import { FormattedText } from 'src/core/ds/FormattedText'
import { presets } from './data'
import styles from './index.module.scss'

export function PresetsWidget({ onSelect, selectedPresetId = null }) {
  const onClear = () => {
    onSelect({
      id: null,
      prompt: '',
      // negative: '',
    })
  }

  return (
    <div className={styles.wrapper}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="presets">
        <FormattedText id="creationForm.actions.presets" />
      </label>
      <div id="presets" className={styles.list}>
        <button
          type="button"
          // disabled
          className={styles.none}
          onClick={onClear}
        >
          <div className={styles.noneText}>
            <span>None</span>
          </div>
        </button>
        {presets.map((preset) => (
          <button
            type="button"
            // disabled
            key={preset.id}
            style={{
              border: preset.id === selectedPresetId ? '2px solid var(--color-primary)' : '2px solid transparent',
            }}
            onClick={() => onSelect(preset)}
            className={styles.modelItem}
          >
            <div className={styles.modelInfo}>
              <span>{preset.title}</span>
            </div>
            {preset.isNew && <div className={styles.new}>NEW</div>}
            {/* <object type="image/webp" data={`${preset.preview}`}>
              <div className={styles.fallbackBlock}>Processing image..</div>
            </object> */}
            <img src={preset.preview} alt={preset.title} loading="lazy" />
          </button>
        ))}
      </div>
    </div>
  )
}
