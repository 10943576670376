import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Input } from 'src/core/ds/input'
import { InputSizes } from 'src/core/ds/input/types'
import { useAuth } from 'src/entities/account/useAuth'
import { FlowLayout } from 'src/layouts/flow/Flow.layout'

export function LoginEmail({ goNext, onCancelFlow, email, setEmail }) {
  const { requestCodeWithEmail } = useAuth()

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      // eslint-disable-next-line camelcase
      const ref_id = localStorage.getItem('refId')
      const utmRaw = localStorage.getItem('adsSource')
      console.log('utmRaw', utmRaw)
      const utm = JSON.parse(utmRaw || '{}') || {}
      await requestCodeWithEmail.mutateAsync({
        email: email?.toLowerCase(),
        // eslint-disable-next-line camelcase
        ref_id,
        utm: {
          ...utm,
          utm_url: window.location.href,
        },
      })
      goNext()
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <FlowLayout>
      <FlowLayout.Header onCancelFlow={onCancelFlow} />
      <FlowLayout.Body>
        <div style={{ maxWidth: 500, margin: '0 auto' }}>
          <FlowLayout.Title>
            <h1>Log in</h1>
          </FlowLayout.Title>
          <FlowLayout.Description>
            <FormattedText id="login.description" />
          </FlowLayout.Description>
          <form onSubmit={onSubmit}>
            <div style={{ marginBottom: '1rem' }}>
              <Input
                error={requestCodeWithEmail?.error?.toString()}
                value={email}
                label="Email"
                isRequired
                inputSize={InputSizes.l}
                name="description"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Enter your email address.."
              />
            </div>

            <div style={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center' }}>
              <Button
                size={ButtonSizes.xl}
                isFullWidth
                disabled={email.length < 3}
                isLoading={requestCodeWithEmail.isLoading}
                label="login.continue"
                type="submit"
              />
            </div>
          </form>
        </div>
      </FlowLayout.Body>
    </FlowLayout>
  )
}
