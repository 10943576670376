import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import styles from './flow.module.scss'

export function FlowLayout({ children }) {
  return <div className={styles.wrapper}>{children}</div>
}

export function FlowLayoutTitle({ children, type }: { children: any; type?: 'success' | 'fail' }) {
  return (
    <>
      {type === 'success' && (
        <div className={styles.successIcon}>
          <Icon name={IconNames.checkCircle} color="green" />
        </div>
      )}
      <div className={styles.title}>{children}</div>
    </>
  )
}
export function FlowLayoutDescription({ children }) {
  return <div className={styles.description}>{children}</div>
}
export function FlowLayoutHeader({ goBack, onCancelFlow }: { goBack?: () => void; onCancelFlow?: () => void }) {
  return (
    <div className={styles.header}>
      {goBack ? (
        <Button
          onClick={goBack}
          type="submit"
          iconColor="var(--color-secondary)"
          label=""
          tooltip="Back"
          variant={ButtonVariants.unstyled}
          icon={IconNames.arrowLeft}
        />
      ) : (
        <div />
      )}
      <Button
        onClick={onCancelFlow}
        type="submit"
        iconColor="var(--color-secondary)"
        label=""
        tooltip="Close page"
        variant={ButtonVariants.unstyled}
        icon={IconNames.close}
      />
    </div>
  )
}
export function FlowLayoutBody({ children }) {
  return <div className={styles.body}>{children}</div>
}
export function FlowLayoutFooter({ children }) {
  return <div className={styles.footer}>{children}</div>
}

FlowLayout.Title = FlowLayoutTitle
FlowLayout.Header = FlowLayoutHeader
FlowLayout.Body = FlowLayoutBody
FlowLayout.Footer = FlowLayoutFooter
FlowLayout.Description = FlowLayoutDescription

FlowLayout.displayName = 'FlowLayout'
