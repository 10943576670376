import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Icon, IconNames } from 'src/core/ds/icon'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import styles from '../index.module.scss'

export function Upsell() {
  const { data: currentUser } = useCurrentAccount()

  return (
    <section className={styles.try}>
      <h3>
        <FormattedText id="landing.trytoday.title" />
      </h3>
      <p>
        <FormattedText id="landing.trytoday.description" />
      </p>

      {!currentUser && (
        <Link to="/login">
          <Button size={ButtonSizes.xl} type="button" label="landing.trytoday.action" />
        </Link>
      )}

      {currentUser && (
        <Link to="/dashboard">
          <Button size={ButtonSizes.xl} type="button" label="landing.trytoday.openDash" />
        </Link>
      )}

      <div className={styles.nocard}>
        <Icon name={IconNames.check} />
        <FormattedText id="landing.trytoday.noCard" />
      </div>
    </section>
  )
}
