import './layouts/reset.css'
import './layouts/fonts.css'
import './layouts/theme.css'

import { MainApp } from './router/index.router'

declare global {
  interface Window {
    plausible: any
    twq: any
    fbq: (eventType: string, action: string, params?: object) => void
    lastImageRequest: any
    rdt: any
    pusherInstance: any
    responseId: any
  }
}

export function App() {
  return <MainApp />
}
