import { Route, Routes } from 'react-router-dom'
import { NotAuthorizedScreen } from 'src/screens/not-authorized/not-authorized.screen'
import { NotFoundScreen } from 'src/screens/not-found/not-found.screen'

export function ErrorRoute() {
  return (
    <Routes>
      <Route path="unauthorized" element={<NotAuthorizedScreen />} />
      {/* <Route path="error" element={<ServerErrorScreen />} />
      <Route path="expired-session" element={<ExpiredSessionScreen />} />
      <Route path="logout-successful" element={<LogoutSuccessfulScreen />} /> */}
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  )
}
