import { useState } from 'react'
import { useFlow } from 'src/hooks/useFlow'
import { ModelCreateFlow } from './steps/Create.flow'
import { ModelInfoFlow } from './steps/Info.flow'
import { ModelReviewFlow } from './steps/Review.flow'
import { ModelUploadFlow } from './steps/Upload.flow'

const addModelFlow = ['create', 'info', 'upload', 'review']

const renderCurrentStep = ({ currentStep, props }: any) => {
  const { onCancelFlow, goBack, createdModelId, setCreatedModelId, goNext, onCompleteFlow } = props
  switch (currentStep) {
    case 'create':
      return <ModelCreateFlow setCreatedModelId={setCreatedModelId} onCancelFlow={onCancelFlow} goNext={goNext} />
    case 'info':
      return <ModelInfoFlow onCancelFlow={onCancelFlow} goNext={goNext} />
    case 'upload':
      return (
        <ModelUploadFlow createdModelId={createdModelId} goBack={goBack} onCancelFlow={onCancelFlow} goNext={goNext} />
      )
    case 'review':
      return <ModelReviewFlow onCancelFlow={onCancelFlow} onCompleteFlow={onCompleteFlow} />
    default:
      return null
  }
}

export function ModelCreationScreen() {
  const [createdModelId, setCreatedModelId] = useState<null | string>(null)
  const { onCancelFlow, goBack, goNext, onCompleteFlow, currentStep } = useFlow({
    flowName: 'add-new-model',
    navigationSequence: addModelFlow,
    cancelUrlFallback: '/dashboard',
  })

  return renderCurrentStep({
    currentStep,
    props: { onCancelFlow, createdModelId, setCreatedModelId, goBack, goNext, onCompleteFlow },
  })
}
