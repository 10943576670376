import { apiClient } from 'src/api/api-client'
import { useModelApi, UseModelApiProps } from 'src/api/core'
import { User } from '../account'
import { QUERY_KEY_CURRENT_ACCOUNT } from './queryKeys'

export const useCurrentAccount = (props?: UseModelApiProps<User>) => {
  const client = apiClient.accounts()
  const token = localStorage.getItem('token')

  return useModelApi({
    ...props,
    id: 'current',
    enabled: !!token,
    queryKey: QUERY_KEY_CURRENT_ACCOUNT,
    queryFn: client.getCurrent,
  })
}

// import { useCollectionApi, UseCollectionApiProps } from '../../core'
// import { InternationalCountriesDetailsCollection } from './types'

// type UseInternationalCountriesDetailsProps = UseCollectionApiProps<CountryInternationalDeliveryDetails>

// export const useInternationalCountriesDetails = (
//   props: UseInternationalCountriesDetailsProps = {},
// ): InternationalCountriesDetailsCollection => {
//   const client = apiClient.internationalCountries()
//   const query = useCollectionApi<CountryInternationalDeliveryDetails>({
//     ...props,
//     queryKey: 'international-countries-details',
//     queryFn: client.fetch,
//   })

//   return query
// }
