import { useState } from 'react'
import { Link } from 'react-router-dom'
import Turnstile from 'react-turnstile'
// import { FormattedText } from 'src/components/FormattedText'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { Container } from 'src/core/ds/container'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Input } from 'src/core/ds/input'
// eslint-disable-next-line import/no-cycle
import { seo } from 'src/router/seo.router'
import { useEmailService } from 'src/services/email/useEmailService'
import { pushNotification } from 'src/services/notifications/notificationService'
import { Logo } from '../Logo'
import styles from './index.module.scss'

export function Footer() {
  const [email, setEmail] = useState('')

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const { requestSubscribeWithEmail } = useEmailService()

  const { isLoading, isSuccess, mutate } = requestSubscribeWithEmail

  const isFormDisabled = isLoading || isSuccess

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!email.length) {
      return
    }

    try {
      await mutate({ email, token: window.cfToken })
      pushNotification({
        type: 'success',
        title: 'Subscribed',
      })
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'Error subscribing',
        subtitle: e?.toString(),
      })
    }
  }

  return (
    <footer id="footer" className={styles.footer}>
      <Container>
        <div className={styles.footerTop}>
          <div className={styles.nav}>
            <ul>
              <li className={styles.sectionTitle}>
                <b>Product:</b>
              </li>
              <li>
                <a href="/#prompts">Features</a>
              </li>

              <li>
                <a href="/#prices">Prices</a>
              </li>

              <li>
                <a href="/#blog">Blog</a>
              </li>

              <li>
                <a href="/#faq">FAQ</a>
              </li>
              <li>
                <a href="/changelog">Changelog</a>
              </li>
              <li>
                <a href="/terms-of-use">Terms of use</a>
              </li>
            </ul>

            <ul>
              <li className={styles.sectionTitle}>
                <b>Company:</b>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
              <li>
                <Link to="/influencers">Influencer program</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>

            <ul>
              <li className={styles.sectionTitle}>
                <b>Free tools:</b>
              </li>
              <li>
                <Link to="/convert-to-png">Convert to PNG</Link>
              </li>
            </ul>

            <ul>
              <li className={styles.sectionTitle}>
                <b>Keep in touch:</b>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href={config.site.socialLinks.twitter}>
                  Twitter
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href={config.site.socialLinks.linkedin}>
                  LinkedIn
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href={config.site.socialLinks.instagram}>
                  Instagram chat
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href={config.site.socialLinks.slack}>
                  Slack
                </a>
              </li>
              <li>
                <a href="/#footer">Subscribe</a>
              </li>
              <li>
                <FormattedText id="footer.sub.contact" />
                <br />
                <a href={`mailto:${config.site.contactEmail}`}>{config.site.contactEmail}</a>
              </li>
            </ul>
          </div>

          <div className={styles.form}>
            <p>
              <FormattedText id="footer.sub.title" />
            </p>
            <form onSubmit={onSubmit}>
              <Input
                isDisabled={isFormDisabled}
                onChange={handleEmailChange}
                value={email}
                type="email"
                placeholder="youremail@email.com"
              />
              <Button isLoading={isLoading} disabled={isFormDisabled} type="submit" label="footer.sub.action" />
              <div className="turnstile">
                <Turnstile
                  theme="light"
                  sitekey={config.turnstileSiteKey}
                  onVerify={(token) => {
                    window.cfToken = token
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        <div className={styles.footerLinks}>
          <p>Links:</p>
          <ul>
            {seo.map((link) => (
              <li key={link.urlSlug}>
                <Link to={`/${link.urlSlug}`}>{link.keyword}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.footerBottom}>
          <div>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a title="Snaptap" href="/">
              <Logo />
            </a>
            <p>All rights reserved. 2024.</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
