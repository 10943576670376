/* eslint-disable react/no-unescaped-entities */
import { Icon, IconNames } from 'src/core/ds/icon'
import styles from '../index.module.scss'

export function Comparison() {
  return (
    <div className={styles.comparison}>
      <h2>How does Snaptap compare to #1 PhotoAI app?</h2>
      <div className={styles.comparisonWrapper}>
        <div className={styles.comparisonInfo}>
          <p>The same photo dataset of 20 photos, the same negative prompt.</p>
        </div>
      </div>

      <h3>Photo reference</h3>
      <div className={styles.comparisonInput}>
        <img
          src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/18290a0f-2d8b-4da9-de0b-0a273d4f5500/small"
          alt="input"
        />
        <img
          src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/081e9b5a-5296-4328-2720-f4453a500000/small"
          alt="input"
        />
        <img
          src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/d556aea6-3227-4535-646b-3955f363b300/small"
          alt="input"
        />
      </div>

      <div className={styles.output}>
        <div className={styles.outputItem}>
          <div className={styles.outputHeader}>#1 PhotoAI app output</div>
          <div className={styles.outputDescription}>
            <b>Prompt: </b>
            In front of a vibrant, abstract background, radiating confidence and style, hair styled impeccably. Dressed
            in the latest high-fashion attire, striking a pose that commands attention. A smile that's both inviting and
            enigmatic, eyes sparkling with ambition. the dynamic composition, (close-up shot)
          </div>
          <div className={styles.outputImages}>
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/a5a30937-ef09-414a-458d-953d1b99df00/medium"
              alt="input"
              loading="lazy"
              width="192"
              height={281}
            />
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/daf9e051-27b8-4af1-c9f7-9b8cd7b77700/medium"
              alt="input"
              loading="lazy"
              width="192"
              height={281}
            />
          </div>
          <div className={styles.outputFeatures}>
            <div className={styles.paidItem}>
              <Icon name={IconNames.close} color="var(--color-red)" />
              <div>Less similar to the original (faceswap disabled)</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.checkFill} color="var(--color-primary)" />
              <div>HDR-like colors</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.checkFill} color="var(--color-primary)" />
              <div>Better long distance photos</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.close} color="var(--color-red)" />
              <div>Worse follows the prompt</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.close} color="var(--color-red)" />
              <div>Starting $39</div>
            </div>
          </div>
        </div>

        <div className={styles.outputItem}>
          <div className={styles.outputHeader}>SnaptapAI output</div>
          <div className={styles.outputDescription}>
            <b>Prompt: </b>
            In front of a vibrant, abstract background, radiating confidence and style, hair styled impeccably. Dressed
            in the latest high-fashion attire, striking a pose that commands attention. A smile that's both inviting and
            enigmatic, eyes sparkling with ambition. the dynamic composition, (close-up shot)
          </div>
          <div className={styles.outputImages}>
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/400a341c-fbef-46b9-b8ea-4a8dcd309700/medium"
              alt="input"
              loading="lazy"
              width="192"
              height={281}
            />
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/46a01f6d-6c9e-4d97-8a68-12021ae08000/medium"
              alt="input"
              loading="lazy"
              width="192"
              height={281}
            />
          </div>
          <div className={styles.outputFeatures}>
            <div className={styles.paidItem}>
              <Icon name={IconNames.checkFill} color="var(--color-primary)" />
              <div>More similar to the original</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.checkFill} color="var(--color-primary)" />
              <div>Bright colors</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.close} color="var(--color-red)" />
              <div>Average long distance photos</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.checkFill} color="var(--color-primary)" />
              <div>Better follows the prompt</div>
            </div>
            <div className={styles.paidItem}>
              <Icon name={IconNames.checkFill} color="var(--color-primary)" />
              <div>Starting just $10</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
