import styles from './index.module.scss'
import { BadgeType } from './types'

interface BadgeProps {
  type: BadgeType
  label: string
}

export function Badge({ type, label }: BadgeProps) {
  return <span className={`${styles.badge} ${styles[type]}`}>{label}</span>
}
