/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import { Helmet } from 'react-helmet'
import { Container } from 'src/core/ds/container'
import { ScrollToTop } from 'src/hooks/scrollToTop'
import { Footer } from 'src/layouts/Footer'
import { Header } from 'src/layouts/Header'
import { Upsell } from 'src/screens/landing/parts/Upsell'
import { blogData } from './data'
import styles from './index.module.scss'

const data = blogData['6']

export function BlogPageSix() {
  ScrollToTop()

  return (
    <>
      <Header />
      <div
        className={styles.surface}
        style={{
          backgroundImage: `url(${data.image})`,
        }}
      >
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.description} />
          <meta property="og:title" content={data.title} />
          <meta property="og:description" content={data.description} />
        </Helmet>
        <div className={styles.hero}>
          <Container>
            <h1>{data.title}</h1>
            <p>{data.description}</p>
          </Container>
        </div>
      </div>
      <div className={styles.content}>
        <Container>
          <div className={styles.date}>
            Published:
            {data.date}
          </div>

          <p>
            In the ever-evolving world of social media, standing out on platforms like Instagram requires constant
            innovation and creativity. Enter the realm of AI-powered photography—a groundbreaking solution for creating
            mesmerizing photos that truly reflect you. Our SaaS project is at the forefront of this revolution, offering
            users the ability to generate images through AI that not only capture their essence but also enhance their
            Instagram feed's aesthetic appeal.
          </p>

          <h2>Why Choose AI for Your Instagram Photos?</h2>
          <p>
            AI technology has the unique ability to analyze and understand the subtleties of human faces, making it
            possible to create images, or even entire themes, tailored to your personal style. Whether you want to{' '}
            <strong>create your AI photo</strong>, explore <strong>photography ideas for Instagram</strong>, or simply
            generate a <strong>photo from AI</strong> that captures your mood, our platform offers endless
            possibilities.
          </p>

          <h2>Features That Set Us Apart</h2>
          <ul>
            <li>
              <strong>Personalized AI Faces:</strong> Generate <strong>faces created by AI</strong> that look
              astonishingly like you, perfect for creating unique profile pictures or themed posts.
            </li>
            <li>
              <strong>Instagram Feed AI:</strong> Enhance your Instagram feed with AI-generated photos that seamlessly
              blend with your existing posts, enriching your social media presence.
            </li>
            <li>
              <strong>AI Photo Camera:</strong> Utilize our AI camera tool to take real-time photos that are instantly
              beautified and stylized by AI, ensuring your Instagram posts are always eye-catching.
            </li>
          </ul>

          <h2>How It Works</h2>
          <p>
            Getting started is simple. Just upload a photo of yourself, and our AI will do the rest. From{' '}
            <strong>creating an AI face</strong> to generating a complete <strong>AI Instagram post</strong>, the
            process is seamless. Our platform uses advanced algorithms to <strong>make your photo with AI</strong>,
            ensuring each image is personalized and unique.
          </p>

          <h2>Join the AI Photography Revolution</h2>
          <p>
            Whether you're an influencer looking to differentiate your content, a brand aiming to captivate your
            audience, or just someone who loves experimenting with new photography trends, our platform is designed for
            you. By leveraging the power of AI, you can transform your Instagram profile into a stunning showcase of
            personalized, AI-generated photos that tell your story in the most visually appealing way.
          </p>

          <p>
            Ready to take your Instagram to the next level? Explore the limitless possibilities of AI-powered
            photography with us and create images that truly stand out. Visit our website today to learn more and start
            creating your unique AI-generated photos for Instagram.
          </p>
        </Container>
      </div>
      <Upsell />

      <Footer />
    </>
  )
}
