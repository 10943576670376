export const cameraSettings = [
  {
    id: 2,
    title: 'Outdoor Portraits',
    value: 'ISO 200, Aperture f/2.8, Shutter Speed 1/400 sec',
    note: 'Soft background, clear subject',
  },
  {
    id: 3,
    title: 'Indoor Candid',
    value: 'ISO 800, Aperture f/4, Shutter Speed 1/125 sec',
    note: 'Balance low light without too much noise',
  },
  {
    id: 4,
    title: 'Children Playing',
    value: 'ISO 800, Aperture f/4, Shutter Speed 1/800 sec',
    note: 'Freeze motion, good for indoor and outdoor',
  },
  {
    id: 5,
    title: 'Street Portraits',
    value: 'ISO 400, Aperture f/4, Shutter Speed 1/500 sec',
    note: 'Versatile for dynamic lighting conditions',
  },
  {
    id: 6,
    title: 'Evening Outdoor Events',
    value: 'ISO 1600, Aperture f/2.8, Shutter Speed 1/200 sec',
    note: 'Capture ambient light, retain sharpness',
  },
  {
    id: 7,
    title: 'Concerts/Low Light',
    value: 'ISO 3200, Aperture f/2.8, Shutter Speed 1/160 sec',
    note: 'High ISO for light, wide aperture for focus',
  },
  {
    id: 8,
    title: 'Beach/Snow Portraits',
    value: 'ISO 100, Aperture f/8, Shutter Speed 1/1000 sec',
    note: 'Bright conditions, deep focus',
  },
  {
    id: 9,
    title: 'Macro Portraits',
    value: 'ISO 400, Aperture f/5.6, Shutter Speed 1/250 sec',
    note: 'Detailed close-ups, natural light',
  },
  {
    id: 10,
    title: 'Night Cityscapes',
    value: 'ISO 800, Aperture f/4, Shutter Speed 1/60 sec',
    note: 'Capture lights and details at night',
  },
  {
    id: 11,
    title: 'Rainy Day Portraits',
    value: 'ISO 800, Aperture f/2.8, Shutter Speed 1/500 sec',
    note: 'Moisture detail, avoid overexposure',
  },
]
