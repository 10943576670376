import { useRef } from 'react'
import { useAutosizeTextArea } from 'src/hooks/useAutosizeTextArea'
import styles from './index.module.scss'

export function Textarea({ placeholder = '', value = '', onChange, name = '', label, disabled = false }: any) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  useAutosizeTextArea(textAreaRef.current, value)

  return (
    <>
      {label && (
        <label className={styles.label} htmlFor="textarea-id">
          {label}
        </label>
      )}
      <textarea
        ref={textAreaRef}
        id="textarea-id"
        className={styles.textarea}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        name={name}
        rows={3}
      />
    </>
  )
}
