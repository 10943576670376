import { apiClient } from 'src/api/api-client'
import { UseCollectionApiProps, useInfiniteCollectionApi } from 'src/api/core'
import { QUERY_KEY_EXPLORER_SNAPS } from './queryKeys'
import { Snap } from './types'

type UseUserPreferencesApiProps = UseCollectionApiProps<typeof apiClient.models, Snap>

export const useExplorerSnaps = (props?: UseUserPreferencesApiProps) => {
  const client = apiClient.snaps()

  const query = useInfiniteCollectionApi({
    ...props,
    // eslint-disable-next-line
    // @ts-ignore
    id: 'explorer',
    queryKey: QUERY_KEY_EXPLORER_SNAPS,
    // eslint-disable-next-line
    // @ts-ignore
    queryFn: ({ pageParam = 1 }) => client.getExplorerSnaps({ page: pageParam }),
  })

  return {
    ...query,
  }
}
