import { Children } from 'react'

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export const defaultFormatters = {
  b: (...chunks) => <b>{Children.toArray(chunks)}</b>,
  span: (...chunks) => <span>{Children.toArray(chunks)}</span>,
  sup: (...chunks) => <sup>{Children.toArray(chunks)}</sup>,
  br: <br />,
}

export const localisationMap = {
  'en-US': 'languages.english',
  'ru-RU': 'languages.russian',
  'th-TH': 'languages.thai',
}
