import { useIsSubscriber } from './useIsSubscriber'

const defaultFeaturesState = {
  canFavor: false,
  canUpscale: false,
  advancedFilter: false,
  advancedSettings: false,
}

export const useAllowedFeatures = () => {
  const { isSubscriber } = useIsSubscriber()

  return isSubscriber
    ? {
        canFavor: true,
        canUpscale: true,
        advancedFilter: true,
        advancedSettings: true,
      }
    : defaultFeaturesState
}
