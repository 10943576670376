import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Checkbox } from 'src/core/ds/checkbox/Checkbox'
import { DropdownSimple } from 'src/core/ds/dropdown'
import { FormattedText } from 'src/core/ds/FormattedText'
import { FormControl } from 'src/core/ds/formControl'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Selector } from 'src/core/ds/selector'
import { Textarea } from 'src/core/ds/textarea'
import { Tooltip } from 'src/core/ds/tooltip/Tooltip'
import { useAllowedFeatures } from 'src/entities/account/useAllowedFeatures'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { AIModel } from 'src/entities/models/types'
import { useModels } from 'src/entities/models/useGetModels'
import { useActiveSnaps } from 'src/entities/snap/useActiveSnap.hook'
import { useSnaps } from 'src/entities/snap/useGetSnaps'
import { useModelActions } from 'src/hooks/useActions.hook'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { pushNotification } from 'src/services/notifications/notificationService'
import { DEFAULT_NEGATIVE, useSettingsStore } from 'src/store/settingsStore'
import { getWordCount } from 'src/utils/strings'
import { BalanceWidget } from '../Balance/Balance.widget'
import { ModelList } from '../ModelList/ModelList'
import { PresetsWidget } from '../Presets/Presets.widget'
import { cameraSettings } from './data'
import styles from './index.module.scss'

const samplerList = [
  {
    id: 2,
    title: 'Realistic',
    value: 'sde-dpmsolver++',
  },
  {
    id: 3,
    title: 'Smooth',
    value: 'euler_a',
  },
]

export function CreationForm() {
  const [refetchEnabled, setRefetchEnabled] = useState(false)
  const { fireEvent } = useAnalytics()
  const updateSetingsStore = useSettingsStore((state) => state.updateSetingsStore)
  const likeness = useSettingsStore((state) => state.likeness)
  const seed = useSettingsStore((state) => state.seed)
  const negative = useSettingsStore((state) => state.negative)
  const strictness = useSettingsStore((state) => state.strictness)
  const camera = useSettingsStore((state) => state.camera)
  const faceFix = useSettingsStore((state) => state.face_fix)
  const faceSwap = useSettingsStore((state) => state.face_swap)
  const sampler = useSettingsStore((state) => state.sampler)
  const hq = useSettingsStore((state) => state.hq)

  const [textInput, setTextInput] = useState('')
  const [selectedPresetId, setSelectedPreset] = useState(null)
  const [selectedModelId, setSelectedModel] = useState<AIModel | null>(null)
  const { deleteModel, trainModel, data: models } = useModels({ refetchEnabled })
  const { createSnap } = useSnaps()
  // eslint-disable-next-line
  // @ts-ignore
  const selectedModel: AIModel = models?.find((model: AIModel) => model?.id?.toString() === selectedModelId)
  // eslint-disable-next-line
  // @ts-ignore
  const allowedActions = useModelActions(selectedModel)
  const allowedFeatures = useAllowedFeatures()

  const { data: currentUser } = useCurrentAccount()

  const { isConcurrencyFilled } = useActiveSnaps()

  const onSettingChange = (e) => {
    updateSetingsStore(e.target.name, e.target.value)
  }

  const onSelectPreset = (preset) => {
    setTextInput(preset?.prompt)
    setSelectedPreset(preset.id)
    // updateSetingsStore('negative', preset.negative)
    fireEvent(ANALYTICS_EVENTS.ClickPreset)
  }

  useEffect(() => {
    if (models) {
      // eslint-disable-next-line
      // @ts-ignore
      const isRefetchEnabled = models?.find((model: AIModel) => model.status !== 'ready')
      // eslint-disable-next-line
      // @ts-ignore
      setRefetchEnabled(isRefetchEnabled) // Be careful to avoid infinite loops here
    }
  }, [models])

  const onRemoveModel = async () => {
    try {
      await deleteModel.mutateAsync(selectedModelId)
      pushNotification({
        type: 'success',
        title: 'notification.model.delete.success',
      })
      fireEvent(ANALYTICS_EVENTS.DeleteModel)
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'notification.model.delete.fail',
      })
    }
  }

  const onTrainModel = async () => {
    try {
      await trainModel.mutateAsync(selectedModelId)
      pushNotification({
        type: 'success',
        title: 'notification.model.training.started',
      })
      fireEvent(ANALYTICS_EVENTS.TrainModel)
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'notification.model.training.failed',
      })
    }
  }

  const onToggleCheckbox = () => {
    updateSetingsStore('face_fix', !faceFix)
  }

  const onToggleFaceSwapCheckbox = () => {
    updateSetingsStore('face_swap', !faceSwap)
  }

  const onToggleHq = () => {
    updateSetingsStore('hq', !hq)
  }

  const onSelectCamera = (name) => {
    updateSetingsStore('camera', name)
  }

  const onSelectSampler = (name) => {
    updateSetingsStore('sampler', name)
  }

  const restoreNegative = () => {
    updateSetingsStore('negative', DEFAULT_NEGATIVE)
  }

  const MAX_PROMPT_WORDS_COUNT = 160
  const MAX_NEGATIVE_WORDS_COUNT = 40

  const onCreateSnapRequest = async () => {
    if (!textInput) {
      pushNotification({
        type: 'default',
        title: 'notification.model.prompt.empty',
      })

      // eslint-disable-next-line
      // @ts-ignore
      return document?.querySelector('#textarea-id')?.focus()
    }

    const promptWordsCount = getWordCount(textInput as string)
    const negativeWordsCount = getWordCount(negative as string)

    if (promptWordsCount > MAX_PROMPT_WORDS_COUNT) {
      pushNotification({
        type: 'error',
        title: 'notification.model.prompt.overflow',
      })

      return undefined
    }

    if (negativeWordsCount > MAX_NEGATIVE_WORDS_COUNT) {
      pushNotification({
        type: 'error',
        title: 'notification.model.negative.overflow',
      })

      return undefined
    }

    try {
      await createSnap.mutateAsync({
        id: selectedModelId,
        prompt: {
          text: `${textInput}${camera !== 'Off' ? `, ${camera}` : ''}`,
          likeness,
          count: '3',
          seed: seed === '' ? null : seed,
          negative,
          strictness,
          hq,
          face_fix: faceFix,
          face_swap: faceSwap,
          sampler: sampler === 'No sampler' ? null : sampler,
          // ...(sampler === '' ? { sampler } : {}),
        },
      })
    } catch (e) {
      console.log('error', e)
    }

    return undefined
  }

  const foundCameraNote = cameraSettings.find((setting) => setting.value === camera)

  const shouldShowForm = selectedModel && selectedModel.status === 'ready'
  const upsellOnTrains = currentUser && currentUser?.limits.trains === 0 // currentUser?.sub_trains === 0
  const upsellOnGens = currentUser && currentUser?.limits.snaps === 0

  return (
    <div className={styles.form}>
      <div className={styles.balanceWidget}>
        <BalanceWidget />
      </div>
      <div className={styles.modelsWrapper}>
        <ModelList models={models} selectedModel={selectedModel} setSelectedModel={setSelectedModel} />

        <div className={styles.actionsDescriptions}>
          {selectedModel ? (
            <span>
              <FormattedText id="creationForm.available" />
            </span>
          ) : (
            <div />
          )}
          <span>
            <FormattedText id="creationForm.trains" />
            :&nbsp;
            {currentUser?.limits.trains}
          </span>
        </div>

        <div className={styles.actions}>
          {!upsellOnTrains && allowedActions.train && (
            <Button
              type="button"
              disabled={trainModel.isLoading}
              isLoading={trainModel.isLoading}
              onClick={onTrainModel}
              // variant={ButtonVariants.secondary}
              label="creationForm.actions.train"
            />
          )}
          {upsellOnTrains && (
            <Link to="/prices">
              <Button type="button" label="creationForm.actions.buyTrains" />
            </Link>
          )}
          {!upsellOnTrains && allowedActions.retrain && (
            <Button
              type="button"
              disabled={trainModel.isLoading}
              isLoading={trainModel.isLoading}
              onClick={onTrainModel}
              confirmPopup
              // variant={ButtonVariants.secondary}
              label="creationForm.actions.retrain"
            />
          )}
          {allowedActions.remove && (
            <Button
              type="button"
              disabled={deleteModel.isLoading}
              isLoading={deleteModel.isLoading}
              onClick={onRemoveModel}
              confirmPopup
              variant={ButtonVariants.secondary}
              label="creationForm.actions.remove"
            />
          )}
        </div>
      </div>

      {shouldShowForm && (
        <div className={styles.formWrapper}>
          <FormControl gap="1rem">
            <div className={styles.formTitle}>
              <FormattedText id="creationForm.actions.title" />
            </div>
            <PresetsWidget selectedPresetId={selectedPresetId} onSelect={onSelectPreset} />

            <div className={styles.formControl}>
              <div className={styles.checkBoxPublic}>
                {/* eslint-disable-next-line */}
                <label htmlFor="publicity">
                  <span>
                    <FormattedText id="creationForm.actions.settings.publicity.title" />
                  </span>
                  <Tooltip content="creationForm.actions.settings.publicity.tooltip" />
                </label>
                <Checkbox disabled name="publicity" onChange={() => false} checked />
              </div>
            </div>

            <div className={styles.formControl}>
              {/* eslint-disable-next-line */}
              <label htmlFor={'model'}>
                <FormattedText id="creationForm.actions.prompt" values={{ name: selectedModel.name }} />

                <Link
                  className={styles.promptLink}
                  target="_blank"
                  to="/blog/how-to-craft-the-perfect-photo-description-for-snaptap-ai"
                  onClick={() => {
                    window?.fbq('track', 'ClickHowToPrompt')
                    fireEvent(ANALYTICS_EVENTS.ClickHowToPrompt)
                  }}
                >
                  How to: prompt
                </Link>
              </label>
              <Textarea
                placeholder="...describe composition, place, light, pose and etc."
                value={textInput}
                id="prompt"
                onChange={(e) => setTextInput(e.target.value)}
                isRequired
                rows={3}
              />
            </div>

            <div className={styles.formControl}>
              <DropdownSimple
                visibleContent={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className={styles.advanced}>
                    <Button
                      type="button"
                      variant={ButtonVariants.unstyled}
                      icon={IconNames.shevronDown}
                      iconColor="var(--color-secondary)"
                      label="creationForm.actions.settings"
                      onClick={() => fireEvent(ANALYTICS_EVENTS.ClickAdvancedSettings)}
                    />
                  </div>
                }
                hiddenContent={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className={styles.advancedBox}>
                    <div className={styles.formControl}>
                      {/* eslint-disable-next-line */}
                      <label htmlFor={'weight'}>
                        <span>
                          <FormattedText id="creationForm.actions.settings.weight.title" />
                        </span>
                        <Tooltip
                          // proBadge={!allowedFeatures.advancedSettings}
                          content="creationForm.actions.character.tooltip"
                        />
                      </label>
                      <div className={styles.rangeBox}>
                        <input
                          value={likeness}
                          onChange={onSettingChange}
                          type="range"
                          name="likeness"
                          className="range-input"
                          min={0.1}
                          // disabled={!allowedFeatures.advancedSettings}
                          step={0.1}
                          max={1}
                        />
                        <div className={styles.rangeValue}>{likeness}</div>
                      </div>
                    </div>

                    <div className={styles.formControl}>
                      {/* eslint-disable-next-line */}
                      <label htmlFor={'strictness'}>
                        <span>
                          <FormattedText id="creationForm.actions.settings.strictness.title" />
                        </span>
                        <Tooltip
                          // proBadge={!allowedFeatures.advancedSettings}
                          content="creationForm.actions.settings.strictness.tooltip"
                        />
                      </label>
                      <div className={styles.rangeBox}>
                        <input
                          value={strictness}
                          onChange={onSettingChange}
                          type="range"
                          name="strictness"
                          className="range-input"
                          min={0.1}
                          // disabled={!allowedFeatures.advancedSettings}
                          step={0.1}
                          max={1}
                        />
                        <div className={styles.rangeValue}>{strictness}</div>
                      </div>
                    </div>

                    <div className={styles.formControl}>
                      <div className={styles.checkBox}>
                        {/* eslint-disable-next-line */}
                        <label htmlFor="face_fix">
                          <span>
                            <FormattedText id="creationForm.actions.settings.enhancement.title" />
                          </span>
                          <Tooltip
                            proBadge={!allowedFeatures.advancedSettings}
                            content="creationForm.actions.settings.enhancement.tooltip"
                          />
                        </label>
                        <Checkbox
                          disabled={!allowedFeatures.advancedSettings}
                          onChange={onToggleCheckbox}
                          checked={faceFix}
                        />
                      </div>
                    </div>

                    <div className={styles.formControl}>
                      <div className={styles.checkBox}>
                        {/* eslint-disable-next-line */}
                        <label htmlFor="face_swap">
                          <span>
                            <FormattedText id="creationForm.actions.settings.faceSwap.title" />
                          </span>
                          <Tooltip
                            proBadge={!allowedFeatures.advancedSettings}
                            content="creationForm.actions.settings.faceSwap.tooltip"
                          />
                        </label>
                        <Checkbox
                          disabled={!allowedFeatures.advancedSettings}
                          onChange={onToggleFaceSwapCheckbox}
                          checked={faceSwap}
                        />
                      </div>
                    </div>

                    <div className={styles.formControl}>
                      <div className={styles.checkBox}>
                        {/* eslint-disable-next-line */}
                        <label htmlFor="hq">
                          <span>
                            <FormattedText id="creationForm.actions.settings.hq.title" />
                          </span>
                          <Tooltip
                            proBadge={!allowedFeatures.advancedSettings}
                            content="creationForm.actions.settings.hq.tooltip"
                          />
                        </label>
                        <Checkbox
                          name="hq"
                          disabled={!allowedFeatures.advancedSettings}
                          onChange={onToggleHq}
                          checked={hq}
                        />
                      </div>
                    </div>

                    {/* <div className={styles.formControl}>
                      <label htmlFor={'photos'}>
                        <span>Number of photos</span>
                        <Tooltip content="Photos will be created per 1 request" />
                      </label>
                      <div className={styles.rangeBox}>
                        <input
                          value={photosAmount}
                          onChange={(e) => setPhotosAmount(e.target.value)}
                          type="range"
                          className="range-input"
                          min={1}
                          max={10}
                        />
                        <div className={styles.rangeValue}>{photosAmount}</div>
                      </div>
                    </div> */}

                    <div className={styles.formControl}>
                      {/* eslint-disable-next-line */}
                      <label htmlFor={'camera'}>
                        <span>
                          <FormattedText id="creationForm.actions.settings.camera.title" />
                        </span>
                        {/* <Tooltip content="creationForm.actions.negative.tooltip" /> */}
                      </label>
                      <Selector
                        onSelect={onSelectCamera}
                        disabled={!allowedFeatures.advancedSettings}
                        options={[
                          { id: 1, value: 'Off', title: 'Off' },
                          ...cameraSettings.map(
                            (setting: any) =>
                              ({
                                id: setting.id,
                                value: setting.value,
                                title: setting.title,
                              } as any),
                          ),
                        ]}
                        selected={camera}
                      />
                      <div className={styles.note}>
                        Note:&nbsp;
                        {camera === 'Off' && !foundCameraNote ? (
                          <FormattedText id="creationForm.actions.settings.camera.off" />
                        ) : (
                          // eslint-disable-next-line
                          // @ts-ignore
                          foundCameraNote?.note
                        )}
                      </div>
                    </div>

                    <div className={styles.formControl}>
                      {/* eslint-disable-next-line */}
                      <label htmlFor={'sampler'}>
                        <span>
                          <FormattedText id="creationForm.actions.settings.sampler.title" />
                        </span>
                        {/* <Tooltip content="creationForm.actions.negative.tooltip" /> */}
                      </label>
                      <Selector
                        onSelect={onSelectSampler}
                        disabled={!allowedFeatures.advancedSettings}
                        options={[{ id: 1, value: null, title: 'No sampler' }, ...samplerList]}
                        selected={sampler}
                      />
                      {/* <div className={styles.note}>
                        Note:&nbsp;
                        {camera === 'Off' && !foundCameraNote ? (
                          <FormattedText id="creationForm.actions.settings.camera.off" />
                        ) : (
                          // eslint-disable-next-line
                          // @ts-ignore
                          foundCameraNote?.note
                        )}
                      </div> */}
                    </div>

                    <div className={styles.formControl}>
                      {/* eslint-disable-next-line */}
                      <label htmlFor={'negative'}>
                        <span>
                          <FormattedText id="creationForm.actions.negative" />
                        </span>
                        <Tooltip
                          // proBadge={!allowedFeatures.advancedSettings}
                          content="creationForm.actions.negative.tooltip"
                        />

                        <div className={styles.negativeRestore}>
                          <Button
                            variant={ButtonVariants.unstyled}
                            iconColor="var(--color-secondary)"
                            type="button"
                            onClick={restoreNegative}
                            tooltip="Restore defaults"
                            icon={IconNames.refresh}
                          />
                        </div>
                      </label>
                      <Textarea
                        // disabled={!allowedFeatures.advancedSettings}
                        name="negative"
                        value={negative}
                        onChange={onSettingChange}
                        rows={3}
                      />
                    </div>

                    <div className={styles.formControl}>
                      {/* eslint-disable-next-line */}
                      <label htmlFor={'sid'}>
                        <span>
                          <FormattedText id="creationForm.actions.seed" />
                        </span>
                        <Tooltip
                          proBadge={!allowedFeatures.advancedSettings}
                          content="creationForm.actions.seed.tooltip"
                        />
                      </label>
                      <div className={styles.rangeBox}>
                        <div className={styles.inputWrapper}>
                          <Input
                            // disabled={!allowedFeatures.advancedSettings}
                            name="seed"
                            placeholder="Add seed"
                            value={seed}
                            disabled={!allowedFeatures.advancedSettings}
                            onChange={onSettingChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </FormControl>
        </div>
      )}

      {currentUser && shouldShowForm && (
        <div className={styles.formAction}>
          {!upsellOnGens && (
            <Button
              isLoading={createSnap.isLoading}
              disabled={isConcurrencyFilled || createSnap.isLoading}
              onClick={onCreateSnapRequest}
              label="creationForm.actions.generate"
              type="button"
              isFullWidth
            />
          )}
          {upsellOnGens && (
            <Link to="/prices">
              <Button
                isLoading={createSnap.isLoading}
                label="creationForm.actions.buyPhotos"
                type="button"
                isFullWidth
              />
            </Link>
          )}
          {isConcurrencyFilled && (
            <div className={styles.concurrencyFilled}>
              <FormattedText id="creationForm.actions.concurrency" />
              &nbsp;
              {currentUser.concurrency.snap}
            </div>
          )}
          <span className={styles.info}>
            <FormattedText id="creationForm.actions.snapsLeft" />
            &nbsp;
            {currentUser.limits.snaps}
          </span>
        </div>
      )}
    </div>
  )
}
