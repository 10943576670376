import { useCurrentAccount } from './useCurrentAccount'

export const useIsSubscriber = () => {
  const { data: currentUser } = useCurrentAccount()

  const subscriptionType = currentUser?.sub_plan
  const isSubscriber = currentUser?.is_subscriber

  return {
    subscriptionType,
    isSubscriber,
  }
}
