import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export type FlowTypes = {
  flowName: string
  navigationSequence: string[]
  cancelUrlFallback: string
}

export const useFlow = ({ flowName, navigationSequence, cancelUrlFallback }: FlowTypes) => {
  const navigate = useNavigate()
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const maxIndex = navigationSequence.length

  const goNext = () => {
    setCurrentStepIndex((prevState) => {
      if (prevState + 1 === maxIndex) {
        console.error('Cant go next: no element in sequance')

        return prevState
      }

      return prevState + 1
    })
  }

  const goBack = () => {
    setCurrentStepIndex((prevState) => {
      if (prevState - 1 < 0) {
        console.error('Cant go back: no element in sequance')

        return prevState
      }

      return prevState - 1
    })
  }

  const onCompleteFlow = (url: string, callback?: any) => {
    callback && callback()
    navigate(url)
  }

  const onCancelFlow = () => {
    navigate(cancelUrlFallback)
  }

  return {
    flowName,
    goBack,
    onCompleteFlow,
    onCancelFlow,
    goNext,
    currentStep: navigationSequence[currentStepIndex],
  }
}
