import { Icon, IconNames } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { calculateTimeLeft } from 'src/utils/strings'
import styles from './index.module.scss'
import { shouldShowLoader } from './ModelItem.utils'

export function ModelItem({ model, selectedModel, setSelectedModel }) {
  return (
    <button
      type="button"
      // disabled
      style={{
        border:
          model.id.toString() === selectedModel?.id?.toString()
            ? '2px solid var(--color-primary)'
            : '2px solid transparent',
      }}
      onClick={() => setSelectedModel(model?.id?.toString())}
      className={styles.modelItem}
    >
      <div className={styles.modelInfo}>
        <span>{model.name}</span>
        <div className={styles.status}>
          {model.status}
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          {shouldShowLoader(model) && <Loader />}
          {model.status === 'ready' && <Icon name={IconNames.checkCircle} color="var(--color-primary)" />}
          {model.status === 'pending' ||
            // eslint-disable-next-line react/jsx-one-expression-per-line
            (model.status === 'processing' && (
              <span className={styles.percent}>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {model.progress}% (~
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {calculateTimeLeft(model.progress, model.status_at * 1000)} min)
              </span>
            ))}
        </div>
      </div>
      {/* <object type="image/webp" data={`${model.image_url}?${model.updated_at}`}>
        <div className={styles.fallbackBlock}>Processing image..</div>
      </object> */}
      <img
        onError={(e) => {
          // eslint-disable-next-line
          // @ts-ignore
          e.target.src = 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/6db0696c-310f-456e-eec6-db53f6a29000/tiny' // Change the source of the image to the fallback source
        }}
        src={`${model.image_url}?${model.updated_at}`}
        alt={model.name}
        loading="lazy"
      />
    </button>
  )
}
