import { useEffect } from 'react'
import { Button } from 'src/core/ds/button'
import { FormattedText } from 'src/core/ds/FormattedText'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { FlowLayout } from 'src/layouts/flow/Flow.layout'
import BadPhotoExample1 from '../../../assets/images/bad-1.jpg'
import BadPhotoExample2 from '../../../assets/images/bad-2.jpg'
import BadPhotoExample3 from '../../../assets/images/bad-3.jpg'
import BadPhotoExample4 from '../../../assets/images/bad-4.jpg'
import BadPhotoExample5 from '../../../assets/images/bad-5.jpg'
import BadPhotoExample6 from '../../../assets/images/bad-6.jpg'
import GoodPhotoExample1 from '../../../assets/images/good-1.jpg'
import GoodPhotoExample2 from '../../../assets/images/good-2.jpg'
import GoodPhotoExample3 from '../../../assets/images/good-3.jpg'
import GoodPhotoExample4 from '../../../assets/images/good-4.jpg'
import GoodPhotoExample5 from '../../../assets/images/good-5.jpg'
import GoodPhotoExample6 from '../../../assets/images/good-6.jpg'
import styles from '../index.module.scss'

export function ModelInfoFlow({ onCancelFlow, goNext }) {
  const { fireEvent } = useAnalytics()
  useEffect(() => {
    fireEvent(ANALYTICS_EVENTS.ModelFlowInfoView)
  }, [])

  return (
    <FlowLayout>
      <FlowLayout.Header onCancelFlow={onCancelFlow} />
      <FlowLayout.Body>
        <FlowLayout.Title>
          <h1>
            <FormattedText id="Instructions" />
          </h1>
        </FlowLayout.Title>
        <FlowLayout.Description>
          <FormattedText id="Please, before uploading photos, familiarize yourself with which photos will be most suitable. The quality and similarity of the generated photos depend 90% on the quality of the ones you upload." />
        </FlowLayout.Description>
        <div style={{ margin: '1rem auto', maxWidth: '800px', marginBottom: '1rem' }}>
          <div className={styles.uploadBox}>
            <div className={styles.expectedResult}>
              <h2 className={styles.smallTitle}>
                <FormattedText id="✅ Good photos example" />
              </h2>
              <p>
                Close-up selfies, same person, adults, variety of backgrounds, facial expressions, head tilts and angles
              </p>
              <div className={styles.photoPreviewBig}>
                <img className={styles.photoPreviewImg} src={GoodPhotoExample1} alt="" />
                <img className={styles.photoPreviewImg} src={GoodPhotoExample2} alt="" />
                <img className={styles.photoPreviewImg} src={GoodPhotoExample3} alt="" />
                <img className={styles.photoPreviewImg} src={GoodPhotoExample4} alt="" />
                <img className={styles.photoPreviewImg} src={GoodPhotoExample5} alt="" />
                <img className={styles.photoPreviewImg} src={GoodPhotoExample6} alt="" />
              </div>
            </div>

            <div className={styles.expectedResult}>
              <h2 className={styles.smallTitle}>
                <FormattedText id="❌ Bad photos example" />
              </h2>
              <p>Group shots, full-length, kids, covered face, animals, nudes</p>
              <div className={styles.photoPreviewBig}>
                <img className={styles.photoPreviewImg} src={BadPhotoExample1} alt="" />
                <img className={styles.photoPreviewImg} src={BadPhotoExample2} alt="" />
                <img className={styles.photoPreviewImg} src={BadPhotoExample3} alt="" />
                <img className={styles.photoPreviewImg} src={BadPhotoExample4} alt="" />
                <img className={styles.photoPreviewImg} src={BadPhotoExample5} alt="" />
                <img className={styles.photoPreviewImg} src={BadPhotoExample6} alt="" />
              </div>
            </div>
          </div>
        </div>
        <FlowLayout.Footer>
          <Button onClick={goNext} type="button" label="Got it! Continue" />
        </FlowLayout.Footer>
      </FlowLayout.Body>
    </FlowLayout>
  )
}
