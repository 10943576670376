import { useMutation } from 'react-query'
import { apiClient } from 'src/api/api-client'
import { createQueryKey, useCollectionApi, UseCollectionApiProps } from 'src/api/core'
import { queryClient } from 'src/queries/reactQueryClient'
import { QUERY_KEY_USER_SNAPS } from '../snap/queryKeys'
import { QUERY_KEY_FAVORITES } from './queryKeys'
import { Favorite } from './types'

type UseUserPreferencesApiProps = UseCollectionApiProps<typeof apiClient.favorites, Favorite>

export const useFavoritesActions = (props?: UseUserPreferencesApiProps) => {
  const client = apiClient.favorites()

  const query = useCollectionApi<typeof client.getFavorites>({
    ...props,
    // eslint-disable-next-line
    // @ts-ignore
    id: 'favoritesActions',
    queryKey: QUERY_KEY_FAVORITES,
    // eslint-disable-next-line
    // @ts-ignore
    // queryFn: () => client.getFavorites(),
  })

  const createFavoriteMutation = useMutation(client.createFavorite, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_FAVORITES, role: 'collection', scope: 'default' }),
      )
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_SNAPS, role: 'collection', scope: 'default' }),
      )
    },
  })

  const deleteFavoriteMutation = useMutation(client.deleteFavorite, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_FAVORITES, role: 'collection', scope: 'default' }),
      )
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_SNAPS, role: 'collection', scope: 'default' }),
      )
    },
  })

  return {
    ...query,
    addFavorite: {
      ...createFavoriteMutation,
    },

    removeFavorite: {
      ...deleteFavoriteMutation,
    },
  }
}
