import { Loader } from 'src/core/ds/loader'
import { Snap } from 'src/entities/snap'
import styles from './index.module.scss'

export function GenProgressStatus({ snap }: { snap: Snap }) {
  const { status } = snap

  if (status === 'created') {
    return (
      <div className={styles.floatStatus}>
        <Loader />
        Waiting in a queue
      </div>
    )
  }

  if (status === 'pending') {
    return (
      <div className={styles.floatStatus}>
        <Loader />
        In a queue
      </div>
    )
  }

  if (status === 'processing') {
    return (
      <div className={styles.floatStatus}>
        <Loader />
        Processing&nbsp;
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {snap?.progress}%
      </div>
    )
  }

  return null
}
