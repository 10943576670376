import { Link } from 'react-router-dom'
import { LanguageSwitcher } from 'src/components/LanguageSwitcher'
import { UserArea } from 'src/components/UserArea'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { FormattedText } from 'src/core/ds/FormattedText'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { useIsSubscriber } from 'src/entities/account/useIsSubscriber'
import { Logo } from '../Logo'
import { SideNavigation } from '../SideNavigation'
import styles from './index.module.scss'

export function DashboardHeader() {
  const { data: currentUser } = useCurrentAccount()
  const { isSubscriber } = useIsSubscriber()

  return (
    <aside>
      <div className={styles.headerWrapper}>
        <Link to="/">
          <Logo />
        </Link>

        <div className={styles.userAreaMobile}>
          <LanguageSwitcher />
          <UserArea currentUser={currentUser} />
        </div>
      </div>

      <div className={styles.rightHeaderSection}>
        <SideNavigation />
        <div className={styles.userAreaBottom}>
          {!isSubscriber && (
            <div className={styles.upsell}>
              <Link to="/prices#paidPlan">
                <Button type="button" size={ButtonSizes.xs} label="upgrade.button" />
                <span>
                  <FormattedText id="upgrade.button.description" />
                </span>
              </Link>
            </div>
          )}
          <LanguageSwitcher />
          <UserArea currentUser={currentUser} />
        </div>
      </div>
    </aside>
  )
}
