import { isString } from 'lodash'
import { useInfiniteQuery, useIsMutating, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query'
import { isServerErrorShouldBeHandled } from 'src/hooks/serverErrors.hook'
import { useServerNotification } from 'src/hooks/serverNotification.hook'
import { ApiError } from './types'

export type Override<T1, T2> = Omit<T1, keyof T2> & T2

const cleanQueryKey = (queryKey: any): [any, unknown[]] => {
  if (isString(queryKey)) {
    return [queryKey, []]
  }

  if (Array.isArray(queryKey)) {
    const [key, ...rest] = queryKey

    if (!isString(key)) {
      throw new Error(`expected the first element of queryKey to be a string, found ${typeof key}`)
    }

    return [key, rest.filter((value) => !!value)]
  }

  throw new Error('expected queryKey to be a string or an array')
}

export function createQueryKey({ queryKey: key, role, scope, id, params = [] }: any): any {
  const [queryKey, extraParams] = cleanQueryKey(key)

  if (role === 'collection') {
    return [queryKey, role, scope, ...params, ...extraParams]
  }

  return [queryKey, role, id, scope, ...params, ...extraParams]
}

export type ModelProps<TQueryFnData, TData> = Pick<
  UseQueryOptions<TQueryFnData, ApiError, TData>,
  'queryFn' | 'enabled' | 'initialData' | 'select' | 'cacheTime'
> & {
  id?: string
  scope?: string
  queryKey: string
  refetchInterval?: number
  refetchOnMount?: boolean | 'always'
  cacheTime?: number
}

type SharedResultsKeys = Extract<
  keyof UseQueryResult,
  'isLoading' | 'isFetching' | 'isFetched' | 'error' | 'refetch' | 'remove' | 'data'
>

type ModelDeleteMethod<TDelete extends boolean> = TDelete extends true
  ? {
      delete: () => Promise<unknown>
    }
  : // eslint-disable-next-line @typescript-eslint/ban-types
    {}

// eslint-disable-next-line @typescript-eslint/ban-types
type ModelUpdateMethod<TData, UData> = [UData] extends [never] ? {} : { update: (data: UData) => Promise<TData> }

export type UseModelApiResult<
  TQueryFnData,
  UData = never,
  TDelete extends boolean = false,
  TData = TQueryFnData,
> = Pick<UseQueryResult<TData, ApiError>, SharedResultsKeys> &
  ModelDeleteMethod<TDelete> &
  ModelUpdateMethod<TQueryFnData, UData> & {
    queryKey: string
    isUpdating: boolean
  }

export type UseCollectionApiProps<T = unknown, TData = T> = Pick<
  CollectionProps<T, TData>,
  'enabled' | 'scope' | 'refetchOnMount' | 'cacheTime' | 'suspense' | 'select'
>

export type UseModelApiProps<T = unknown> = Pick<
  ModelProps<T, T>,
  'enabled' | 'id' | 'scope' | 'refetchInterval' | 'refetchOnMount' | 'cacheTime'
>

export const useModelApi = <TQueryFnData, TData = TQueryFnData>({
  queryKey: _queryKey,
  enabled = true,
  scope = 'default',
  id,
  ...options
}: ModelProps<TQueryFnData, TData>) => {
  const queryKey = createQueryKey({ queryKey: _queryKey, role: 'model', scope, id })
  const { notify } = useServerNotification()
  const query = useQuery<TQueryFnData, ApiError, TData>(queryKey, {
    ...options,
    enabled: enabled && !!id,
    onError: (err) => isServerErrorShouldBeHandled(err) && notify(err),
  })
  const isUpdating = useIsMutating(queryKey) > 0

  return {
    ...query,
    queryKey,
    isUpdating,
    update: () => {
      throw new Error('update method not implemented')
    },
    delete: () => {
      throw new Error('delete method not implemented')
    },
  }
}

export type CustomUseQueryOptions<TQueryFnData, TData = TQueryFnData> = Pick<
  UseQueryOptions<TQueryFnData, ApiError, TData>,
  'queryFn' | 'enabled' | 'initialData' | 'select' | 'cacheTime' | 'suspense'
> & {
  refetchOnMount?: boolean | 'always'
}
export type CreateFn<TData, CData> = (params: CData) => Promise<TData>

export type CollectionProps<TQueryFnData, TData = TQueryFnData> = CustomUseQueryOptions<TQueryFnData[], TData[]> & {
  scope?: string
  queryKey: string
  refetchOnMount?: boolean | 'always'
}
// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyType = {}

type CreateMethod<TData, CData> = [CData] extends [never] ? EmptyType : { create: CreateFn<TData, CData> }

export type UseCollectionApiResult<TQueryFnData, CData = never, TData = TQueryFnData> = Pick<
  UseQueryResult<TData[], ApiError>,
  SharedResultsKeys
> &
  CreateMethod<TQueryFnData, CData> & {
    queryKey: string
    isMutating: boolean
  }

export const useCollectionApi = <TQueryFnData, CData = never, TData = TQueryFnData>({
  queryKey: _queryKey,
  scope = 'default',
  ...options
}: CollectionProps<TQueryFnData, TData>): UseCollectionApiResult<TQueryFnData, CData, TData> => {
  const queryKey = createQueryKey({ queryKey: _queryKey, role: 'collection', scope })
  const { notify } = useServerNotification()
  const queryClient = useQueryClient()

  const create: CreateFn<TQueryFnData, CData> = () => {
    throw new Error('create method not implemented')
  }

  const query = useQuery<TQueryFnData[], ApiError, TData[]>(queryKey, options)

  return {
    ...query,
    queryKey,
    create,
    onError: (err) => notify(err),
    isMutating: queryClient.isMutating({ mutationKey: queryKey }) > 0,
    isFetching: queryClient.isFetching(queryKey) > 0,
    update: () => {
      throw new Error('update method not implemented')
    },
  }
}

export const useInfiniteCollectionApi = ({ queryKey: _queryKey, scope = 'default', ...options }) => {
  const queryKey = createQueryKey({ queryKey: _queryKey, role: 'collection', scope })
  const { notify } = useServerNotification()
  const queryClient = useQueryClient()

  const create = () => {
    throw new Error('create method not implemented')
  }

  const query = useInfiniteQuery(queryKey, {
    ...options,
    getNextPageParam: (lastPage: any) =>
      // lastPage?.length >= 20 ? pages.length * 20 : false

      lastPage?.pagination?.next_page ?? undefined,
  })

  return {
    ...query,
    queryKey,
    create,
    onError: (err) => notify(err),
    isMutating: queryClient.isMutating({ mutationKey: queryKey }) > 0,
    isFetching: queryClient.isFetching(queryKey) > 0,
    update: () => {
      throw new Error('update method not implemented')
    },
  }
}
