import { useEffect } from 'react'
import { Button } from 'src/core/ds/button'
import { FormattedText } from 'src/core/ds/FormattedText'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { FlowLayout } from 'src/layouts/flow/Flow.layout'

export function ModelReviewFlow(props) {
  const { onCompleteFlow, onCancelFlow } = props
  const { fireEvent } = useAnalytics()
  useEffect(() => {
    fireEvent(ANALYTICS_EVENTS.ModelFlowReviewView)
  }, [])

  return (
    <FlowLayout>
      <FlowLayout.Header onCancelFlow={onCancelFlow} />
      <FlowLayout.Body>
        <FlowLayout.Title type="success">
          <h1>
            <FormattedText id="addModel.review.title" />
          </h1>
        </FlowLayout.Title>
        <FlowLayout.Description>
          <FormattedText id="addModel.review.description" />
        </FlowLayout.Description>

        <FlowLayout.Footer>
          <Button onClick={() => onCompleteFlow('/dashboard')} type="button" label="addModel.review.button.complete" />
        </FlowLayout.Footer>
      </FlowLayout.Body>
    </FlowLayout>
  )
}
