/* eslint-disable react/no-unescaped-entities */
import { Helmet } from 'react-helmet'
import { Container } from 'src/core/ds/container'
import { ScrollToTop } from 'src/hooks/scrollToTop'
import { Footer } from 'src/layouts/Footer'
import { Header } from 'src/layouts/Header'
import { Upsell } from 'src/screens/landing/parts/Upsell'
import { blogData } from './data'
import styles from './index.module.scss'

const data = blogData['1']

export function BlogPageOne() {
  ScrollToTop()

  return (
    <>
      <Header />
      <div
        className={styles.surface}
        style={{
          backgroundImage: `url(${data.image})`,
        }}
      >
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.description} />
          <meta property="og:title" content={data.title} />
          <meta property="og:description" content={data.description} />
        </Helmet>
        <div className={styles.hero}>
          <Container>
            <h1>{data.title}</h1>
            <p>{data.description}</p>
          </Container>
        </div>
      </div>
      <div className={styles.content}>
        <Container>
          <div className={styles.date}>
            Published:
            {data.date}
          </div>

          <p>
            In a world where social media is king, and your feed is your kingdom, making sure every post is
            picture-perfect is no small feat. Gone are the days when you needed a fancy camera and a plane ticket to
            some exotic locale to capture that jaw-dropping shot for Instagram. In this digital age, the power of AI has
            transformed the way we create content. With a few taps on your screen, you can conjure up images that would
            have once taken hours, if not days, to produce. So, let's dive into the rabbit hole and explore how to
            create stunning Instagram photos without stepping foot outside your door.
          </p>

          <h2>The Magic Wand of AI Photo Editing</h2>

          <h3>Snap and Edit, It's That Simple!</h3>

          <ul>
            <li>
              AI-Powered Filters: Say goodbye to mundane edits. Modern apps come with filters that do more than adjust
              the contrast; they can add a sunset to a dull sky or turn day into night.
            </li>
            <li>
              Smart Selection Tools: Need to get rid of that pesky photo-bomber? AI selection tools can recognize and
              remove unwanted elements in a snap.
            </li>
            <li>
              Auto Enhance: Not sure where to start? Auto enhance features can tweak your photo to perfection with just
              one tap.
            </li>
          </ul>

          <h3>Tailoring Your Edits to Perfection</h3>

          <ul>
            <li>
              Adjust the Mood: With AI, you can alter the mood of your photo. Want a vintage feel or a futuristic vibe?
              No problem!
            </li>
            <li>
              Refine Details: Sharpening, blurring, and texture adjustments are all at your fingertips, ensuring every
              detail is just right.
            </li>
          </ul>

          <h2>The Art of Composing Without Composting</h2>
          <h3>Finding Beauty in the Mundane</h3>

          <p>
            Who said you need an epic backdrop? That potted plant or the pattern on your rug might just be the next star
            of your feed. It's all about framing and perspective. Look for interesting shadows, textures, or colors
            right in your home.
          </p>

          <h3>Props and Concepts: A Little Goes a Long Way</h3>

          <ul>
            <li>
              Props: A simple prop can elevate a photo from "meh" to "wow." Scarves, books, and even your morning coffee
              can work wonders.
            </li>
            <li>Concepts: Create a narrative. Tell a story with your photo, and the likes will follow.</li>
          </ul>

          <h2>Lighting: The Unsung Hero of Photography</h2>
          <h3>Harness Natural Light</h3>

          <p>
            Even the most advanced AI can't fix bad lighting. Find the best-lit spot in your house, perhaps near a
            window, and work with the sun, not against it.
          </p>

          <h3>DIY Studio Lighting</h3>

          <p>
            Can't find the light? Make it! A simple lamp and some white paper to diffuse it can mimic professional
            studio lighting.
          </p>

          <h3>Virtual Backgrounds and Scenery</h3>
          <p>
            Why not transport yourself to Paris or Bali with the magic of virtual backgrounds? AI technology allows you
            to insert new backgrounds seamlessly.
          </p>

          <h2>FAQ</h2>

          <h3>Can AI really make my photos look professional?</h3>

          <p>
            Absolutely! With the right tools and a bit of creativity, AI can elevate your photos to a professional
            standard.
          </p>

          <h3>Do I need to be good at photography to use AI editing apps?</h3>

          <p>
            Not at all. Many AI apps are user-friendly and designed for beginners. They often include tutorials to help
            you along the way.
          </p>

          <h3>Is it cheating to use AI instead of traditional photography methods?</h3>
          <p>
            No way! Think of AI as another tool in your creative arsenal. It's all about the end result, and if AI gets
            you there, why not use it?
          </p>

          <h2>Conclusion</h2>

          <p>
            Creating stunning Instagram photos without leaving home has never been easier, thanks to the power of AI.
            From revamping your editing process to finding beauty in the simplest things, the possibilities are endless.
            Embrace the future of photography and let AI be your guide. Who needs a passport when you've got
            cutting-edge technology right at your fingertips? So go ahead, give it a whirl, and watch your Instagram
            feed transform into a tapestry of AI-powered masterpieces.
          </p>
        </Container>
      </div>
      <Upsell />

      <Footer />
    </>
  )
}
