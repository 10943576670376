import { Link } from 'react-router-dom'
import { Scroller } from 'src/components/Scroller/Scroller'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { FormattedText } from 'src/core/ds/FormattedText'
import { useExplorerSnaps } from 'src/entities/snap/useExplorerSnaps'
import { PhotoItem } from 'src/widgets/PhotoList/components/PhotoItem'
import styles from '../index.module.scss'

export function Grid({ withLink = true }) {
  const { data: snapPages } = useExplorerSnaps()

  const snaps: any = snapPages?.pages?.flatMap((page) => page?.items ?? page)

  return (
    <section className={styles.faq}>
      <h2>
        <FormattedText id="landing.grid.title" />
      </h2>

      <div className={styles.grid}>
        <Scroller direction="left" speed="slow">
          {snaps?.flatMap(
            (snap) =>
              snap.account.id.toString() !== '147153057904328705' &&
              snap.thumbs?.map((photo, index) =>
                index % 4 === 0 ? (
                  <PhotoItem
                    key={index}
                    // allowedFeatures={allowedFeatures}
                    downloadableUrl={snap.photos[index]}
                    showPrompt
                    photoIndex={index}
                    info={snap.info}
                    prompt={snap.prompt.text}
                    photo={photo}
                  />
                ) : null,
              ),
          )}
        </Scroller>
      </div>

      {withLink && (
        <div style={{ margin: '0 auto', justifyContent: 'center', marginTop: '2rem' }} className={styles.form}>
          <Link to="/explore">
            <Button type="button" size={ButtonSizes.xl} label="explore.action" />
          </Link>
        </div>
      )}
    </section>
  )
}
