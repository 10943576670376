/* eslint-disable react/no-unescaped-entities */
import { Helmet } from 'react-helmet'
import { Container } from 'src/core/ds/container'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import styles from './index.module.scss'
import { Blog } from './parts/Blog'
import { Comparison } from './parts/Comparison'
import { Faq } from './parts/Faq'
import { Features } from './parts/Features'
import { Grid } from './parts/Grid'
import { Hero } from './parts/Hero'
import { PricesNew } from './parts/PricesNew'
import { Testimonials } from './parts/Testimonials'
import { Upsell } from './parts/Upsell'

declare global {
  interface Window {
    cfToken: string
    plausible: any
  }
}

export function LandingScreen() {
  const { data: currentUser } = useCurrentAccount()

  return (
    <AppLayout>
      <Helmet>
        <link rel="canonical" href="https://snaptap.ai" />
      </Helmet>
      <div className={styles.surface}>
        <Hero currentUser={currentUser} />
      </div>
      <Container>
        <Features />

        <Grid />

        <Comparison />

        <section className={styles.cost}>
          <PricesNew isInnerBlock={false} />
        </section>

        <Testimonials />

        <Blog />

        <Faq />

        <Upsell />
      </Container>
      <Footer />
    </AppLayout>
  )
}
