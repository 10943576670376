import { useState } from 'react'
import { FormattedText } from '../FormattedText'
import { Icon, IconNames, IconSize } from '../icon'
// eslint-disable-next-line import/no-cycle
import { Modal } from '../modal'
import { SimpleLoader } from '../spinner'
import styles from './index.module.scss'
import { ButtonSizes, ButtonType, ButtonVariants } from './types'

type ButtonProps = {
  type: ButtonType
  size?: ButtonSizes
  variant?: ButtonVariants
  onClick?: any
  label?: string
  icon?: IconNames
  isLoading?: boolean
  isIconOnLeft?: boolean
  iconColor?: string
  tooltip?: string
  tooltipOnLeft?: boolean
  disabled?: boolean
  isFullWidth?: boolean
  confirmPopup?: boolean
}

export function Button({
  type = 'button',
  variant = ButtonVariants.primary,
  icon,
  isLoading = false,
  size = ButtonSizes.md,
  onClick,
  label,
  isIconOnLeft = false,
  iconColor = '#fff',
  tooltip,
  tooltipOnLeft = false,
  disabled = false,
  isFullWidth = false,
  confirmPopup = false,
}: ButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (confirmPopup && !isModalOpen) {
      setIsModalOpen(true)
    } else {
      onClick?.(e)
    }
  }

  const handleConfirm = () => {
    setIsModalOpen(false)
    onClick?.()
  }

  return (
    <>
      <button
        // eslint-disable-next-line
        type={type}
        onClick={handleButtonClick}
        disabled={disabled || isLoading}
        className={`
        ${styles.button}
        ${styles[variant]}
        ${styles[size]}
        ${isIconOnLeft ? styles.leftIcon : ''}
        ${isFullWidth ? styles.fullWidth : ''}
			`}
      >
        {tooltip && (
          <div className={`${styles.tooltip} ${tooltipOnLeft ? styles.leftTooltip : undefined}`}>
            <FormattedText id={tooltip} />
          </div>
        )}

        {isLoading ? (
          <SimpleLoader />
        ) : (
          (
            <>
              <span>
                <FormattedText id={label} />
              </span>
              <Icon size={IconSize.xs} name={icon} color={iconColor} />
            </>
          ) || null
        )}
      </button>
      {confirmPopup && (
        <Modal
          isOpen={isModalOpen}
          title="Are you sure?"
          type="default"
          onClose={() => setIsModalOpen(false)}
          onDeny={() => setIsModalOpen(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  )
}
