import heic2any from 'heic2any'
import { useEffect, useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import styles from '../index.module.scss'

const MIN_HEIGHT = 1216
const MIN_WIDTH = 832

export function FilePreview({ file, onDelete, updateBadPhotosCount }) {
  const [previewUrl, setPreviewUrl] = useState<any>()
  const [error, setError] = useState('')

  useEffect(() => {
    const reader = new FileReader()
    reader.onloadend = () => {
      // If the file is a HEIC image, convert it to JPEG using heic2any
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        heic2any({
          blob: file,
          toType: 'image/png',
          // multiple: true,
          quality: 0.1,
        })
          .then((conversionResult) => {
            // Use the converted blob as an image source
            const url = URL.createObjectURL(conversionResult)
            setPreviewUrl(url)
            validateImage(url, file)
          })
          .catch((conversionError) => {
            console.error('Error converting HEIC image', conversionError)
            setError('Error converting image.')
          })
      } else {
        setPreviewUrl(reader.result)
        validateImage(reader.result, file)
      }
    }

    if (file.type === 'image/heic' || file.type === 'image/heif') {
      reader.readAsArrayBuffer(file) // Read as buffer for HEIC conversion
    } else {
      reader.readAsDataURL(file) // Read as DataURL for other images
    }

    return () => {
      // Clean up the object URL if it was created
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [file])

  function validateImage(src, file) {
    const img = new Image()
    img.onload = () => {
      if (img.width < MIN_WIDTH) {
        updateBadPhotosCount(file.name)
        setError(`Wrong width: ${img.width}. Should be min: ${MIN_WIDTH}`)
      }

      if (img.height < MIN_HEIGHT) {
        updateBadPhotosCount(file.name)
        setError(`Wrong height: ${img.height}. Should be min: ${MIN_HEIGHT}`)
      }
    }
    img.onerror = () => {
      setError('Image cannot be displayed.')
    }
    img.src = src
  }

  // useEffect(() => {
  //   const reader = new FileReader()
  //   reader.onloadend = () => {
  //     setPreviewUrl(reader.result)
  //     const img = new Image()
  //     img.onload = () => {
  //       if (img.width < MIN_WIDTH) {
  //         updateBadPhotosCount(file.name)
  //         setError(`Wrong width: ${img.width}. Should be min: ${MIN_WIDTH}`)
  //       }

  //       if (img.height < MIN_HEIGHT) {
  //         updateBadPhotosCount(file.name)
  //         setError(`Wrong height: ${img.height}. Should be min: ${MIN_HEIGHT}`)
  //       }
  //     }
  //     // eslint-disable-next-line
  //     // @ts-ignore
  //     img.src = reader.result
  //   }
  //   reader.readAsDataURL(file)

  //   return () => URL.revokeObjectURL(previewUrl)
  // }, [file])

  return (
    <div className={`${styles.filePreview} ${error ? styles.invalid : ''}`}>
      <img className={styles.photoPreviewImg} src={previewUrl} alt={file.name} />

      <Button variant={ButtonVariants.unstyled} icon={IconNames.delete} type="button" onClick={() => onDelete(file)} />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  )
}
