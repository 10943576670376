import toast from 'react-hot-toast'
import { FormattedMessage } from 'react-intl'
import { IconNames } from 'src/core/ds/icon'
import { Notification } from 'src/core/ds/notification'

export function pushNotification(item: {
  type: 'success' | 'error' | 'default'
  title: string
  subtitle?: string
  icon?: IconNames
  autoClose?: number | false
  testId?: string
  isForeverDuration?: boolean
}) {
  const { type, isForeverDuration, title, subtitle } = item

  // analytics.trackAction('toast-notification', { type })
  // return toast('Here is your toast.')

  return toast.custom(
    (t) => (
      <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} `}>
        <Notification
          // interactLabel="Click me"
          // onInteract={() => alert('interact')}
          onClose={() => toast.dismiss(t.id)}
          type={type}
          subtitle={subtitle}
          title={<FormattedMessage id={title} />}
        />
      </div>
    ),
    {
      duration: isForeverDuration ? 999999 : 4000,
    },
  )
}
