export const locale = {
  'en-US': {
    // 'landing.hero.title': 'Perfect {br} <span>Instagram photos</span>{br}in minutes',
    'landing.hero.title': '<span>Blow Up Instagram</span> {br}with Next-Level AI Portraits',
    'landing.hero.subtitle':
      'Professional photos without leaving your home. Surprise your friends and attract new followers.',
    'landing.hero.action': 'Get started for free',
    'landing.hero.noCredit': 'No credit card required',
    'landing.hero.dashboard': 'Open dashboard',
    'landing.howitworks.title': 'How it works',
    'landing.howitworks.description': 'Start generating perfect Instagram photos in as little as 30 minutes.',
    'landing.features.title': 'How it works',
    'landing.features.description': 'Start generating perfect Instagram photos in as little as 30 minutes.',
    'landing.features.featureOne.title': 'Create your own AI character',
    'landing.features.featureOne.description': `Create photography with artificial intelligence by training your own AI character. Upload a set of just minimum 5 photos in a diverse range of places, settings, times, and attire. By inputting these images into your AI character, you're teaching it to recognize and replicate it.`,
    'landing.features.featureOne.action': `Try now`,
    'landing.testimonials.title': 'Loved by customers',
    'landing.testimonials.description': 'Save money and time',
    'landing.grid.title': `People creating now`,
    'landing.blog.title': 'Blog',
    'landing.grid.action': `Get started fo free`,
    'landing.trytoday.title': `Try Snaptap today`,
    'landing.trytoday.description': `Change your Instagram game 🚀`,
    'landing.trytoday.action': `Get started for free`,
    'landing.trytoday.noCard': `No credit card required`,
    'landing.trytoday.openDash': `Open dashboard`,
    'landing.faq.title': 'Frequently Asked Questions (FAQs)',
    'landing.faq.description': 'Find answers to your questions about using Snaptap.',
    'landing.faq.q1.question': 'Does Midjourney have an API?',
    'landing.faq.q1.answer':
      ' Midjourney does not have an official API, but the Snaptap will allow you to work with Midjourney data in a programmatic way for whatever your creative needs are.',

    'landing.prices.title': 'Prices',
    'landing.prices.description': 'Unleash the power of API with a PRO account.',

    'landing.navigation.api': 'How it works',
    'landing.navigation.prices': 'Prices',
    'landing.navigation.faq': 'FAQ',
    'landing.navigation.subscribe': 'Subscribe',
    'landing.navigation.dashboard': 'Dashboard',

    'login.description': 'No password is required.{br}You need to obtain a new one-time code each time you log in.',
    'login.continue': 'Continue',
    'login.verification.title': 'Code verification',
    'login.verification.resendText': 'Resend code in {seconds} seconds',
    'login.verification.resendAction': 'Resend code',
    'login.verification.description':
      'We just sent you a one-time login code. Please, check your inbox or spam. May take a few minutes. Code expires in 5 mins.',

    'favorites.page.title': 'Favorites',
    'favorites.page.availability': 'This feature is available only for subscribers.',
    'favorites.page.availability.upgrade': 'Upgrade',
    'favorites.page.empty.title': 'Add photo to favorites and it will appear here',
    'favorites.page.empty.subtitle': 'No favorites added',

    'photo.actions.copy': 'Copy prompt',
    'photo.actions.upscale': 'Upscale photo',
    'photo.actions.upscale.download': 'Download 4k',
    'photo.actions.nsfw': 'Toggle NSFW',
    'photo.actions.favorite.unavailable': 'Need subscription',

    'status.widget.title': 'Status',
    'status.widget.inactive': 'No active process',
    'status.widget.current': 'Current ',
    'status.widget.queue': 'In a queue ',

    'navigation.top.dashboard': 'Dashboard',
    'navigation.top.favorites': 'Favorites',
    'navigation.top.explore': 'Explore',
    'navigation.top.prices': 'Prices and Plans',

    'notification.auth.logout.success': 'You was logged out.',
    'notification.auth.logout.fail': 'Can not do logout',
    'notification.snap.genStarted': 'Generation started',
    'notification.code.success': 'Code sent',
    'notification.code.fail': 'Error sending code',

    'notification.model.delete.success': 'Model deleted.',
    'notification.model.delete.fail': 'Can not delete model',
    'notification.model.training.started': 'Training started.',
    'notification.model.training.failed': 'Can not start training',
    'notification.model.prompt.empty': 'Please, fill the prompt field',
    'notification.model.prompt.overflow': '160 words is a max for prompt.',
    'notification.model.negative.overflow': '40 words is a max for negative.',

    'notification.common.textCopy': 'Copied to clipboard',
    'notification.favorites.added': 'Added to favorites',
    'notification.favorites.removed': 'Removed from favorites',
    'notification.favorites.error': 'Error updating favorites',

    'favorites.tooltip.remove': 'Remove from favorites',
    'favorites.tooltip.add': 'Add to favorites',

    'pricesNew.title': 'Plans & Pricing',
    'pricesNew.freePlan.title': '$0/month',
    'pricesNew.freePlan.description':
      'Free. Forever. Sign up and start creating excellent photo shoots. Choose to upgrade later for better quality and experience.',

    'pricesNew.signUp': 'Sign up for free',
    'pricesNew.paidPlan.header': 'Paid plans',
    'pricesNew.paidPlan.title':
      'Are you serious about generating photos? {br}Try a paid plan to boost speed and experience.',

    'pricesNew.paidPlan.join': 'Join 20+ others',

    'pricesNew.paidPlan.price': '$19.90/m',
    'pricesNew.paidPlan.cancel': 'Cancel anytime',
    'pricesNew.paidPlan.current': 'Your current plan',
    'pricesNew.paidPlan.getStarted': 'Get started for free',
    'pricesNew.paidPlan.manage': 'Manage your plan',

    'pricesNew.paidPlan.start': 'Start',
    'pricesNew.paidPlan.start.description': 'Everything in free plan',

    'pricesNew.asyougo.title': 'Pay as you go',
    'pricesNew.asyougo.description': 'Pay only when you need it',
    'pricesNew.asyougo.input.label': 'Amount',
    'pricesNew.asyougo.input.action': 'Buy',
    'pricesNew.asyougo.input.description':
      'Min payment is $10. Steps in $10. The amount is rounded up (ex. $12 -> $20).',

    'pricesNew.subscribe.button': 'Get started for $19.90/m',
    'pricesNew.subscribe.feature1': 'Favorites feature',
    'pricesNew.subscribe.feature2': '3 AI characters',
    'pricesNew.subscribe.feature3': '2x Faster generations (upcoming)',
    'pricesNew.subscribe.feature4': 'Advanced filters',
    'pricesNew.subscribe.feature5': 'Advanced settings',
    'pricesNew.subscribe.feature6': 'History without expiring',
    'pricesNew.subscribe.feature7': '+10% on snaps refill',
    'pricesNew.subscribe.feature8': 'Parallel snap generations',
    'pricesNew.subscribe.feature9': 'Stealth mode (upcoming)',
    'pricesNew.subscribe.feature10': 'HQ Quality',

    'modelList.title': 'My Characters',
    'modelList.select': 'Select character to start',
    'modelList.statuses': 'About statuses',
    'modelList.tooltip':
      'Created – Ready for the train.{br}Pending – In a queue.{br}Processing – Model training.{br}Ready – Start generating photos.',

    'modelList.add.title': 'Add character',
    'modelList.add.onboarding': `To start creating photos, you need to train the uploaded character. Usually, it takes about 30 minutes. Select an available model and click "train"`,
    'modelList.add.upload': 'Upload your photos',
    'modelList.add.upgrade.title': 'Upgrade account',
    'modelList.add.upgrade.description': 'Max number of characters for your plan is ',

    'creationForm.available': 'Available actions',
    'creationForm.trains': 'Trains left',
    'creationForm.actions.train': 'Train model',
    'creationForm.actions.buyTrains': 'Buy more trains',
    'creationForm.actions.retrain': 'Retrain model',
    'creationForm.actions.remove': 'Remove',
    'creationForm.actions.title': 'Creation form',
    'creationForm.actions.prompt': 'Photo of {name}',
    'creationForm.actions.settings': 'Advanced settings',
    'creationForm.actions.settings.weight.title': 'Character Weight',
    'creationForm.actions.settings.camera.title': 'Camera settings',
    'creationForm.actions.settings.sampler.title': 'Sampler settings',
    'creationForm.actions.settings.camera.off': 'settings disabled',
    'creationForm.actions.settings.strictness.title': 'Prompt strictness',
    'creationForm.actions.settings.strictness.tooltip': 'Less is more creative; more is closer to the prompt.',
    'creationForm.actions.settings.hq.title': 'HQ',
    'creationForm.actions.settings.hq.tooltip':
      'It produces higher resolution photos with more details, but is less stable. It takes two shots per generation and operates more slowly.',
    'creationForm.actions.settings.enhancement.title': 'Face enhancement',
    'creationForm.actions.settings.enhancement.tooltip':
      'If enabled, a separate setting will be applied to enhance facial details.',
    'creationForm.actions.settings.faceSwap.title': 'Face swap',
    'creationForm.actions.settings.faceSwap.tooltip': 'Face swap',
    'creationForm.actions.settings.publicity.title': 'Publicly available photos',
    'creationForm.actions.settings.publicity.tooltip':
      'Your photos, which will be generated, will be publicly available: on the homepage of the website and on the Explorer page. In the future, when you subscribe, you will be able to disable the public display of your photos.',
    'creationForm.actions.settingsPRO': 'Advanced settings (need paid account)',
    'creationForm.actions.character': 'Character Weight',
    'creationForm.actions.character.tooltip': 'How closely you want the generated photo to match a specific character.',
    'creationForm.actions.negative': 'Negative prompt',
    'creationForm.actions.negative.tooltip':
      'Words or phrases you tell the AI to avoid. Use this to steer clear of unwanted elements in your photo.',
    'creationForm.actions.seed': 'Seed',
    'creationForm.actions.seed.tooltip':
      'A unique starting point for the AI to generate your photo. Think of it as the initial inspiration for the artwork',

    'creationForm.actions.presets': 'Presets',
    'creationForm.actions.generate': 'Take photos',
    'creationForm.actions.buyPhotos': 'Buy photos',
    'creationForm.actions.concurrency': 'The max num of concurrent requests in your plan is',
    'creationForm.actions.snapsLeft': 'snaps left:',

    'footer.sub.title': `Don't miss updates:`,
    'footer.sub.action': 'Subscribe',
    'footer.sub.contact': 'Contact:',

    'header.signin': 'Sign in',
    'header.signup': 'Get started',

    'navigation.mobile.billing': 'Billing',
    'navigation.mobile.explore': 'Explore',
    'navigation.mobile.create': 'Create',
    'navigation.mobile.favorites': 'Favorites',
    'navigation.mobile.snaps': 'Snaps',

    'faq.section.q1': 'Will the photos have AI artefacts?',
    'faq.section.a1':
      'Sometimes yes. Depending on the quality of the uploaded photos, and the type of photos you take, you might see some AI artefacts. Any app that uses generative AI imaging will have artefacts. They can be illogical things like extra arms, deformed faces etc.',

    'faq.section.q2': 'How long will it take to create my AI character?',
    'faq.section.a2': 'Based on the current processing times, it takes about 30 minutes to create an AI character.',

    'faq.section.q3': 'How long will it take to take an AI photo?',
    'faq.section.a3':
      'Based on the current processing times, it takes about 40 seconds to take one photo. You can take up to 4 photos in parallel.',

    'faq.section.q4': 'What will you do with my AI character photos?',
    'faq.section.a4':
      'We only use your photos to create an AI character. Your data privacy and security are our top priorities.',

    'faq.section.q5': 'Can I use Snaptap to try on clothes and hairstyles?',
    'faq.section.a5': 'You can try different outfits and hairstyles by describing a prompt.',

    'faq.section.q6': 'How much will the photos look like me?',
    'faq.section.a6':
      ' The resemblance of the photos to you depends on the quality and variety of the photos you upload. The better and more varied your photos, the more accurately the AI can understand and represent your unique characteristics. We keep improving our models every day.',

    'faq.section.q7': 'How do I cancel my subscription?',
    'faq.section.a7': `You can cancel your subscription at any time. Go to the Prices and Plans page when you are logged in and click Manage my Plan, you will be redirected to Stripe's billing portal where you can download invoices, switch plans and cancel your subscription.`,

    'faq.section.q8': 'What kind of pictures should I upload to develop an AI character?',
    'faq.section.a8': `It's advisable to choose images that show a wide range of scenes, including both close-up portraits and
            full-length pictures, taken in different settings, from various perspectives, wearing diverse outfits, and
            showcasing a variety of facial expressions. Avoid selecting pictures that are repetitive, feature groups,
            include other individuals, or have accessories like sunglasses and hats. Also, steer clear of photos where
            your face is obscured or not clearly visible.`,

    'addModel.create.title': 'Add new model',
    'addModel.create.title.welcome': 'Welcome to Snaptap!',
    'addModel.create.title.welcome.description': `Before starting to create photos that look like you, let's create your first AI character. Simply give it any name.`,
    'addModel.create.input.name': 'Character name',
    'addModel.create.input.description': 'Give your AI character a name',
    'addModel.create.button.continue': 'Continue',

    'addModel.upload.title': 'Upload photos',
    'addModel.upload.description': 'Upload a collection of high-quality images to start working with an AI model.',
    'addModel.upload.expected': 'Expected photos:',
    'addModel.upload.final': 'Your photos:',
    'addModel.upload.button.upload': 'Continue',

    'addModel.review.title': 'Now only forward!',
    'addModel.review.description':
      'Creating the model will take about 30 minutes. {br}You will receive an email when it is done. Now you can make some tea in the meantime.',
    'addModel.review.button.complete': 'Complete',

    'emptyStates.noPhotos.title': 'No photos yet',
    'emptyStates.noPhotos.description': 'Generate something and it will appear here',

    'upgrade.button': 'Upgrade!',
    'upgrade.button.description': 'Boost perfomance',

    'explore.action': 'Explore more',

    'languages.english': 'English',
    'languages.russian': 'Русский',
    'languages.thai': 'ภาษาไทย',

    'common.loadMore': 'Load more',
    'common.copyNegative': 'Copy negative',

    'server.error.notAuthorized': 'You are not authorized.',
    'server.error.unhandledMessage': 'Unknown error.',
    'server.error.AuthMissing': 'Invalid code',
    'server.error.Ready': 'Character is not ready yet',
    'server.error.InsufficientBalance': 'Not enough balance',
    'server.error.concurrencyOverflow': 'Too many parallel requests',
  },
  'ru-RU': {
    // 'landing.hero.title': 'Идеальные {br} <span>фотографии в Instagram</span>{br} за минуты',
    'landing.hero.title': '<span>Взорвите Instagram</span> {br} фотосессиями с помощью ИИ',
    'landing.hero.subtitle':
      'Профессиональные фотографии не выходя из дома. Удивите своих друзей и привлеките новых подписчиков.',
    'landing.hero.action': 'Начать бесплатно',
    'landing.hero.noCredit': 'Без кредитной карты',
    'landing.hero.dashboard': 'Открыть дашборд',
    'landing.howitworks.title': 'Как это работает',
    'landing.howitworks.description': 'Начните создавать идеальные фотографии для Instagram всего за 30 минут.',
    'landing.features.title': 'Как это работает',
    'landing.features.description': 'Начните создавать идеальные фотографии для Instagram всего за 30 минут.',
    'landing.features.featureOne.title': 'Создайте своего ИИ персонажа',
    'landing.features.featureOne.description':
      'Создавайте фотографии с искусственным интеллектом, тренируя своего ИИ персонажа. Загрузите минимум 5 фотографий в разнообразных местах, обстановках, временах и одежде. Вводя эти изображения в вашего ИИ персонажа, вы учите его распознавать и воспроизводить их.',
    'landing.features.featureOne.action': 'Попробовать сейчас',
    'landing.testimonials.title': 'Отзывы клиентов',
    'landing.testimonials.description': 'Экономьте деньги и время',
    'landing.grid.title': 'Люди создают прямо сейчас',
    'landing.blog.title': 'Блог',
    'landing.grid.action': 'Начать бесплатно',
    'landing.trytoday.title': 'Попробуйте Snaptap сегодня',
    'landing.trytoday.description': 'Взорвите Instagram 🚀',
    'landing.trytoday.action': 'Начать бесплатно',
    'landing.trytoday.noCard': 'Кредитная карта не требуется',
    'landing.trytoday.openDash': 'Открыть дашборд',
    'landing.faq.title': 'Часто задаваемые вопросы (FAQ)',
    'landing.faq.description': 'Найдите ответы на свои вопросы о использовании Snaptap.',
    'landing.faq.q1.question': 'Имеет ли Midjourney API?',
    'landing.faq.q1.answer':
      'Midjourney не имеет официального API, но Snaptap позволит вам работать с данными Midjourney программным способом для любых ваших творческих нужд.',

    'landing.prices.title': 'Цены',
    'landing.prices.description': 'Раскройте мощь API с PRO аккаунтом.',

    'landing.navigation.api': 'Как это работает',
    'landing.navigation.prices': 'Цены',
    'landing.navigation.faq': 'FAQ',
    'landing.navigation.subscribe': 'Подписаться',
    'landing.navigation.dashboard': 'Дашборд',

    'login.description':
      'Пароль не требуется.{br}Вам нужно получить новый одноразовый код каждый раз при входе в систему.',
    'login.continue': 'Продолжить',
    'login.verification.title': 'Проверка кода',
    'login.verification.resendText': 'Отправить код повторно через {seconds} секунд',
    'login.verification.resendAction': 'Отправить код повторно',
    'login.verification.description':
      'Мы только что отправили вам одноразовый код для входа. Пожалуйста, проверьте ваш почтовый ящик или спам. Может занять несколько минут. Код действителен в течение 5 минут.',

    'navigation.top.dashboard': 'Дашборд',
    'navigation.top.favorites': 'Избранное',
    'navigation.top.explore': 'Explore',
    'navigation.top.prices': 'Цены и тарифы',

    'notification.auth.logout.success': 'Вы вышли из системы.',
    'notification.auth.logout.fail': 'Не удалось выйти из системы',
    'notification.snap.genStarted': 'Генерация начата',
    'notification.code.success': 'Код отправлен',
    'notification.code.fail': 'Ошибка отправки кода',

    'notification.model.delete.success': 'Модель удалена.',
    'notification.model.delete.fail': 'Не удалось удалить модель',
    'notification.model.training.started': 'Обучение начато.',
    'notification.model.training.failed': 'Не удалось начать обучение',

    'notification.common.textCopy': 'Скопировано',
    'notification.favorites.added': 'Добавлено в избранное',
    'notification.favorites.removed': 'Удалено из избранного',
    'notification.favorites.error': 'Ошибка обновления избранного',

    'favorites.tooltip.remove': 'Удалить из избранного',
    'favorites.tooltip.add': 'Добавить в избранное',

    'status.widget.current': 'Текущий ',
    'status.widget.queue': 'В очереди ',

    'pricesNew.title': 'Планы и цены',
    'pricesNew.freePlan.title': '$0/месяц',
    'pricesNew.freePlan.description':
      'Бесплатно. Навсегда. Зарегистрируйтесь и начните создавать отличные фотосессии. Вы можете перейти на более качественный и улучшенный план позже.',

    'pricesNew.signUp': 'Зарегистрироваться бесплатно',
    'pricesNew.paidPlan.header': 'Платные планы',
    'pricesNew.paidPlan.title': 'Настроены серьезно? {br}Подписка улучшит качество и скорость.',

    'pricesNew.paidPlan.join': 'Присоединяйтесь к 120+ другим',

    'pricesNew.paidPlan.price': '$19/месяц',
    'pricesNew.paidPlan.cancel': 'Отмена в любое время',
    'pricesNew.paidPlan.current': 'Ваш текущий план',
    'pricesNew.paidPlan.getStarted': 'Начать бесплатно',
    'pricesNew.paidPlan.manage': 'Управлять вашим планом',

    'pricesNew.paidPlan.start': 'Начать',
    'pricesNew.paidPlan.start.description': 'Все, что входит в бесплатный план',

    'pricesNew.asyougo.title': 'Оплата по факту',
    'pricesNew.asyougo.description': 'Платите только за использование',

    'modelList.title': 'Мои персонажи',
    'modelList.select': 'Выберите персонажа',
    'modelList.statuses': 'О статусах',
    'modelList.tooltip':
      'Created – Готов к обучению.{br}Pending – В очереди.{br}Processing – Обучение модели.{br}Ready – Начать генерацию фотографий.',

    'modelList.add.title': 'Создать персонажа',
    'modelList.add.upload': 'Загрузите ваши фотографии',
    'modelList.add.onboarding': `Чтобы начать создание фотографий вам нужно обучить загруженную модель. Обычно это занимает около 30 минут. Выберите доступную модель и нажмите "обучить".`,

    'photo.actions.copy': 'Скопировать промпт',
    'photo.actions.upscale': 'Upscale фото',
    'photo.actions.upscale.download': 'Скачать 4k',
    'photo.actions.nsfw': 'Toggle NSFW',

    'creationForm.available': 'Доступные действия',
    'creationForm.trains': 'Осталось тренировок',
    'creationForm.actions.train': 'Обучить модель',
    'creationForm.actions.buyTrains': 'Купить больше тренировок',
    'creationForm.actions.retrain': 'Переобучить модель',
    'creationForm.actions.remove': 'Удалить',
    'creationForm.actions.title': 'Форма создания',
    'creationForm.actions.prompt': 'Ваш запрос',
    'creationForm.actions.settings': 'Расширенные настройки',
    'creationForm.actions.settingsPRO': 'Расширенные настройки (необходим платный аккаунт)',
    'creationForm.actions.character': 'Вес персонажа',
    'creationForm.actions.character.tooltip':
      'Насколько точно вы хотите, чтобы сгенерированное фото соответствовало определенному персонажу.',
    'creationForm.actions.negative': 'Негативный запрос',
    'creationForm.actions.negative.tooltip':
      'Слова или фразы, которых ИИ следует избегать. Используйте это, чтобы избежать нежелательных элементов на вашей фотографии.',
    'creationForm.actions.seed': 'Seed',
    'creationForm.actions.seed.tooltip':
      'Уникальная отправная точка для ИИ для генерации вашего фото. Считайте это первоначальным вдохновением для произведения искусства',

    'creationForm.actions.settings.publicity.title': 'Публичные фото',
    'creationForm.actions.settings.publicity.tooltip':
      'Ваши фотографии, которые будут созданы, будут доступны публично: на главной странице веб-сайта и на странице "Исследовать". В будущем, когда вы подпишетесь, вы сможете отключить публичный показ ваших фотографий.',

    'creationForm.actions.settings.weight.title': 'Вес персонажа',
    'creationForm.actions.settings.camera.title': 'Настройки камеры',
    'creationForm.actions.settings.camera.off': 'настройки отключены',
    'creationForm.actions.settings.strictness.title': 'Строгость запроса',
    'creationForm.actions.settings.strictness.tooltip':
      'Чем меньше, тем творческий подход; чем больше, тем ближе к запросу.',
    'creationForm.actions.settings.hq.title': 'Высокое качество',
    'creationForm.actions.settings.hq.tooltip':
      'Производит фотографии высокого разрешения с большим количеством деталей, но менее стабильно. Делает два снимка за генерацию и работает медленнее.',
    'creationForm.actions.settings.enhancement.title': 'Улучшение лица',
    'creationForm.actions.settings.enhancement.tooltip':
      'Если включено, будет применена отдельная настройка для улучшения деталей лица.',

    'creationForm.actions.presets': 'Пресеты',
    'creationForm.actions.generate': 'Сгенерировать фото',
    'creationForm.actions.snapsLeft': 'осталось snap',

    'footer.sub.title': 'Не пропустите обновления:',
    'footer.sub.action': 'Подписаться',
    'footer.sub.contact': 'Контакт:',

    'status.widget.title': 'Статус',
    'status.widget.inactive': 'Нет активных процессов',

    'header.signin': 'Войти',
    'header.signup': 'Начать',

    'navigation.mobile.billing': 'Оплата',
    'navigation.mobile.explore': 'Explore',
    'navigation.mobile.create': 'Создать',
    'navigation.mobile.favorites': 'Избранное',
    'navigation.mobile.snaps': 'Snaps',

    'addModel.create.title': 'Добавить новую модель',
    'addModel.create.title.welcome': 'Welcome to Snaptap!',
    'addModel.create.title.welcome.description': `Перед тем как начать создавать фотографии, похожие на вас, давайте создадим вашего первого AI персонажа. Просто дайте ему любое имя.`,
    'addModel.create.input.name': 'Имя',
    'addModel.create.input.description': 'Дайте имя вашей модели',
    'addModel.create.button.continue': 'Продолжить',

    'pricesNew.asyougo.input.label': 'Сумма',
    'pricesNew.asyougo.input.action': 'Купить',
    'pricesNew.asyougo.input.description': 'Минимальный платеж составляет $10. Шаги по $10.',

    'faq.section.q1': 'Будут ли на фотографиях артефакты ИИ?',
    'faq.section.a1':
      'Иногда да. В зависимости от качества загруженных фотографий и типа снимков, которые вы делаете, вы можете заметить некоторые артефакты ИИ. Любое приложение, использующее генеративное изображение ИИ, будет иметь артефакты. Это могут быть нелогичные вещи, такие как лишние руки, деформированные лица и т.д.',

    'faq.section.q2': 'Сколько времени займет создание моего персонажа ИИ?',
    'faq.section.a2': 'Исходя из текущего времени обработки, создание персонажа ИИ занимает около 30 минут.',

    'faq.section.q3': 'Сколько времени займет создание фотографии с ИИ?',
    'faq.section.a3':
      'Исходя из текущего времени обработки, создание одной фотографии занимает около 40 секунд. Вы можете делать до 4 фотографий параллельно.',

    'faq.section.q4': 'Что вы будете делать с моими фотографиями персонажа ИИ?',
    'faq.section.a4':
      'Мы используем ваши фотографии только для создания персонажа ИИ. Конфиденциальность и безопасность ваших данных - наши главные приоритеты.',

    'faq.section.q5': 'Могу ли я использовать Snaptap для примерки одежды и причесок?',
    'faq.section.a5': 'Вы можете пробовать различные наряды и прически, описывая запрос.',

    'faq.section.q6': 'Насколько фотографии будут похожи на меня?',
    'faq.section.a6':
      'Сходство фотографий с вами зависит от качества и разнообразия загружаемых вами фотографий. Чем лучше и разнообразнее ваши фотографии, тем точнее ИИ сможет понять и воспроизвести ваши уникальные черты. Мы каждый день работаем над улучшением наших моделей.',

    'faq.section.q7': 'Как мне отменить мою подписку?',
    'faq.section.a7':
      'Вы можете отменить свою подписку в любое время. Перейдите на страницу Цены и Планы, когда вы войдете в систему, и нажмите Управление моим планом, после чего вы будете перенаправлены в платежный портал Stripe, где сможете скачать счета-фактуры, изменить планы и отменить подписку.',

    'faq.section.q8': 'Какие фотографии мне следует загружать для разработки персонажа ИИ?',
    'faq.section.a8':
      'Рекомендуется выбирать изображения, на которых показан широкий спектр сцен, включая как крупные портреты, так и фотографии в полный рост, сделанные в разных условиях, с разных ракурсов, в разнообразной одежде и с различными выражениями лица. Избегайте выбора повторяющихся изображений, фотографий с группами людей, изображений с другими людьми или аксессуарами, такими как солнцезащитные очки и шляпы. Также стоит избегать фотографий, на которых ваше лицо закрыто или плохо видно.',

    'addModel.upload.title': 'Загрузка фотографий',
    'addModel.upload.description':
      'Загрузите коллекцию изображений хорошего качества, чтобы начать работу с моделью ИИ.',
    'addModel.upload.expected': 'Ожидаемые фотографии:',
    'addModel.upload.final': 'Ваши фотографии:',
    'addModel.upload.button.upload': 'Продолжить',

    'addModel.review.title': 'Теперь только вперед!',
    'addModel.review.description': 'Создание модели займет около 30 минут. Вы можете заварить чай пока ждете.',
    'addModel.review.button.complete': 'Завершить',

    'pricesNew.subscribe.button': 'Начать за $19.90/мес',
    'pricesNew.subscribe.feature1': 'Функция избранного',
    'pricesNew.subscribe.feature2': '3 персонажа ИИ',
    'pricesNew.subscribe.feature3': 'Скорость 2х (в будущем)',
    'pricesNew.subscribe.feature4': 'Расширенные фильтры',
    'pricesNew.subscribe.feature5': 'Расширенные настройки',
    'pricesNew.subscribe.feature6': 'Бесконечная история',
    'pricesNew.subscribe.feature7': '+10% к пополнению баланса',
    'pricesNew.subscribe.feature8': 'Параллельное генерация',
    'pricesNew.subscribe.feature9': 'Скрытый режим (в будущем)',
    'pricesNew.subscribe.feature10': 'HQ качество',

    'explore.action': 'Explore more',

    'languages.english': 'English',
    'languages.russian': 'Русский',
    'languages.thai': 'ภาษาไทย',

    'common.loadMore': 'Load more',
    'common.copyNegative': 'Copy negative',

    'server.error.notAuthorized': 'Вы не авторизованы.',
    'server.error.unhandledMessage': 'Неизвестная ошибка.',
    'server.error.AuthMissing': 'Неверный код',
    'server.error.Ready': 'Персонаж еще не готов',
    'server.error.InsufficientBalance': 'Недостаточно средств',
  },
  'th-TH': {
    'landing.hero.title': '<span>เปิดประสบการณ์รูปแบบใหม่บนInstagramของคุณ</span> {br}ด้วยภาพ AI',
    'landing.hero.subtitle':
      'คุณสามรถสร้างภาพถ่ายมืออาชีพโดยไม่ต้องออกจากบ้านและทำให้เพื่อนของคุณประหลาดใจและดึงดูดผู้ติดตามใหม่',
    'landing.hero.action': 'เริ่มต้นใช้งานฟรี',
    'landing.hero.noCredit': 'ไม่ต้องใช้บัตรเครดิต',
    'landing.hero.dashboard': 'เปิดแดชบอร์ด',
    'landing.howitworks.title': 'วิธีการทำงาน',
    'landing.howitworks.description': 'เริ่มสร้างภาพถ่ายที่สมบูรณ์แบบในเวลาเพียง 30 นาที',
    'landing.features.title': 'วิธีการทำงาน',
    'landing.features.description': 'เริ่มสร้างภาพถ่ายที่สมบูรณ์แบบในเวลาเพียง 30 นาที',
    'landing.features.featureOne.title': 'สร้างตัวละคร AI ของคุณเอง',
    'landing.features.featureOne.description':
      'สร้างภาพถ่ายด้วยปัญญาประดิษฐ์โดยการฝึกตัวละคร AI ของคุณเอง อัปโหลดภาพถ่ายอย่างน้อย 5 ภาพหรือมากกว่านั้น ซึ่งภาพเหล่านั้น ควรมีความหลากหลายและแตกต่างกันออกไป โดยการใส่ภาพเหล่านี้จะทำให้ภาพตัวละคร AI ของคุณได้เรียนรู้และจดจำใบหน้าของคุณและทำตัวละครAI ของคุณออกมาได้อย่างสมบูรณ์แบบที่สุด',
    'landing.features.featureOne.action': 'ลองตอนนี้',
    'landing.testimonials.title': 'ที่ลูกค้ารัก',
    'landing.testimonials.description': 'ประหยัดเงินและเวลา',
    'landing.grid.title': 'ผู้คนที่กำลังสร้างตอนนี้',
    'landing.blog.title': 'บล็อก',
    'landing.grid.action': 'เริ่มใช้งานฟรี',
    'landing.trytoday.title': 'ลอง Snaptap วันนี้',
    'landing.trytoday.description': 'เปลี่ยนรูปภาพบน Instagram ของคุณให้น่าสนใจและดึงดูดมากขึ้น 🚀',
    'landing.trytoday.action': 'เริ่มใช้งานฟรี',
    'landing.trytoday.noCard': 'ไม่ต้องใช้บัตรเครดิต',
    'landing.trytoday.openDash': 'เปิดแดชบอร์ด',
    'landing.faq.title': 'คำถามที่พบบ่อย (FAQ)',
    'landing.faq.description': 'ค้นหาคำตอบสำหรับคำถามเกี่ยวกับการใช้งาน Snaptap',
    'landing.faq.q1.question': 'Midjourney มี API หรือไม่?',
    'landing.faq.q1.answer':
      'Midjourney ไม่มี API อย่างเป็นทางการ แต่ Snaptap จะช่วยให้คุณสามารถทำงานกับข้อมูล Midjourney ได้ในลักษณะโปรแกรมเมติกสำหรับความต้องการทางสร้างสรรค์ของคุณ',

    'landing.prices.title': 'ราคา',
    'landing.prices.description': 'ปลดปล่อยพลังของ API ด้วยบัญชี PRO',

    'landing.navigation.api': 'วิธีการทำงาน',
    'landing.navigation.prices': 'ราคา',
    'landing.navigation.faq': 'FAQ',
    'landing.navigation.subscribe': 'สมัครสมาชิก',
    'landing.navigation.dashboard': 'แดชบอร์ด',

    'login.description': 'ไม่ต้องใช้รหัสผ่าน{br}คุณต้องรับรหัสใหม่ทุกครั้งที่ล็อกอิน',
    'login.continue': 'ดำเนินการต่อ',
    'login.verification.title': 'การตรวจสอบรหัส',
    'login.verification.resendText': 'ส่งรหัสอีกครั้งใน {seconds} วินาที',
    'login.verification.resendAction': 'ส่งรหัสอีกครั้ง',
    'login.verification.description':
      'เราเพิ่งส่งรหัสเข้าสู่ระบบหนึ่งครั้งให้คุณ โปรดตรวจสอบกล่องจดหมายหรือสแปมของคุณ อาจใช้เวลาสักครู่ รหัสหมดอายุใน 5 นาที',

    'navigation.top.dashboard': 'แดชบอร์ด',
    'navigation.top.favorites': 'รายการโปรด',
    'navigation.top.explore': 'สำรวจ',
    'navigation.top.prices': 'ราคาและแผน',

    'notification.auth.logout.success': 'คุณได้ออกจากระบบแล้ว',
    'notification.auth.logout.fail': 'ไม่สามารถออกจากระบบได้',
    'notification.snap.genStarted': 'เริ่มการสร้าง',
    'notification.code.success': 'ส่งรหัส',
    'notification.code.fail': 'ข้อผิดพลาดในการส่งรหัส',

    'notification.model.delete.success': 'ลบโมเดลแล้ว',
    'notification.model.delete.fail': 'ไม่สามารถลบโมเดลได้',
    'notification.model.training.started': 'เริ่มการฝึก',
    'notification.model.training.failed': 'ไม่สามารถเริ่มการฝึกได้',
    'notification.model.prompt.empty': 'โปรดกรอกข้อมูลในช่องโพรมต์',
    'notification.model.prompt.overflow': '160 คำเป็นสูงสุดสำหรับโพรมต์',
    'notification.model.negative.overflow': '40 คำเป็นสูงสุดสำหรับ negative',

    'notification.common.textCopy': 'คัดลอกไปยังคลิปบอร์ด',
    'notification.favorites.added': 'เพิ่มในรายการโปรด',
    'notification.favorites.removed': 'ลบออกจากรายการโปรด',
    'notification.favorites.error': 'ข้อผิดพลาดในการอัปเดตรายการโปรด',

    'favorites.tooltip.remove': 'ลบออกจากรายการโปรด',
    'favorites.tooltip.add': 'เพิ่มในรายการโปรด',

    'pricesNew.title': 'แผนและราคา',
    'pricesNew.freePlan.title': '$0/เดือน',
    'pricesNew.freePlan.description':
      'ฟรี ตลอดไป ลงทะเบียนและเริ่มสร้างการถ่ายภาพที่ยอดเยี่ยม เลือกที่จะอัปเกรดในภายหลังเพื่อคุณภาพและประสบการณ์ที่ดีขึ้น',

    'pricesNew.signUp': 'ลงทะเบียนฟรี',
    'pricesNew.paidPlan.header': 'แผนการชำระเงิน',
    'pricesNew.paidPlan.title':
      'คุณจริงจังเกี่ยวกับการสร้างภาพหรือไม่? {br}ลองแผนการชำระเงินเพื่อเพิ่มความเร็วและประสบการณ์',

    'pricesNew.paidPlan.join': 'เข้าร่วมกับ 20+ คนอื่น ๆ',

    'navigation.mobile.billing': 'การเรียกเก็บเงิน',
    'navigation.mobile.explore': 'สำรวจ',
    'navigation.mobile.create': 'สร้าง',
    'navigation.mobile.favorites': 'รายการโปรด',
    'navigation.mobile.snaps': 'snaps',

    'pricesNew.paidPlan.price': '$19.90/เดือน',
    'pricesNew.paidPlan.cancel': 'ยกเลิกได้ทุกเมื่อ',
    'pricesNew.paidPlan.current': 'แผนปัจจุบันของคุณ',
    'pricesNew.paidPlan.getStarted': 'เริ่มใช้งานฟรี',
    'pricesNew.paidPlan.manage': 'จัดการแผนของคุณ',

    'pricesNew.paidPlan.start': 'เริ่ม',
    'pricesNew.paidPlan.start.description': 'ทุกอย่างในแผนฟรี',

    'pricesNew.asyougo.title': 'จ่ายตามที่คุณใช่งาน',
    'pricesNew.asyougo.description': 'จ่ายเฉพาะเมื่อคุณต้องการ',

    'modelList.title': 'ตัวละครของฉัน',
    'modelList.select': 'เลือกตัวละครเพื่อเริ่ม',
    'modelList.statuses': 'เกี่ยวกับสถานะ',
    'modelList.tooltip':
      'Created – พร้อมสำหรับการฝึก{br}Pending – อยู่ในคิว{br}Processing – การฝึกโมเดล{br}Ready – เริ่มการสร้างภาพ',

    'modelList.add.title': 'เพิ่มตัวละคร',
    'modelList.add.upload': 'อัปโหลดภาพถ่ายของคุณ',
    'modelList.add.onboarding': `เพื่อเริ่มการสร้างภาพถ่าย คุณต้องฝึกโมเดลที่อัปโหลด โดยปกติจะใช้เวลาประมาณ 30 นาที เลือกโมเดลที่ใช้ได้แล้วคลิก "ฝึก".`,

    'creationForm.available': 'การกระทำที่มีอยู่',
    'creationForm.trains': 'การฝึกที่เหลืออยู่',
    'creationForm.actions.train': 'ฝึกโมเดล',
    'creationForm.actions.buyTrains': 'ซื้อการฝึกเพิ่มเติม',
    'creationForm.actions.retrain': 'ฝึกโมเดลใหม่',
    'creationForm.actions.remove': 'ลบ',
    'creationForm.actions.title': 'แบบฟอร์มการสร้าง',
    'creationForm.actions.prompt': 'ภาพถ่ายของ {name}',
    'creationForm.actions.settings': 'การตั้งค่าขั้นสูง',
    'creationForm.actions.settings.weight.title': 'น้ำหนักตัวละคร',
    'creationForm.actions.settings.camera.title': 'การตั้งค่ากล้อง',
    'creationForm.actions.settings.camera.off': 'ปิดการตั้งค่า',
    'creationForm.actions.settings.strictness.title': 'ความเข้มงวดของพรอมต์',
    'creationForm.actions.settings.strictness.tooltip':
      'น้อยหมายถึงมีความคิดสร้างสรรค์มากขึ้น; มากหมายถึงใกล้เคียงกับพรอมต์มากขึ้น',
    'creationForm.actions.settingsPRO': 'การตั้งค่าขั้นสูง (ต้องการบัญชีที่ชำระเงิน)',
    'creationForm.actions.character': 'น้ำหนักตัวละคร',
    'creationForm.actions.character.tooltip': 'คุณต้องการให้ภาพถ่ายที่สร้างขึ้นตรงกับตัวละครที่ระบุมากน้อยเพียงใด',
    'creationForm.actions.negative': 'พรอมต์ที่ไม่ต้องการ',
    'creationForm.actions.negative.tooltip':
      'คำหรือวลีที่คุณบอก AI ให้หลีกเลี่ยง ใช้สิ่งนี้เพื่อหลีกเลี่ยงองค์ประกอบที่ไม่ต้องการในภาพถ่ายของคุณ',
    'creationForm.actions.seed': 'Seed',
    'creationForm.actions.seed.tooltip':
      'จุดเริ่มต้นที่ไม่ซ้ำใครสำหรับ AI ในการสร้างภาพถ่ายของคุณ คิดว่าเป็นแรงบันดาลใจเริ่มต้นสำหรับผลงานศิลปะ',
    'creationForm.actions.presets': 'พรีเซ็ต',
    'creationForm.actions.generate': 'สร้างภาพถ่าย',
    'creationForm.actions.buyPhotos': 'ซื้อภาพถ่าย',
    'creationForm.actions.concurrency': 'จำนวนคำขอที่ทำพร้อมกันสูงสุดในแผนของคุณคือ',
    'creationForm.actions.snapsLeft': 'ภาพถ่ายที่เหลือ:',
    'footer.sub.title': 'ไม่พลาดการอัปเดต:',
    'footer.sub.action': 'สมัครรับข้อมูล',
    'footer.sub.contact': 'ติดต่อ:',
    'header.signin': 'เข้าสู่ระบบ',
    'header.signup': 'เริ่มต้น',
    'addModel.create.title': 'เพิ่มโมเดลใหม่',
    'addModel.create.input.name': 'ชื่อ',
    'addModel.create.input.description': 'ให้ชื่อโมเดลของคุณ',
    'addModel.create.title.welcome': 'Welcome to Snaptap!',
    'addModel.create.title.welcome.description': `Before starting to create photos that look like you, let's create your first AI character. Simply give it any name.`,
    'addModel.create.button.continue': 'ดำเนินการต่อ',
    'addModel.upload.title': 'อัปโหลดภาพถ่าย',
    'addModel.upload.description': 'อัปโหลดคอลเลกชันของภาพที่มีคุณภาพดีเพื่อเริ่มทำงานกับโมเดล AI',
    'addModel.upload.expected': 'ภาพถ่ายที่คาดหวัง:',
    'addModel.upload.final': 'ภาพถ่ายของคุณ:',
    'addModel.upload.button.upload': 'ดำเนินการต่อ',
    'addModel.review.title': 'กำลังดำเนินการ',
    'addModel.review.description': 'การสร้างโมเดลจะใช้เวลาประมาณ 30 นาที {br}คุณจะได้รับอีเมลเมื่อเสร็จสิ้น',
    'addModel.review.button.complete': 'เสร็จสิ้น',
    'emptyStates.noPhotos.title': 'ยังไม่มีภาพถ่าย',
    'emptyStates.noPhotos.description': 'สร้างบางอย่างและมันจะปรากฏที่นี่',
    'upgrade.button': 'อัปเกรด!',
    'upgrade.button.description': 'เพิ่มประสิทธิภาพ',

    'favorites.page.title': 'รายการโปรด',
    'favorites.page.availability': 'ฟีเจอร์นี้มีให้บริการสำหรับสมาชิกเท่านั้น',
    'favorites.page.availability.upgrade': 'อัพเกรด',
    'favorites.page.empty.title': 'เพิ่มภาพถ่ายลงในรายการโปรดและภาพถ่ายของคุณจะจัดเก็บไว้ที่นี้',
    'favorites.page.empty.subtitle': 'คุณไม่ได้เพิ่มภาพถ่ายในรายการโปรด',
    'photo.actions.copy': 'คัดลอกคำสั่ง',
    'photo.actions.upscale': 'Upscale photo',
    'photo.actions.upscale.download': 'Download 4k',
    'photo.actions.nsfw': 'Toggle NSFW',
    'photo.actions.favorite.unavailable': 'ต้องการสมาชิก',
    'status.widget.title': 'สถานะ',
    'status.widget.inactive': 'ไม่มีกระบวนการทำงาน',
    'status.widget.current': 'ปัจจุบัน ',
    'status.widget.queue': 'ในคิว',
    'pricesNew.asyougo.input.label': 'จำนวน',
    'pricesNew.asyougo.input.action': 'ซื้อ',
    'pricesNew.asyougo.input.description': 'การชำระเงินขั้นต่ำเริ่มต้นที่ $10,$20,$30',
    'pricesNew.subscribe.button': 'เริ่มต้นใช้งานในราคา $19.90/เดือน',
    'pricesNew.subscribe.feature1': 'ฟีเจอร์รายการโปรด',
    'pricesNew.subscribe.feature2': '3 ตัวละคร AI',
    'pricesNew.subscribe.feature3': 'การสร้างเร็วขึ้น 2 เท่า (เร็วๆ นี้)',
    'pricesNew.subscribe.feature4': 'ตัวกรองขั้นสูง',
    'pricesNew.subscribe.feature5': 'การตั้งค่าขั้นสูง',
    'pricesNew.subscribe.feature6': 'ประวัติการใช้งานไม่หมดอายุ',
    'pricesNew.subscribe.feature7': 'รับโบนัส 10% สำหรับการเติมสแนป',
    'pricesNew.subscribe.feature8': 'การสร้างสแนปแบบขนาน',
    'pricesNew.subscribe.feature9': 'โหมดซ่อนตัว (เร็วๆ นี้)',
    'pricesNew.subscribe.feature10': 'HQ Quality',
    'modelList.add.upgrade.title': 'อัพเกรดบัญชี',
    'modelList.add.upgrade.description': 'จำนวนตัวละครสูงสุดสำหรับแผนของคุณคือ ',
    'creationForm.actions.settings.hq.title': 'HQ',
    'creationForm.actions.settings.hq.tooltip':
      'ผลิตภาพที่มีความละเอียดสูงขึ้นพร้อมรายละเอียดเพิ่มเติม แต่มีความเสถียรน้อยกว่า ใช้เวลาสองรอบในการสร้างต่อหนึ่งครั้งและทำงานช้าลง',
    'creationForm.actions.settings.enhancement.title': 'การเสริมความสวยงามของใบหน้า',
    'creationForm.actions.settings.enhancement.tooltip':
      'หากเปิดใช้งาน การตั้งค่าแยกต่างหากจะถูกใช้เพื่อเสริมรายละเอียดของใบหน้า',
    'creationForm.actions.settings.publicity.title': 'ภาพถ่ายที่เปิดเผยต่อสาธารณะ',
    'creationForm.actions.settings.publicity.tooltip':
      'ภาพถ่ายของคุณที่จะถูกสร้างขึ้น จะเปิดเผยต่อสาธารณะ: บนหน้าแรกของเว็บไซต์และบนหน้า Explorer ในอนาคต เมื่อคุณสมัครสมาชิก คุณจะสามารถปิดการแสดงภาพถ่ายของคุณต่อสาธารณะได้',
    'faq.section.q1': 'ภาพถ่ายจะมีสิ่งประดิษฐ์ AI หรือไม่?',
    'faq.section.a1':
      'บางครั้งอาจจะมี ขึ้นอยู่กับคุณภาพของภาพถ่ายที่อัปโหลด และประเภทของภาพถ่ายที่คุณถ่าย คุณอาจเห็นสิ่งประดิษฐ์ AI บางอย่าง ทุกแอปที่ใช้การจัดภาพ AI สร้างสรรค์จะมีสิ่งประดิษฐ์ พวกมันสามารถเป็นสิ่งที่ไม่เป็นเหตุเป็นผล เช่น แขนเพิ่มเติม ใบหน้าที่บิดเบี้ยว ฯลฯ',
    'faq.section.q2': 'จะใช้เวลานานแค่ไหนในการสร้างตัวละคร AI ของฉัน?',
    'faq.section.a2': 'ตามเวลาการประมวลผลปัจจุบัน จะใช้เวลาประมาณ 30 นาทีในการสร้างตัวละคร AI',
    'faq.section.q3': 'จะใช้เวลานานแค่ไหนในการถ่ายภาพ AI?',
    'faq.section.a3':
      'ตามเวลาการประมวลผลปัจจุบัน จะใช้เวลาประมาณ 40 วินาทีในการถ่ายหนึ่งภาพ คุณสามารถถ่ายภาพพร้อมกันได้สูงสุด 4 ภาพ',
    'faq.section.q4': 'คุณจะทำอย่างไรกับภาพถ่ายตัวละคร AI ของฉัน?',
    'faq.section.a4':
      'เราใช้ภาพถ่ายของคุณเพื่อสร้างตัวละคร AI เท่านั้น ความเป็นส่วนตัวและความปลอดภัยของข้อมูลของคุณเป็นสิ่งสำคัญสูงสุดของเรา',
    'faq.section.q5': 'ฉันสามารถใช้ Snaptap เพื่อลองใส่เสื้อผ้าและทรงผมได้หรือไม่?',
    'faq.section.a5': 'คุณสามารถลองใส่ชุดและทรงผมต่างๆ โดยการอธิบายคำสั่ง',
    'faq.section.q6': 'ภาพถ่ายจะมีลักษณะคล้ายฉันมากน้อยเพียงใด?',
    'faq.section.a6':
      ' ความคล้ายคลึงของภาพถ่ายกับคุณขึ้นอยู่กับคุณภาพและความหลากหลายของภาพถ่ายที่คุณอัปโหลด ยิ่งภาพถ่ายของคุณมีคุณภาพและหลากหลายมากเท่าไหร่ AI ก็จะยิ่งเข้าใจและแสดงคุณลักษณะเฉพาะตัวของคุณได้อย่างแม่นยำยิ่งขึ้น เรายังคงปรับปรุงโมเดลของเราทุกวัน',
    'faq.section.q7': 'ฉันจะยกเลิกการสมัครสมาชิกได้อย่างไร?',
    'faq.section.a7':
      'คุณสามารถยกเลิกการสมัครสมาชิกได้ทุกเมื่อ ไปที่หน้าราคาและแผนเมื่อคุณเข้าสู่ระบบและคลิกจัดการแผนของฉัน คุณจะถูกนำไปยังพอร์ทัลการเรียกเก็บเงินของ Stripe ที่คุณสามารถดาวน์โหลดใบแจ้งหนี้ สลับแผน และยกเลิกการสมัครสมาชิกของคุณ',
    'faq.section.q8': 'ฉันควรอัปโหลดภาพถ่ายประเภทใดเพื่อพัฒนาตัวละคร AI?',
    'faq.section.a8':
      'ควรเลือกภาพที่แสดงถึงช่วงของฉากที่หลากหลาย รวมถึงทั้งภาพถ่ายใบหน้าแบบใกล้ชิดและภาพถ่ายทั้งตัว ถ่ายในสถานที่ต่างๆ จากมุมมองต่างๆ สวมชุดต่างๆ และแสดงสีหน้าที่หลากหลาย หลีกเลี่ยงการเลือกภาพถ่ายที่ซ้ำซาก มีการจัดกลุ่ม รวมถึงบุคคลอื่น หรือมีอุปกรณ์เสริมเช่นแว่นตากันแดดและหมวก นอกจากนี้ หลีกเลี่ยงภาพถ่ายที่ใบหน้าของคุณถูกบดบังหรือมองไม่เห็นอย่างชัดเจน',

    'explore.action': 'Explore more',

    'common.loadMore': 'Load more',
    'common.copyNegative': 'Copy negative',

    'languages.english': 'English',
    'languages.russian': 'Русский',
    'languages.thai': 'ภาษาไทย',
    'server.error.notAuthorized': 'คุณไม่ได้รับอนุญาต',
    'server.error.unhandledMessage': 'ข้อผิดพลาดที่ไม่รู้จัก',
    'server.error.AuthMissing': 'รหัสไม่ถูกต้อง',
    'server.error.Ready': 'ตัวละครยังไม่พร้อม',
    'server.error.InsufficientBalance': 'ยอดเงินไม่พอ',
    'server.error.concurrencyOverflow': 'คำขอพร้อมกันมากเกินไป',
  },
}
