import { useCallback, useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { useSnaps } from 'src/entities/snap/useGetSnaps'
import { NsfwCheck } from 'src/entities/snap/useNsfw.hook'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { pushNotification } from 'src/services/notifications/notificationService'
import { addResolutionToImageUrl, copyToClipboard } from 'src/utils/strings'
import styles from './index.module.scss'

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`
  }

  return text
}

type Props = {
  snapId?: string
  photo: any
  photoIndex?: number
  prompt: string
  showPrompt?: boolean
  downloadableUrl?: string
  onAddFav?: any
  onRemoveFav?: any
  isFav?: boolean
  allowedFeatures?: any
  info: Record<any, any>
}

export function PhotoItem({
  snapId,
  photo,
  prompt,
  showPrompt = false,
  downloadableUrl,
  onAddFav = null,
  info,
  photoIndex,
  allowedFeatures = {},
  onRemoveFav,
  isFav,
}: Props) {
  const isNsfwImage = NsfwCheck(info, photoIndex)
  const [isPhotoShown, setIsPhotoShown] = useState(() => NsfwCheck(info, photoIndex))
  const { upscaleSnap } = useSnaps()
  // eslint-disable-next-line
  // @ts-ignore
  const isUpscaled = info && info[photoIndex]?.upscale === 'ready'
  const isUpscaling =
    // eslint-disable-next-line
    // @ts-ignore
    (info && info[photoIndex]?.upscale === 'processing') || (info && info[photoIndex]?.upscale === 'pending')

  const { fireEvent } = useAnalytics()
  const onTextCopy = (text) => {
    fireEvent(ANALYTICS_EVENTS.ClickCopyPrompt)
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'notification.common.textCopy',
      }),
    )
  }

  const onUpscale = async () => {
    try {
      await upscaleSnap.mutateAsync({ id: snapId, resample: 1, index: photoIndex })
      pushNotification({
        type: 'success',
        title: 'Upscaling...',
      })
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'Error upscaling',
      })
    }
  }

  const toggleNsfwStatus = () => {
    setIsPhotoShown((prevState) => !prevState)
  }

  const handleFavorites = useCallback(async () => {
    fireEvent(ANALYTICS_EVENTS.ClickFavorite)

    try {
      if (isFav) {
        await onRemoveFav.mutateAsync({
          id: snapId,
          photo: photoIndex,
        })
        pushNotification({
          type: 'success',
          title: 'notification.favorites.removed',
        })
      } else {
        await onAddFav.mutateAsync({
          id: snapId,
          photo: photoIndex,
        })
        pushNotification({
          type: 'success',
          title: 'notification.favorites.added',
        })
      }
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'notification.favorites.error',
      })
    }
  }, [isFav])

  return (
    <div className={styles.listItem}>
      {/* <object type="image/webp" data={photo}>
        <div className={styles.fallbackBlock}>Processing image..</div>
      </object> */}

      <img className={isNsfwImage && isPhotoShown ? styles.blurred : ''} src={photo} alt={prompt} loading="lazy" />

      {isNsfwImage && (
        <div className={styles.nsfw}>
          <Button
            label="18+"
            icon={!isPhotoShown ? IconNames.eyeShow : IconNames.eyeHide}
            type="button"
            tooltipOnLeft
            tooltip="photo.actions.nsfw"
            iconColor="#fff"
            variant={ButtonVariants.unstyled}
            onClick={toggleNsfwStatus}
          />
        </div>
      )}

      <div className={styles.floatBox}>
        {allowedFeatures.canFavor && onAddFav && (
          <div className={styles.fav}>
            <Button
              label=""
              icon={isFav ? IconNames.starFilled : IconNames.star}
              type="button"
              isLoading={false}
              tooltip={isFav ? 'favorites.tooltip.remove' : 'favorites.tooltip.add'}
              variant={ButtonVariants.unstyled}
              onClick={handleFavorites}
            />
          </div>
        )}
        {!allowedFeatures.canFavor && onAddFav && (
          <div className={styles.fav}>
            <Button
              label=""
              icon={isFav ? IconNames.starFilled : IconNames.star}
              type="button"
              isLoading={false}
              tooltip="photo.actions.favorite.unavailable"
              variant={ButtonVariants.unstyled}
              // onClick={handleFavorites}
            />
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a
          title="download"
          rel="noreferrer"
          href={downloadableUrl}
          download
          target="_blank"
          className={styles.download}
        >
          <Icon name={IconNames.download} />
        </a>

        <div className={styles.copy}>
          <Button
            label=""
            icon={IconNames.copy}
            type="button"
            tooltipOnLeft
            tooltip="photo.actions.copy"
            iconColor="#fff"
            variant={ButtonVariants.unstyled}
            onClick={() => onTextCopy(prompt)}
          />
        </div>
        {!isUpscaled && onAddFav && (
          <div className={styles.upscale}>
            {!isUpscaling && (
              <Button
                label=""
                icon={IconNames.upscale}
                type="button"
                tooltipOnLeft
                isLoading={!!isUpscaling}
                disabled={upscaleSnap.isLoading}
                tooltip="photo.actions.upscale"
                iconColor="#fff"
                variant={ButtonVariants.unstyled}
                onClick={onUpscale}
              />
            )}
            {!!isUpscaling && <Loader />}
          </div>
        )}
        {isUpscaled && (
          <div className={styles.upscale}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              title="download"
              rel="noreferrer"
              href={addResolutionToImageUrl(downloadableUrl)}
              download
              target="_blank"
            >
              <Button
                // icon={IconNames.fourk}
                type="button"
                tooltipOnLeft
                label="4k"
                disabled={upscaleSnap.isLoading}
                tooltip="photo.actions.upscale.download"
                iconColor="var(--color-primary)"
                variant={ButtonVariants.unstyled}
              />
            </a>
          </div>
        )}
        {/* {item?.model && <span className={styles.model}>{item?.model}</span>} */}
        {showPrompt && <p className={styles.inputText}>{truncateText(prompt, 170)}</p>}
        {/* <div className={styles.date}>

          {formatDate(Number(item?.created_at) as Date, DateFormats.datePickerFullDate)}
        </div> */}
      </div>
    </div>
  )
}
