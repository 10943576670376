import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const DEFAULT_NEGATIVE =
  'nsfw, ugly, low quality, watermark, logo, text, bad anatomy, disfigured, deformed iris, fused fingers, blurred'

export interface SettingsType {
  likeness: string | number
  strictness: string | number
  seed: any
  negative: null | string
  camera: string
  face_fix: boolean
  hq: boolean
  face_swap: boolean
  sampler: null | string
  updateSetingsStore: (key: any, val: any) => void
}

export const useSettingsStore = create<SettingsType>()(
  persist(
    (set) => ({
      likeness: 0.8,
      seed: null,
      camera: 'Off',
      negative: DEFAULT_NEGATIVE,
      strictness: 0.5,
      face_fix: false,
      hq: false,
      face_swap: false,
      sampler: null,

      updateSetingsStore: (key: any, val: any) => {
        set({ [key]: val })
      },
    }),
    {
      name: 'SettingsStore',
    },
  ),
)
