import { MetaTitle } from 'src/components/MetaTitle'
import { DashboardLayout } from 'src/layouts/DashboardLayout'
import { CreationForm } from 'src/widgets/CreationForm/CreationForm.widget'
import styles from './index.module.scss'

export function CreateScreen() {
  return (
    <DashboardLayout>
      <MetaTitle title="Create new character" />
      <div className={styles.wrap}>
        <div className={styles.form}>
          <CreationForm key="creation" />
        </div>
      </div>
    </DashboardLayout>
  )
}
