export const config = {
  baseUrl: process.env.REACT_APP_APP_ENV === 'production' ? 'https://api.snaptap.ai' : 'http://api.snaptap.zz:2080',
  siteName: 'Snaptap',
  turnstileSiteKey: '0x4AAAAAAADaAKssW_ZClFec',
  baseMailUrl: 'https://mailer.muvon.dev',
  projectKey: 'snaptap',
  site: {
    contactEmail: 'hello@snaptap.ai',
    name: 'Snaptap',
    socialLinks: {
      twitter: 'https://twitter.com/muvonteam',
      linkedin: 'https://www.linkedin.com/company/13463009',
      slack: 'https://join.slack.com/t/muvon-community/shared_invite/zt-2ciam1emd-WWN52E7ljMrlziD7N18Sug',
      instagram: 'https://ig.me/j/AbY3-7WXDB3jTLJf/',
    },
  },
  influencersFormLink:
    'https://docs.google.com/forms/d/e/1FAIpQLSfQo_6My3vOMN8q_eYR49MD6X3cOb9wsOBtLZ8yjjHu0dE11Q/viewform',
  features: {
    maxConcurrentRequests: 1,
    maxConcurrentRequestsPRO: 2,
  },
  settings: {
    minPhotoRequired: 5,
    maxPhotoRequired: 60,
    nsfwCutoff: 65,
  },
}
