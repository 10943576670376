import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ProgressSkeleton } from 'src/components/skeletons/ProgressSkeleton'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { useSnaps } from 'src/entities/snap/useGetSnaps'
import { useMedia } from 'src/hooks/useMedia'
import styles from './index.module.scss'

export function MobileNavigation() {
  const [refetchEnabled, setRefetchEnabled] = useState(false)
  const { data: snapPages } = useSnaps({ refetchEnabled })

  const snaps: any = snapPages?.pages?.flatMap((page) => page?.items ?? page)
  // eslint-disable-next-line
  // @ts-ignore
  const width = useMedia()
  const inAQueueQuery = snaps?.filter((snap) => snap.status === 'pending' || snap.status === 'created') || []
  const inProgressQuery = snaps?.filter((snap) => snap.status === 'processing') || []
  const inProgress = snaps?.find((snap) => snap.status === 'processing') || null

  const counter = inAQueueQuery?.length + inProgressQuery?.length
  useEffect(() => {
    if (snapPages && snaps) {
      const isRefetchEnabled = snaps.find((snap) => snap.status !== 'ready')
      setRefetchEnabled(isRefetchEnabled) // Be careful to avoid infinite loops here
    }
  }, [snaps])

  if (width > 768) return null

  return (
    <nav className={styles.wrapper}>
      {inProgress && (
        <div className={styles.current}>
          <ProgressSkeleton>
            <span style={{ width: `${inProgress?.progress}%` }} className={styles.line} />
          </ProgressSkeleton>
        </div>
      )}
      <ul className={styles.nav}>
        <li className={styles.home}>
          {counter > 0 && <div className={styles.progressCount}>{counter}</div>}
          <NavLink className={styles.navLink} to="/dashboard">
            <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.images} />
            <span>
              <FormattedText id="navigation.mobile.snaps" />
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink className={styles.navLink} to="/favorites">
            <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.star} />
            <span>
              <FormattedText id="navigation.mobile.favorites" />
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink className={styles.navLink} to="/create">
            <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.plus} />
            <span>
              <FormattedText id="navigation.mobile.create" />
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink className={styles.navLink} to="/explore">
            <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.search} />
            <span>
              <FormattedText id="navigation.mobile.explore" />
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink className={styles.navLink} to="/prices">
            <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.creditCard} />
            <span>
              <FormattedText id="navigation.mobile.billing" />
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}
