import { MetaTitle } from 'src/components/MetaTitle'
import { Container } from 'src/core/ds/container'
import { Icon, IconNames } from 'src/core/ds/icon'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import { Blog } from '../landing/parts/Blog'
import { Faq } from '../landing/parts/Faq'
import { Upsell } from '../landing/parts/Upsell'
import styles from './index.module.scss'

export function TeamScreen() {
  return (
    <AppLayout>
      <MetaTitle title="Team" />
      <Container>
        <div className={styles.content}>
          <div className={styles.hero}>
            <h1>Meet the Founders</h1>
          </div>

          <div className={styles.team}>
            <div className={styles.teamMember}>
              <img
                src="https://pbs.twimg.com/profile_images/1647989520703578112/UtgBAyGR_400x400.jpg"
                alt="Don Harman"
              />
              <div className={styles.teamMemberInfo}>
                <span>Don Hardman</span>
                <p>CEO & Co-founder</p>
                <div className={styles.links}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a href="https://twitter.com/donhardman88" title="twitter" target="_blank" rel="noreferrer">
                    <Icon color="var(--color-text)" name={IconNames.twitter} />
                  </a>
                </div>
              </div>
            </div>

            <div className={styles.teamMember}>
              <img
                src="https://pbs.twimg.com/profile_images/1631938866218258432/QRnxbWuj_400x400.jpg"
                alt="Vladimir Kosenko"
              />
              <div className={styles.teamMemberInfo}>
                <span>Vladimir Kosenko</span>
                <p>CMO & Co-founder</p>
                <div className={styles.links}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a href="https://twitter.com/web3brightside" title="twitter" target="_blank" rel="noreferrer">
                    <Icon color="var(--color-text)" name={IconNames.twitter} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Blog />

          <Faq />

          <Upsell />
        </div>
      </Container>
      <Footer />
    </AppLayout>
  )
}
