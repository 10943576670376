import { ReactElement } from 'react'
import { FormattedText } from 'src/core/ds/FormattedText'
import Illustration from '../assets/images/empty-box.png'

type Props = {
  title: string
  description?: string
  illustration?: boolean
  children?: ReactElement
}

export function EmptyList({ title, description, illustration, children }: Props) {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        padding: '1rem',
        margin: '0 auto',
        display: 'flex',
        height: '80%',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      {illustration && (
        <div>
          <img style={{ maxWidth: '70px' }} src={Illustration} alt="Empty block" />
        </div>
      )}
      <h2 style={{ fontWeight: '500', fontSize: '1.2rem' }}>
        <FormattedText id={title} />
      </h2>
      {description && (
        <p style={{ textAlign: 'center', color: 'var(--color-grey)', marginTop: '.5rem' }}>
          <FormattedText id={description} />
        </p>
      )}
      {children}
    </div>
  )
}
