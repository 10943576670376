/* eslint-disable jsx-a11y/label-has-associated-control */
import styles from './index.module.scss'

export function Checkbox({ checked, onChange, name = 'checkbox-toggle', disabled = false }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <label onClick={disabled ? () => false : onChange} htmlFor={name} className={styles.switch}>
      <input name={name} disabled={disabled} id={name} type="checkbox" checked={checked} />
      <div className={styles.slider} />
    </label>
  )
}
