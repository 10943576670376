import { apiClient } from 'src/api/api-client'
import { UseCollectionApiProps, useInfiniteCollectionApi } from 'src/api/core'
import { QUERY_KEY_FAVORITES } from './queryKeys'
import { Favorite } from './types'

type UseUserPreferencesApiProps = UseCollectionApiProps<typeof apiClient.favorites, Favorite>

export const useFavorites = (props?: UseUserPreferencesApiProps) => {
  const client = apiClient.favorites()

  const query = useInfiniteCollectionApi({
    ...props,
    // eslint-disable-next-line
    // @ts-ignore
    id: 'favorites',
    queryKey: QUERY_KEY_FAVORITES,
    // eslint-disable-next-line
    // @ts-ignore
    queryFn: ({ pageParam = 1 }) => client.getFavorites({ page: pageParam }),
  })

  return {
    ...query,
  }
}
