import { useMutation } from 'react-query'
import { apiClient } from 'src/api/api-client'
import { createQueryKey, UseCollectionApiProps, useInfiniteCollectionApi } from 'src/api/core'
import { ServerErrorEnum } from 'src/api/types'
import { useServerNotification } from 'src/hooks/serverNotification.hook'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { queryClient } from 'src/queries/reactQueryClient'
import { pushNotification } from 'src/services/notifications/notificationService'
import { QUERY_KEY_CURRENT_ACCOUNT } from '../account/queryKeys'
import { QUERY_KEY_USER_SNAPS } from './queryKeys'
import { Snap } from './types'

type UseUserPreferencesApiProps = UseCollectionApiProps<typeof apiClient.models, Snap> & {
  refetchEnabled?: boolean
  character_id?: string
}

export const useSnaps = (props?: UseUserPreferencesApiProps) => {
  const { fireEvent } = useAnalytics()
  const client = apiClient.snaps()
  const { notify } = useServerNotification()

  const query = useInfiniteCollectionApi({
    ...props,
    // eslint-disable-next-line
    // @ts-ignore
    id: 'snaps',
    queryKey: [QUERY_KEY_USER_SNAPS, props?.character_id],
    refetchInterval: props?.refetchEnabled ? 10000 : 9999999999,
    enabled: true,
    // eslint-disable-next-line
    // @ts-ignore
    queryFn: ({ pageParam = 1 }) => client.getSnaps({ page: pageParam, characterId: props?.character_id }),
  })

  const createSnapMutation = useMutation(client.createSnap, {
    onError: (error: [ServerErrorEnum, null]) => {
      notify(error as any)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_SNAPS, role: 'collection', scope: 'default' }),
      )
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_CURRENT_ACCOUNT })
      pushNotification({
        type: 'success',
        title: 'notification.snap.genStarted',
      })
      fireEvent(ANALYTICS_EVENTS.CreateSnap)
      window?.fbq('track', 'CreateSnap')
    },
  })

  const deleteSnapMutation = useMutation(client.deleteModel, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_SNAPS, role: 'collection', scope: 'default' }),
      )
    },
  })

  const upscaleMutation = useMutation(client.upscaleSnap, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_SNAPS, role: 'collection', scope: 'default' }),
      )
    },
  })

  return {
    ...query,
    createSnap: {
      ...createSnapMutation,
    },
    upscaleSnap: {
      ...upscaleMutation,
    },
    deleteSnap: {
      ...deleteSnapMutation,
    },
  }
}
