import { useCallback, useEffect, useState } from 'react'
import PinInput from 'react-pin-input'
import { ErrorField } from 'src/core/ds/errorField'
import { FormattedText } from 'src/core/ds/FormattedText'
import { SimpleLoader } from 'src/core/ds/spinner'
import { useAuth } from 'src/entities/account/useAuth'
import { useMedia } from 'src/hooks/useMedia'
import { FlowLayout } from 'src/layouts/flow/Flow.layout'
import { pushNotification } from 'src/services/notifications/notificationService'

let pinRef

export function LoginVerification({ email, goBack, onCancelFlow, onCompleteFlow }) {
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState(30)
  const [isTimerActive, setIsTimerActive] = useState(true)
  const width = useMedia()

  const { requestCodeWithEmail, verifyCode } = useAuth()

  useEffect(() => {
    let interval = null

    if (isTimerActive && seconds > 0) {
      // eslint-disable-next-line
      // @ts-ignore
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1)
      }, 1000)
    } else if (seconds === 0) {
      // eslint-disable-next-line
      // @ts-ignore
      clearInterval(interval)
      setIsTimerActive(false)
    }

    // eslint-disable-next-line
    // @ts-ignore
    return () => clearInterval(interval)
  }, [isTimerActive, seconds])

  useEffect(() => {
    pinRef.focus()
  }, [])

  const handleResendClick = async () => {
    try {
      await requestCodeWithEmail.mutateAsync({ email })
      setSeconds(30)
      setIsTimerActive(true)
      pushNotification({
        type: 'success',
        title: 'notification.code.success',
      })
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'notification.code.fail',
      })
    }
  }

  const onSubmit = useCallback(
    async (code: string) => {
      try {
        await verifyCode.mutateAsync({ email: email.toLowerCase(), code })
        width > 768 ? onCompleteFlow('/dashboard') : onCompleteFlow('/create')
      } catch (e) {
        console.log('error', e)
      }
    },
    [code],
  )

  return (
    <FlowLayout>
      <FlowLayout.Header goBack={goBack} onCancelFlow={onCancelFlow} />
      <FlowLayout.Body>
        <div style={{ maxWidth: 500, margin: '0 auto' }}>
          <FlowLayout.Title>
            <h1>
              <FormattedText id="login.verification.title" />
            </h1>
          </FlowLayout.Title>
          <FlowLayout.Description>
            <FormattedText id="login.verification.description" />
          </FlowLayout.Description>
          <div style={{ marginBottom: '1rem' }}>
            {!verifyCode.isLoading ? (
              <PinInput
                length={5}
                initialValue=""
                // eslint-disable-next-line no-return-assign
                ref={(n) => (pinRef = n)}
                onChange={(value) => setCode(value)}
                type="numeric"
                inputMode="number"
                style={{ padding: '15px', display: 'flex', justifyContent: 'center' }}
                inputStyle={{ border: 'none', fontSize: '24px', margin: '0 10px', borderBottom: '3px solid gray' }}
                inputFocusStyle={{ borderColor: 'var(--color-primary)' }}
                onComplete={(code) => {
                  onSubmit(code)
                }}
                autoSelect
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            ) : (
              <SimpleLoader />
            )}
            {verifyCode.error ? <ErrorField error={verifyCode.error} /> : null}
          </div>

          {!verifyCode.isLoading && (
            <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
              {isTimerActive ? (
                // eslint-disable-next-line react/jsx-one-expression-per-line
                <span style={{ opacity: '.6' }}>
                  <FormattedText id="login.verification.resendText" values={{ seconds }} />
                </span>
              ) : (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button style={{ cursor: 'pointer' }} type="button" onClick={handleResendClick}>
                  <FormattedText id="login.verification.resendAction" />
                </button>
              )}
            </div>
          )}
        </div>
      </FlowLayout.Body>
    </FlowLayout>
  )
}
