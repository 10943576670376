import { Link } from 'react-router-dom'
import { MetaTitle } from 'src/components/MetaTitle'
import { Button } from 'src/core/ds/button'
import { FormattedText } from 'src/core/ds/FormattedText'
import { useAllowedFeatures } from 'src/entities/account/useAllowedFeatures'
import { useFavorites, useFavoritesActions } from 'src/entities/favorites'
import { DashboardLayout } from 'src/layouts/DashboardLayout'
import { EmptyList } from 'src/widgets/EmptyList'
import { PhotoItem } from 'src/widgets/PhotoList/components/PhotoItem'
import styles from './index.module.scss'

export function FavoritesScreen() {
  const { data: snapPages, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useFavorites()

  const snaps: any = snapPages?.pages?.flatMap((page) => page?.items ?? page)

  const allowedFeatures = useAllowedFeatures()

  const { addFavorite, removeFavorite } = useFavoritesActions()

  const isHasAnyPhotos = snaps?.length > 0

  if (!allowedFeatures.canFavor) {
    return (
      <DashboardLayout>
        <MetaTitle title="Favorites" />
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <h2>
              <FormattedText id="favorites.page.title" />
            </h2>

            <div>
              <FormattedText id="favorites.page.availability" />
              <Link to="/prices#paidPlan">
                <Button type="button" label="favorites.page.availability.upgrade" />
              </Link>
            </div>
          </div>
        </div>
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2>
            <FormattedText id="favorites.page.title" />
          </h2>

          {!isHasAnyPhotos && !isLoading && (
            <EmptyList description="favorites.page.empty.title" title="favorites.page.empty.subtitle" illustration />
          )}

          {isHasAnyPhotos && (
            <div className={styles.list}>
              {snaps?.flatMap((snap) =>
                snap.snap.thumbs?.map((photo, index) => {
                  if (index === snap.photo) {
                    return (
                      <PhotoItem
                        key={`${snap?.id.toString()}${index}`}
                        downloadableUrl={snap.snap.photos[index]}
                        showPrompt
                        info={snap.info}
                        prompt={snap.snap.prompt.text}
                        photo={photo}
                        allowedFeatures={allowedFeatures}
                        snapId={snap.id.toString()}
                        onAddFav={addFavorite}
                        isFav
                        onRemoveFav={removeFavorite}
                        photoIndex={index}
                      />
                    )
                  }

                  return null
                }),
              )}
            </div>
          )}

          {hasNextPage && (
            <div className={styles.action}>
              <Button isLoading={isFetchingNextPage} type="button" onClick={fetchNextPage} label="Load more" />
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
