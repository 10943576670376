import { useState } from 'react'
import { Link } from 'react-router-dom'
import { apiClient } from 'src/api/api-client'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { FormattedText } from 'src/core/ds/FormattedText'
import { IconNames } from 'src/core/ds/icon'
import { Icon } from 'src/core/ds/icon/icon'
import { Input } from 'src/core/ds/input'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './prices.module.scss'

const IMAGE_PRICE = 15
const TRAIN_PRICE = 1
const PHOTO_PRICE = 60

export function PricesNew({ isInnerBlock = false }) {
  const { data: currentUser } = useCurrentAccount()
  const [amount, setAmount] = useState('10')
  const isStarterPlan = currentUser?.sub_plan === 'start'
  const client = apiClient.stripe()
  const { fireEvent } = useAnalytics()
  const token = (localStorage && localStorage.getItem('token')) || ''

  const onManagePlan = async () => {
    try {
      // console.log('onManagePlan')
      fireEvent(ANALYTICS_EVENTS.ClickManagePlan)
      const response = await client.getSubscription()
      const link = response as any

      // console.log('link', link)

      // if (error === 'e_customer_not_found') {
      //   pushNotification({
      //     type: 'error',
      //     title: 'Error',
      //     subtitle: 'No user found',
      //   })

      //   return
      // }

      setTimeout(() => {
        // eslint-disable-next-line
        // @ts-ignore
        window?.open(link, '_blank').focus()
      })

      // console.log('response', response)
    } catch (err) {
      console.log(err)
      pushNotification({
        type: 'error',
        title: 'Error',
        subtitle: "Can't get subscription plan link",
      })
    }
  }

  const onClickSub = () => {
    // eslint-disable-next-line
    // @ts-ignore
    window?.fbq('track', 'BuySubscription')
    // eslint-disable-next-line
    // @ts-ignore
    window?.fbq('track', 'Purchase', { value: 19.9, currency: 'USD' })
    fireEvent(ANALYTICS_EVENTS.ClickBuySubscription)
  }

  const onSubmitPayment = async (event) => {
    // firePlausibleEvent(PLAUSIBLE_EVENTS.CLICK_BUY_BUTTON)
    fireEvent(ANALYTICS_EVENTS.ClickBuyCredits)
    // eslint-disable-next-line
    // @ts-ignore
    window?.fbq('track', 'BuyCredits')
    // eslint-disable-next-line
    // @ts-ignore
    window?.fbq('track', 'Purchase', { value: amount, currency: 'USD' })

    if (+amount < 10 || Number.isNaN(+amount)) {
      event.preventDefault()
      pushNotification({
        title: 'Error',
        subtitle: 'Minimum amount is $10',
        type: 'error',
      })

      return false
    }

    return false
  }

  return (
    <div id="prices" className={styles.wrapper}>
      <div className={styles.freeWrapper}>
        {currentUser && isInnerBlock && (
          <h2>
            <FormattedText id="pricesNew.title" />
          </h2>
        )}

        {currentUser && isInnerBlock && (
          <div className={`${styles.paidPriceItem} `}>
            <div className={styles.paidPriceItemLeft}>
              <div>
                <FormattedText id="pricesNew.asyougo.title" />
              </div>
              <p>
                <FormattedText id="pricesNew.asyougo.description" />
              </p>
            </div>
            <div className={styles.paidPriceItemRight}>
              <div className={styles.buy}>
                <form action={`${config.baseUrl}/account/pay?value=${amount}`} method="post">
                  <input hidden name="TKN" value={token} />

                  <Input
                    action={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Button
                        type="submit"
                        onClick={onSubmitPayment}
                        label="pricesNew.asyougo.input.action"
                        size={ButtonSizes.xs}
                      />
                    }
                    value={amount}
                    prefix="$"
                    label="pricesNew.asyougo.input.label"
                    description="pricesNew.asyougo.input.description"
                    name="credits"
                    onChange={(event) => setAmount(event.target.value)}
                    placeholder="Enter amount"
                  />
                </form>
              </div>

              <div className={styles.buyDetails}>
                <div>
                  <span>
                    {Number.isNaN(+amount * IMAGE_PRICE) ? 0 : +amount * IMAGE_PRICE}
                    &nbsp;snaps (credits)
                  </span>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <p>
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    {Number.isNaN(+amount * PHOTO_PRICE) ? 0 : Number(+amount * PHOTO_PRICE)} photos or{' '}
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    {Number.isNaN(+amount * TRAIN_PRICE) ? 0 : Number(+amount * TRAIN_PRICE)} trains
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.paidWrapper}>
        <h2>
          <FormattedText id="pricesNew.paidPlan.header" />
        </h2>
        <div className={styles.pricePaid}>
          <FormattedText id="pricesNew.paidPlan.title" />
        </div>

        <div className={styles.others}>
          <div className={styles.stackedAvatars}>
            <img
              src="https://ph-avatars.imgix.net/3259959/469bf616-bc06-424f-86d6-810c0a18e635?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
              loading="lazy"
              width="36"
              height="36"
              alt="Social proof users"
            />
            <img
              src="https://ph-avatars.imgix.net/581391/23b58d4d-ab80-4d01-94b4-62c2b97859a8?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
              loading="lazy"
              width="36"
              height="36"
              alt="Social proof users"
            />
            <img
              src="https://ph-avatars.imgix.net/5136189/ff275044-b287-4562-a447-ceffc0333186?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
              loading="lazy"
              width="36"
              height="36"
              alt="Social proof users"
            />
            <img
              src="https://ph-avatars.imgix.net/5415947/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
              loading="lazy"
              width="36"
              height="36"
              alt="Social proof users"
            />
            <img
              src="https://ph-avatars.imgix.net/4955004/4d8950c7-10b4-4a14-ba54-efd9be352b38?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
              loading="lazy"
              width="36"
              height="36"
              alt="Social proof users"
            />
          </div>

          <p>
            <FormattedText id="pricesNew.paidPlan.join" />
          </p>
        </div>

        <div id="paidPlan" className={`${styles.paidPriceItem} ${isStarterPlan ? styles.selectedPlan : ''}`}>
          <div className={styles.paidPriceItemLeft}>
            <div>
              <FormattedText id="pricesNew.paidPlan.price" />
            </div>
            {!isStarterPlan && (
              <p>
                <FormattedText id="pricesNew.paidPlan.cancel" />
              </p>
            )}
            {isStarterPlan && (
              <p>
                <FormattedText id="pricesNew.paidPlan.current" />
              </p>
            )}
            {!currentUser && !isStarterPlan && (
              <Link to="/login">
                <Button type="button" label="pricesNew.paidPlan.getStarted" />
              </Link>
            )}
            {isStarterPlan && (
              <div className={styles.manage}>
                <Button type="button" onClick={onManagePlan} label="pricesNew.paidPlan.manage" />
              </div>
            )}

            {currentUser && !currentUser.is_subscriber && (
              <div className={styles.manage}>
                <form action={`${config.baseUrl}/account/pay/start?value=1`} method="post">
                  <input hidden name="TKN" value={token} />
                  <Button type="submit" onClick={onClickSub} label="pricesNew.subscribe.button" />
                </form>
              </div>
            )}
          </div>
          <div className={styles.paidPriceItemRight}>
            <div className={styles.pricePaidHeader}>
              <div className={styles.priceBadge}>
                <FormattedText id="pricesNew.paidPlan.start" />
              </div>
              {/* <div className={styles.priceDescription}>
                <FormattedText id="pricesNew.paidPlan.start.description" />
              </div> */}
            </div>

            <div className={styles.paidList}>
              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature1" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature2" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature3" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature4" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature5" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature6" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature7" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature8" />
                  {/* <p>test</p> */}
                </div>
              </div>

              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature9" />
                  {/* <p>test</p> */}
                </div>
              </div>
              <div className={styles.paidItem}>
                <Icon name={IconNames.checkFill} color="var(--color-primary)" />
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <div>
                  <FormattedText id="pricesNew.subscribe.feature10" />
                  {/* <p>test</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
