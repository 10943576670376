import { Link, useNavigate } from 'react-router-dom'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Tooltip } from 'src/core/ds/tooltip/Tooltip'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { ModelItem } from './components/ModelItem'
import styles from './index.module.scss'

export function ModelList({ models, selectedModel, setSelectedModel }) {
  const navigate = useNavigate()
  const { fireEvent } = useAnalytics()
  const { data: currentUser } = useCurrentAccount()

  const isOnboardingState = models?.length === 1 && models[0].status === 'created'

  return (
    <div>
      <div className={styles.title}>
        <p>
          <FormattedText id="modelList.title" />
          <Tooltip content="modelList.tooltip" />
        </p>
        {!selectedModel && (
          <div className={styles.select}>
            <FormattedText id="modelList.select" />
          </div>
        )}
      </div>

      {isOnboardingState && (
        <div className={styles.onboarding}>
          <Icon name={IconNames.alertCircle} color="black" />
          <p>
            <FormattedText id="modelList.add.onboarding" />
          </p>
        </div>
      )}

      <div className={styles.list}>
        {/* eslint-disable-next-line  */}
        {/* @ts-ignore  */}
        {models?.length < currentUser?.limits?.characters && (
          <div className={styles.addNewModel}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              onClick={() => {
                fireEvent(ANALYTICS_EVENTS.ClickCreateModel)
                navigate('/add-new-model')
              }}
              type="button"
            >
              <Icon name={IconNames.imageAdd} />
              <span>
                <FormattedText id="modelList.add.title" />
              </span>
              <p>
                <FormattedText id="modelList.add.upload" />
              </p>
            </button>
          </div>
        )}

        {/* eslint-disable-next-line  */}
        {/* @ts-ignore  */}
        {models?.length >= currentUser?.limits?.characters && (
          <div className={styles.addNewModel}>
            <Link to="/prices">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                onClick={() => {
                  // fireEvent(ANALYTICS_EVENTS.ClickCreateModel)
                  // navigate('/add-new-model')
                }}
                type="button"
              >
                <Icon name={IconNames.imageAdd} />
                <span>
                  <FormattedText id="modelList.add.upgrade.title" />
                </span>
                <p>
                  <FormattedText id="modelList.add.upgrade.description" />
                  {currentUser?.limits?.characters}
                </p>
              </button>
            </Link>
          </div>
        )}

        {models?.map((model) => (
          <ModelItem
            key={model.id.toString()}
            model={model}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
        ))}
      </div>
    </div>
  )
}
