/* eslint-disable react/no-unescaped-entities */
import { Helmet } from 'react-helmet'
import { Container } from 'src/core/ds/container'
import { ScrollToTop } from 'src/hooks/scrollToTop'
import { Footer } from 'src/layouts/Footer'
import { Header } from 'src/layouts/Header'
import { Upsell } from 'src/screens/landing/parts/Upsell'
import { blogData } from './data'
import styles from './index.module.scss'

const data = blogData['3']

export function BlogPageFour() {
  ScrollToTop()

  return (
    <>
      <Header />
      <div
        className={styles.surface}
        style={{
          backgroundImage: `url(${data.image})`,
        }}
      >
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.description} />
          <meta property="og:title" content={data.title} />
          <meta property="og:description" content={data.description} />
        </Helmet>
        <div className={styles.hero}>
          <Container>
            <h1>{data.title}</h1>
            <p>{data.description}</p>
          </Container>
        </div>
      </div>
      <div className={styles.content}>
        <Container>
          <div className={styles.date}>
            Published:
            {data.date}
          </div>

          <p>
            Welcome to the world of Snaptap.AI, where your imagination is the only limit to creating stunning photos of
            yourself with just a text prompt. Whether you're new to Instagram or looking to spice up your feed,
            Snaptap.AI, powered by the Stable Diffusion XL 1.0 model, offers an exciting way to transform your ideas
            into visually captivating images without needing any technical know-how.
          </p>

          <p>
            Here's a simple guide on how to write descriptions that will get you the best results from Snaptap.AI.
            Remember, it's all about clarity, creativity, and letting the AI understand exactly what you envision.
          </p>

          <h2>Understand the Basics</h2>

          <p>
            First off, it's important to note that Snaptap.AI automatically incorporates the subject (you, in this case)
            into the generated photo. So, your job is to describe everything else—what you're doing, wearing, and where
            you are, along with the mood or style of the picture.
          </p>

          <h3>Crafting Your Description</h3>

          <ol>
            <li>
              Detailed Imagery: Start with what you're wearing or doing. Instead of saying, "photo of a person wearing
              something," specify exactly what you want. For example, "dressed in a vintage leather jacket and aviator
              sunglasses."
            </li>
            <li>
              Environment Description: Where are you? Describe the setting around you. If you're indoors, mention the
              type of room and key elements (e.g., "sitting in a cozy, dimly lit café"). For outdoor scenes, paint a
              picture of the location (e.g., "standing on a bustling city street at sunset").
            </li>
            <li>
              Mood/Atmosphere: What's the vibe? Convey the feeling of the scene. Phrases like "a sense of mystery and
              adventure in the air" or "a calm and peaceful morning" set the tone.
            </li>
            <li>
              Style and Execution: Here's where you can really let your creativity shine. Mention any artistic style or
              visual effects you want. "In the style of an old Hollywood glamour shot" or "with a vibrant pop art
              effect" guides the AI in the right direction.
            </li>
            <li>
              Exclusions (Negative Prompting): If there's something you definitely don't want included, make it known.
              For instance, "no crowds in the background" or "avoid using bright colors."
            </li>
          </ol>

          <h3>Example Description</h3>

          <p>
            "Leaning against the railing of an old, wooden bridge, dressed in a flowy, white summer dress with delicate
            floral patterns. The golden hour sunlight casts a warm glow, enhancing the serene, dreamy atmosphere of the
            secluded countryside around me. Capture this moment in a soft, impressionist painting style, focusing on the
            gentle interplay of light and shadow, ensuring no modern buildings are visible in the landscape."
          </p>

          <h4>Tips for Success</h4>

          <ul>
            <li>
              Be Specific: The more detailed your description, the closer the outcome will be to what you envision.
            </li>
            <li>
              Think Visually: Imagine the scene in your mind's eye. What stands out? What are the key elements that make
              it unique?
            </li>
            <li>
              Play with Styles: Don't hesitate to mix elements from different styles or periods for something truly
              unique.
            </li>
            <li>
              Review and Revise: If the first result isn't quite right, tweak your description based on what you'd like
              to change.
            </li>
          </ul>

          <p>
            Here are some examples of good prompting based on the guidelines provided in the article "How to Craft the
            Perfect Photo Description for Snaptap.AI":
          </p>

          <ul>
            <li>
              **Vintage Elegance:** "Capture me lounging on a velvet chaise, wearing a 1920s-inspired flapper dress
              adorned with sequins and feathers, a crystal headband gleaming in my waved hair. The room is rich in Art
              Deco details, with geometric patterns and lush green plants. The mood is luxurious and opulent,
              reminiscent of a Gatsby party scene, filtered through a sepia tone to enhance the vintage feel. Ensure
              there are no modern elements to maintain the era's authenticity."
            </li>
            <li>
              **Adventure in the Wild:** "Depict me standing at the edge of a rugged cliff, dressed in outdoor hiking
              gear with a lightweight backpack. The vast landscape stretches behind me, showcasing a panoramic view of
              towering mountains and a river cutting through the valley below. The atmosphere is exhilarating and wild,
              bathed in the soft light of the early morning sun. I want the style to be hyper-realistic, capturing every
              detail of the adventure spirit, with a clear emphasis on the natural beauty and excluding any signs of
              urban life."
            </li>
            <li>
              **Futuristic Cityscape:** "Imagine me walking through a neon-lit city street at night, futuristic
              skyscrapers towering above. I'm wearing a sleek, modern outfit with smart fabrics and integrated tech
              accessories. The vibe is cyberpunk, full of energy and mystery. The scene should be drenched in vibrant
              colors, especially blues and purples, with reflections on wet asphalt. I'd like this to be captured with a
              dynamic angle, emphasizing the sense of scale and the buzz of the future city life, making sure there are
              no outdated vehicles or architecture in view."
            </li>

            <li>
              4. **Serene Beach Sunset:** "Visualize me standing barefoot on a pristine sandy beach, the hem of my
              flowing maxi dress gently swaying in the sea breeze. My arms are open wide, embracing the breathtaking
              view of the sun setting over the ocean, painting the sky in shades of orange, pink, and purple. The mood
              is tranquil and blissful, a perfect moment of harmony with nature. I want this captured in a realistic
              style but with a touch of dreaminess, focusing on the warm lighting and the soothing atmosphere, with no
              other people or man-made structures to disturb the peace."
            </li>

            <li>
              5. **Historical Fantasy:** "Portray me as a knight in shining armor, riding a majestic white steed through
              an ancient forest. The setting is mystical, with towering old trees and a carpet of vibrant green moss.
              Sunlight filters through the leaves, creating a magical, ethereal quality. The atmosphere is one of
              adventure and valiance, straight out of a high fantasy tale. I'd like the style to blend realism with
              fantasy elements, highlighting the rich textures and the sense of movement, ensuring that modern elements
              are conspicuously absent to preserve the timeless fantasy."
            </li>
          </ul>

          <p>
            With Snaptap.AI, you're the director of your own visual story. By following these guidelines and letting
            your imagination run wild, you'll be able to create personalized, awe-inspiring photos that stand out on any
            social media platform. Happy creating!
          </p>
        </Container>
      </div>
      <Upsell />

      <Footer />
    </>
  )
}
