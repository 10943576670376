import create from 'zustand'
import { persist } from 'zustand/middleware'

export interface LanguageStoreType {
  currentLocale: string
  updateLanguage: (lang: string) => void
}
const localeMap = {
  'en-US': 'en-US',
  en: 'en-US',
  'ru-RU': 'ru-RU',
  ru: 'ru-RU',
  th: 'th-TH',
  'th-TH': 'th-TH',
}

function getUrlLang(): string | null {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('lang')
}

const supportedLocales = ['en-US', 'ru-RU', 'th-TH', 'en', 'ru', 'th'] // Example supported locales
const browserLocale = navigator.language
const defaultLocale = 'en-US' // Default fallback

export const useLanguageStore = create<LanguageStoreType>()(
  persist(
    (set) => ({
      currentLocale: (() => {
        const urlLang = getUrlLang()

        if (urlLang && supportedLocales.includes(urlLang)) {
          return localeMap[urlLang] || urlLang
        }

        return supportedLocales.includes(browserLocale) ? localeMap[browserLocale] : defaultLocale
      })(),
      updateLanguage: (lang: string) => set({ currentLocale: lang }),
    }),
    {
      name: 'languageStore',
    },
  ),
)
