import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'

export type Props = {
  options: any[]
  selected: any
  noBorder?: boolean
  label?: string
  onSelect?: any
  disabled?: boolean
}

export function Selector({ label, disabled = false, onSelect, options, selected, noBorder }: Props) {
  return (
    <div className={styles.container}>
      {label && (
        <label htmlFor="selector-id" className={styles.label}>
          {label}
        </label>
      )}
      <span className={styles.icon}>
        <Icon color="var(--color-grey)" size={IconSize.xs} name={IconNames.shevronDown} />
      </span>
      <select
        disabled={disabled}
        onChange={(e) => onSelect(e.target.value)}
        id="selector-id"
        value={selected}
        className={`${styles.select} ${noBorder ? styles.noBorder : ''}`}
      >
        {options.map(({ id, value, title }) => (
          <option key={id} value={value}>
            {title}
          </option>
        ))}
      </select>
    </div>
  )
}
