import { Link } from 'react-router-dom'
import { blogData } from 'src/blog/data'
import { MetaTitle } from 'src/components/MetaTitle'
import { Container } from 'src/core/ds/container'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import { Faq } from '../landing/parts/Faq'
import { Upsell } from '../landing/parts/Upsell'
import styles from './index.module.scss'

export function BlogScreen() {
  return (
    <AppLayout>
      <MetaTitle title="Blog" />
      <Container>
        <div className={styles.content}>
          <div className={styles.hero}>
            <h1>Blog</h1>
          </div>

          <div className={styles.blogList}>
            {Object.keys(blogData).map((key) => (
              <div key={key} className={styles.blogItem}>
                <Link to={blogData[key].link}>
                  <img src={blogData[key].image} alt="" loading="lazy" />
                </Link>
                <Link to={blogData[key].link}>{blogData[key].title}</Link>
                <p>{blogData[key].description}</p>
              </div>
            ))}
          </div>

          <Faq />

          <Upsell />
        </div>
      </Container>
      <Footer />
    </AppLayout>
  )
}
