import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Input } from 'src/core/ds/input'
import { useModels } from 'src/entities/models/useGetModels'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { FlowLayout } from 'src/layouts/flow/Flow.layout'
import WelcomePhoto from '../../../assets/images/onboarding.webp'
import styles from '../index.module.scss'

const inputValidationParams = {
  min: 3,
  max: 64,
}

export function ModelCreateFlow(props) {
  const location = useLocation()
  const { setCreatedModelId } = props
  const [modelName, setModelName] = useState('')
  const [error, setError] = useState<string | null>(null)
  const { createModel } = useModels()
  const { fireEvent } = useAnalytics()

  const characterId = createModel.data?.id?.toString()

  const onModelCreate = async (e) => {
    e.preventDefault()
    const isValid = validation(modelName)

    if (!isValid) return

    try {
      await createModel.mutateAsync({ name: modelName })

      // console.log(mutate)
    } catch (e) {
      alert('cant create new model')
    }
  }

  const validation = (text: string) => {
    setError(null)

    if (text.length < inputValidationParams.min) {
      setError('Name should be at least 2 characters long.')

      return false
    }

    if (text.length > inputValidationParams.max) {
      setError('Name should be 64 characters max.')

      return false
    }

    return true
  }

  useEffect(() => {
    if (!characterId) return

    // eslint-disable-next-line
    // @ts-ignore
    window.characterId = characterId
    setCreatedModelId(characterId)
    goNext()
  }, [characterId])

  useEffect(() => {
    fireEvent(ANALYTICS_EVENTS.ModelFlowCreateView)
  }, [])

  const { onCancelFlow, goNext } = props
  const isOnOnboardingPage = location.pathname === '/onboarding'

  return (
    <FlowLayout>
      <FlowLayout.Header onCancelFlow={onCancelFlow} />
      <FlowLayout.Body>
        {isOnOnboardingPage && (
          <div className={styles.image}>
            <img src={WelcomePhoto} loading="lazy" width="350" height="217" alt="Welcome to Snaptap" />
          </div>
        )}
        <FlowLayout.Title>
          <h1>
            <FormattedText id={isOnOnboardingPage ? 'addModel.create.title.welcome' : 'addModel.create.title'} />
          </h1>
        </FlowLayout.Title>
        {isOnOnboardingPage && (
          <div>
            <FlowLayout.Description>
              <FormattedText id="addModel.create.title.welcome.description" />
            </FlowLayout.Description>
          </div>
        )}
        <form onSubmit={onModelCreate}>
          <div style={{ margin: '1rem auto', maxWidth: '600px', marginBottom: '1rem' }}>
            <Input
              // size="l"
              // action={<Button type="submit" onClick={onSubmitPayment} label="Buy" size={ButtonSizes.xs} />}
              value={modelName}
              error={error}
              label="addModel.create.input.name"
              // description="addModel.create.input.description"
              name="modelName"
              onChange={(event) => setModelName(event.target.value)}
              placeholder="Character name"
            />
            <FlowLayout.Footer>
              <Button
                disabled={createModel.isLoading}
                // onClick={onModelCreate}
                isLoading={createModel.isLoading}
                type="submit"
                label="addModel.create.button.continue"
              />
            </FlowLayout.Footer>
          </div>
        </form>
      </FlowLayout.Body>
    </FlowLayout>
  )
}
