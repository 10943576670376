// import axios from 'axios'
// import { readAndCompressImage } from 'browser-image-resizer'
// import ImageResize from 'image-resize'
// import heic2any from 'heic2any'
import { apiClient } from 'src/api/api-client'
// import { config } from 'src/config/config'
import { pushNotification } from 'src/services/notifications/notificationService'

// const maxWidth = 3000
// const imageResize = new ImageResize({
//   format: 'png',
//   width: 2000,
// })

const preprocessImage = async (file, minWidth, minHeight) => {
  const img = new Image()

  if (file.type === 'image/heic') {
    // const heicImage = await heic2any({
    //   blob: file,
    //   toType: 'image/png', // or "image/png"
    // })
    // console.log('heicImage', heicImage)
    // eslint-disable-next-line
    // @ts-ignore
    img.src = URL.createObjectURL(file)

    return img
  }

  img.src = URL.createObjectURL(file)

  // Create an Image object

  // img = new Image()

  await new Promise((resolve) => {
    img.onload = () => {
      console.log('loaded!', img)
    }
    // eslint-disable-next-line
    // @ts-ignore
    img.onload = () => resolve()
  })

  // Check if the image width is less than the minimum required width

  if (img.width < minWidth) {
    throw new Error(`Image width is less than the minimum required width of ${minWidth}px. Photo will be ignored.`)
  }

  if (img.height < minHeight) {
    throw new Error(`Image height is less than the minimum required height of ${minHeight}px. Photo will be ignored.`)
  }

  // Resize the image if it's wider than the maximum allowed width
  // downloadImage(file, 'resized-image.png')

  return file

  // const resizedImage =
  //   img.width > minWidth || img.height < minHeight ? await readAndCompressImage(file, { maxWidth }) : file
  // console.log('resizedImage', resizedImage, downloadImage)

  // const compressed = await imageResize.play(file)
  // console.log('compressed', compressed)

  // return resizedImage
}

// function downloadImage(blob, filename) {
//   // Create a URL for the blob
//   const url = URL.createObjectURL(blob)

//   // Create a temporary anchor element and trigger the download
//   const a = document.createElement('a')
//   a.href = url
//   a.download = filename
//   document.body.appendChild(a) // Append to body temporarily
//   a.click() // Trigger the download

//   // Clean up by revoking the blob URL and removing the anchor from the DOM
//   URL.revokeObjectURL(url)
//   document.body.removeChild(a)
// }

// function downloadBase64Image(base64Data, filename) {
//   // Create a temporary anchor element and set the href to the Base64 data
//   const a = document.createElement('a')
//   a.href = base64Data
//   a.download = filename
//   document.body.appendChild(a) // Append to body temporarily
//   a.click() // Trigger the download

//   // Clean up by removing the anchor from the DOM
//   document.body.removeChild(a)
// }

const client = apiClient.storage()

export const imageUpload = async (file, modelId) => {
  if (!file) return

  try {
    // Preprocess the image (check width and resize if necessary)

    const minWidth = 832 // Set your minimum width

    const minHeight = 1216 // Set your maximum allowed size

    const processedImage = await preprocessImage(file, minWidth, minHeight)

    // Use FormData to construct the payload

    const formData = new FormData()

    formData.append('file', processedImage)

    const endpoint = await client.getPresignedUrl(modelId)

    // eslint-disable-next-line
    // @ts-ignore
    await fetch(endpoint, {
      method: 'PUT',
      body: file, // This is your file object
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    // alert('Image uploaded successfully!')
  } catch (error) {
    pushNotification({
      type: 'error',
      title: `${file.name} can't be uploaded. Wrong size.`,
    })
  }
}
