import { AIModel } from 'src/entities/models/types'

export const useModelActions = (model?: AIModel | null) => {
  const defaultActions = {
    remove: false,
    train: false,
    retrain: false,
    edit: false,
  }

  if (!model) {
    return defaultActions
  }

  const remove = model.status === 'created' || model.status === 'ready'
  const train = model.status === 'created'
  const retrain = model.status === 'ready'
  const edit = false

  return {
    remove,
    train,
    retrain,
    edit,
  }
}
