import { Link } from 'react-router-dom'
import { ProgressSkeleton } from 'src/components/skeletons/ProgressSkeleton'
import { Badge } from 'src/core/ds/badge'
import { BadgeType } from 'src/core/ds/badge/types'
import { FormattedText } from 'src/core/ds/FormattedText'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { useActiveSnaps } from 'src/entities/snap/useActiveSnap.hook'
import styles from './index.module.scss'

export function BalanceWidget() {
  const { data: currentUser } = useCurrentAccount()

  const { inAQueue, inProgress } = useActiveSnaps()

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>
          <FormattedText id="status.widget.title" />
        </span>
        {inAQueue?.length === 0 && inProgress === null && (
          <div>
            <FormattedText id="status.widget.inactive" />
          </div>
        )}
      </div>

      {inProgress && (
        <div className={styles.current}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <p>
            <FormattedText id="status.widget.current" />
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            snap – {inProgress?.progress}%
          </p>
          <ProgressSkeleton>
            <span style={{ width: `${inProgress?.progress}%` }} className={styles.line} />
          </ProgressSkeleton>
        </div>
      )}

      {inAQueue?.length > 0 && (
        <div className={styles.queue}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <p>
            <FormattedText id="status.widget.queue" />
            –&nbsp;
            {inAQueue?.length}
          </p>
          <div className={styles.queueList}>
            {(inAQueue || []).map((el, index) => (
              <div key={index} className={styles.queueItem} />
            ))}
          </div>
        </div>
      )}

      {currentUser?.is_subscriber && (
        <div className={styles.floatBadge}>
          <Link to="/prices">
            <Badge type={BadgeType.SUCCESS} label="STARTER" />
          </Link>
        </div>
      )}
      {/* <div className={styles.list}>
        <div className={styles.listItem}>
          Trains left:&nbsp;
          {currentUser?.sub_trains}
        </div>
        <div className={styles.listItem}>
          Snaps left: &nbsp;
          {currentUser?.sub_snaps}
        </div>
      </div> */}
    </div>
  )
}
