import { useRef } from 'react'
import { ScrollToTopButton } from 'src/components/ScrollTopButton/ScrollTopButton'
import { useMedia } from 'src/hooks/useMedia'
import { CreationForm } from 'src/widgets/CreationForm/CreationForm.widget'
import { DashboardHeader } from './DashboardHeader'
import styles from './index.module.scss'
import { MobileNavigation } from './MobileNavigation/MobileNavigation'

export function DashboardLayout({ children }) {
  const scrollableElementRef = useRef(null)
  const width = useMedia()

  return (
    <main className={styles.dashboard}>
      <DashboardHeader />
      <section ref={scrollableElementRef} className={styles.section}>
        {width > 768 && (
          <div className={styles.form}>
            <CreationForm key="creation" />
          </div>
        )}
        {children}
      </section>
      <ScrollToTopButton ref={scrollableElementRef} />
      <MobileNavigation />
    </main>
  )
}
