import { isSafeNumber, parse } from 'lossless-json'
import { config } from 'src/config/config'
import { handleServerResponse } from './responseHandler'

export const BASE_PATH = `${config.baseUrl}`

export function customNumberParser(value) {
  return !isSafeNumber(value) ? BigInt(value) : parseFloat(value)
}
interface RequestConfig {
  headers?: { [key: string]: string }
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export class HTTPClient {
  private defaultHeaders: { [key: string]: string }

  constructor() {
    // Set up any default headers or configurations here
    this.defaultHeaders = {
      'Cache-Control': 'no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate',
    }
  }

  private async refreshToken(): Promise<void> {
    // Implement your token refresh logic here
  }

  // eslint-disable-next-line max-params
  private async request<T>(method: HttpMethod, url: string, data?: T, config?: RequestConfig): Promise<T> {
    const token = (localStorage && localStorage.getItem('token')) || ''
    const headers = new Headers({
      ...this.defaultHeaders,
      ...(!url.includes('mailer')
        ? {
            'Account-Token': token || '',
          }
        : {}),
      ...(config?.headers || {}),
    })

    let body

    if (data) {
      body = JSON.stringify(data)
      headers.set('Content-Type', 'application/json')
    }

    try {
      const serverResponse = await fetch(BASE_PATH + url, {
        method,
        headers,
        body,
        ...config,
      })

      const responseText: any = await serverResponse.text()
      // eslint-disable-line
      const json = parse(responseText, null as any, customNumberParser) // await response.json();

      const response = handleServerResponse<T>(json as unknown as any)

      if (!serverResponse.ok) {
        if (serverResponse.status === 401) {
          await this.refreshToken()

          return this.request(method, url, data, config)
        }

        throw new Error(`HTTP error! status: ${response}`)
      }

      return response as Promise<T>
    } catch (error) {
      // Handle errors as necessary
      console.error('There was a problem with the fetch operation:', error)
      throw error
    }
  }

  public get<T>(url: string, config?: RequestConfig): Promise<T> {
    return this.request<T>('GET', url, undefined, config)
  }

  public post<T>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    return this.request<T>('POST', url, data, config)
  }

  public delete<T>(url: string, config?: RequestConfig): Promise<T> {
    return this.request<T>('DELETE', url, undefined, config)
  }

  public put<T>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    return this.request<T>('PUT', url, data, config)
  }

  public patch<T>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    return this.request<T>('PATCH', url, data, config)
  }
}

export const httpClient = new HTTPClient()
