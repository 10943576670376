/* eslint-disable max-classes-per-file */
import { memoize } from 'lodash'
import { User } from 'src/entities/account'
import { AIModel } from 'src/entities/models/types'
import { Snap } from 'src/entities/snap'
import { httpClient } from './apiClients'

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

type UpdateEntityParams<T> = RecursivePartial<T>
// export type HttpResponse<T = unknown, D = unknown> = Promise<T, D>

type API<T, C = T, U = UpdateEntityParams<T>, Q = unknown> = {
  readonly url: string
  fetch?(params?: Q): Promise<T[]>
  get?: (id: string) => Promise<T>
  create?(params: C): Promise<T>
  update?(id: string, data: U): Promise<T>
  delete?(id: string): Promise<unknown>
}

export type ServerResponse<T = unknown> = T

class AccountsApi implements API<User> {
  url = '/account/current'

  getCurrent = () => httpClient.get<ServerResponse<User>>(this.url).then((data) => data)
}

class AuthApi implements API<User> {
  url = '/account/'

  sendEmail = (params) => httpClient.post<ServerResponse<User>>(`${this.url}code`, params).then((data) => data)

  verifyCode = (params) =>
    httpClient.post<ServerResponse<{ token: string }>>(`${this.url}auth`, params).then((data) => data)

  logout = () => httpClient.post<ServerResponse<{ token: string }>>(`${this.url}logout`).then((data) => data)
}

class SnapsApi implements API<Snap> {
  url = '/snap/'

  getSnaps = ({ page, characterId = '' }) =>
    httpClient
      .get<ServerResponse<AIModel>>(
        `${this.url}list?page=${page}&limit=10${characterId ? `&character_id=${characterId}` : ''}`,
      )
      .then((data) => data)

  getExplorerSnaps = ({ page }) =>
    httpClient.get<ServerResponse<AIModel>>(`${this.url}explore?page=${page}&limit=20`).then((data) => data)

  deleteModel = (id) => httpClient.post<ServerResponse<AIModel>>(`${this.url}delete/${id}`).then((data) => data)

  // trainModel = (id) => httpClient.post<ServerResponse<AIModel>>(`${this.url}train/${id}`).then((data) => data)

  upscaleSnap = (params) =>
    httpClient.post<ServerResponse<AIModel>>(`${this.url}upscale/${params.id}`, params).then((data) => data)

  createSnap = (params) =>
    httpClient.post<ServerResponse<AIModel>>(`${this.url}create/${params.id}`, params).then((data) => data)
}

class FavotitesApi implements API<any> {
  url = '/favorite/'

  getFavorites = ({ page }) =>
    httpClient.get<ServerResponse<any>>(`${this.url}list?page=${page}&limit=10`).then((data) => data)

  deleteFavorite = (params) =>
    httpClient.post<ServerResponse<AIModel>>(`${this.url}delete/${params.id}`, params).then((data) => data)

  createFavorite = (params) =>
    httpClient.post<ServerResponse<AIModel>>(`${this.url}create/${params.id}`, params).then((data) => data)
}

class StripeApi implements API<any> {
  url = '/account/'

  getSubscription = () => httpClient.get<ServerResponse<any>>(`${this.url}subscription`).then((data) => data)
}

class ModelsApi implements API<AIModel> {
  url = '/character/'

  getModels = () => httpClient.get<ServerResponse<AIModel>>(`${this.url}list`).then((data) => data)

  deleteModel = (id) => httpClient.post<ServerResponse<AIModel>>(`${this.url}delete/${id}`).then((data) => data)

  trainModel = (id) => httpClient.post<ServerResponse<AIModel>>(`${this.url}train/${id}`).then((data) => data)

  createModel = (params) => httpClient.post<ServerResponse<AIModel>>(`${this.url}create`, params).then((data) => data)
}

class StorageApi implements API<AIModel> {
  url = '/storage/upload/'

  getPresignedUrl = (id: string) => httpClient.get<ServerResponse<AIModel>>(`${this.url}${id}`).then((data) => data)
}

class APIClient {
  accounts = memoize(() => new AccountsApi())

  auth = memoize(() => new AuthApi())

  models = memoize(() => new ModelsApi())

  storage = memoize(() => new StorageApi())

  snaps = memoize(() => new SnapsApi())

  favorites = memoize(() => new FavotitesApi())

  stripe = memoize(() => new StripeApi())
}

export const apiClient = new APIClient()
