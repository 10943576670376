import { MetaTitle } from 'src/components/MetaTitle'
import { DashboardLayout } from 'src/layouts/DashboardLayout'
import { PricesNew } from '../landing/parts/PricesNew'
// import { PhotoListSimple } from 'src/widgets/PhotoListSimple/PhotoListSimple.widget'
import styles from './index.module.scss'

export function PricesScreen() {
  return (
    <DashboardLayout>
      <MetaTitle title="Prices" />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <PricesNew isInnerBlock />
        </div>
      </div>
    </DashboardLayout>
  )
}
