import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { MetaTitle } from 'src/components/MetaTitle'
import { Button } from 'src/core/ds/button'
import { Loader } from 'src/core/ds/loader'
import { Selector } from 'src/core/ds/selector'
import { useAllowedFeatures } from 'src/entities/account/useAllowedFeatures'
import { useIsSubscriber } from 'src/entities/account/useIsSubscriber'
import { AIModel } from 'src/entities/models/types'
import { useModels } from 'src/entities/models/useGetModels'
import { useSnaps } from 'src/entities/snap/useGetSnaps'
import { DashboardLayout } from 'src/layouts/DashboardLayout'
import { EmptyList } from 'src/widgets/EmptyList'
import { PhotoList } from 'src/widgets/PhotoList/PhotoList.widget'
import styles from './index.module.scss'

export function DashboardScreen() {
  const [refetchEnabled, setRefetchEnabled] = useState(false)
  const [filter, setFilter] = useState('')
  const {
    data: snapPages,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useSnaps({ refetchEnabled, character_id: filter })
  const allowedFeatures = useAllowedFeatures()
  const { data: models } = useModels()
  const { isSubscriber } = useIsSubscriber()

  const snaps: any = snapPages?.pages?.flatMap((page) => page?.items ?? page)
  const inAQueue = snaps?.filter((snap) => snap.status === 'pending' || snap.status === 'created') || null

  // eslint-disable-next-line
  // @ts-ignore
  const options = (models || [])
    // eslint-disable-next-line
    // @ts-ignore
    ?.filter((model) => model.status !== 'created')
    // eslint-disable-next-line
    // @ts-ignore
    .map((model: AIModel) => ({
      id: model?.id?.toString(),
      title: model?.name,
      value: model?.id?.toString(),
    }))

  useEffect(() => {
    if (snapPages && snaps) {
      const isRefetchEnabled = snaps.find((snap) => snap.status !== 'ready')
      setRefetchEnabled(isRefetchEnabled) // Be careful to avoid infinite loops here
    }
  }, [snaps])

  const isHasAnyPhotos = snaps?.length > 0

  return (
    <DashboardLayout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <MetaTitle title="Dashboard" />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.headLine}>
            <h2>Snaps</h2>
            {allowedFeatures.advancedFilter && (
              <Selector
                onSelect={setFilter}
                options={[{ id: 1, value: 'All', title: 'All characters' }, ...(options as any)]}
                selected={filter}
              />
            )}
          </div>

          {!isHasAnyPhotos && !isLoading && (
            <EmptyList description="emptyStates.noPhotos.description" title="emptyStates.noPhotos.title" illustration />
          )}
          {isLoading && <Loader isBig />}
          {!isSubscriber && inAQueue?.length > 1 && (
            <div className={styles.upsell}>
              <Link to="/prices">Feeling slow? Upgrade Account!</Link>
            </div>
          )}
          {isHasAnyPhotos && snaps?.map((snap) => <PhotoList key={snap.id.toString()} snap={snap} />)}

          {hasNextPage && (
            <div className={styles.action}>
              <Button isLoading={isFetchingNextPage} type="button" onClick={fetchNextPage} label="common.loadMore" />
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
