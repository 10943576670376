type ServerError = string

type ServerResponse<T> = [ServerError | null, T | null]

// Usage:
export function handleServerResponse<T>(response: ServerResponse<T>) {
  const [error, data] = response

  if (error !== null) {
    // Handle the error
    throw error
  } else if (data !== null) {
    // Handle the data

    return data as T
  }

  return null
}
