export const copyToClipboard = async (text: string, cb: any) => {
  // if (!navigator.clipboard) return
  navigator.clipboard.writeText(text).then(() => {
    // console.log('Async: Copying to clipboard was successful!')
    if (typeof cb === 'function') {
      cb()
    }
  })
}

export const claculateProgress = (progress) => {
  const total = 10000 // You should replace this with your actual total
  // Calculate percentage
  const percentage = (progress / total) * 100

  return `${percentage}%`
}

export function calculateTimeLeft(progressPercent, startTimestamp) {
  // Current timestamp
  const currentTimestamp = Date.now()

  // Time elapsed since the start in milliseconds
  const elapsedTime = currentTimestamp - startTimestamp

  if (progressPercent < 10) return 30

  if (progressPercent > 95) return 1

  // eslint-disable-next-line padding-line-between-statements
  const totalTimeExpected = (elapsedTime * 100) / progressPercent

  // Calculate the remaining time in milliseconds
  const remainingTime = totalTimeExpected - elapsedTime

  // Convert remaining time from milliseconds to minutes
  const remainingTimeInMinutes = remainingTime / (1000 * 60)

  // Return the remaining time rounded to two decimal places
  return remainingTimeInMinutes.toFixed(0)
}

export function getWordCount(str = '') {
  // Split the string into words using space as separator

  // Use the regex /\s+/ to match one or more whitespace characters

  const words = str?.split(/\s+/) || []

  // Filter out empty strings to accurately count words

  const filteredWords = words.filter((word) => word.length > 0)

  // Return the number of words

  return filteredWords.length
}

export function isSignUpEvent(timestamp) {
  // Given timestamp
  // var timestamp = 1708279338
  const date = new Date(timestamp * 1000) // Convert to milliseconds and create Date object

  // Get current date and time
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const difference = currentDate.getTime() - date.getTime()

  // Convert milliseconds into minutes
  const differenceInMinutes = difference / (1000 * 60)

  // Check if the difference is 10 minutes or more
  if (differenceInMinutes >= 10) {
    return false
  }

  return true
}

export function addResolutionToImageUrl(url) {
  // Use a regular expression to match the part of the URL just before the extension
  const regex = /(\.[0-9a-z]+)$/i
  // Replace the matched part with "@4x" inserted right before the extension
  const newUrl = url.replace(regex, '@4x$1')

  return newUrl
}
