import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { Loader } from 'src/core/ds/loader'
import styles from './index.module.scss'

export function ImageConverter() {
  const [imageSrc, setImageSrc] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    setIsLoading(true)
    const reader = new FileReader()

    reader.onload = (event) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext('2d')
        // eslint-disable-next-line
        // @ts-ignore
        ctx.drawImage(img, 0, 0)

        const pngUrl = canvas.toDataURL('image/png')
        // eslint-disable-next-line
        // @ts-ignore
        setImageSrc(pngUrl)
        setIsLoading(false)
      }
      // eslint-disable-next-line
      // @ts-ignore
      img.src = event.target.result
    }

    reader.readAsDataURL(file)
  }

  const downloadImage = () => {
    if (!imageSrc) return

    const downloadLink = document.createElement('a')
    downloadLink.href = imageSrc
    downloadLink.download = 'converted_image.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <>
      <div className={styles.loaderContainer}>
        <input type="file" onChange={handleImageUpload} accept="image/jpeg" />

        <Button
          label="Download PNG"
          type="button"
          onClick={downloadImage}
          isLoading={isLoading}
          disabled={!imageSrc || isLoading}
        />
      </div>
      {isLoading && <Loader isBig />}
    </>
  )
}
