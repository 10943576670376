export const testimonials = [
  {
    id: 1,
    title: '"Transforming my Instagram presence has never been easier, thanks to this incredible service!"',
    description:
      "I was amazed at how quickly I could create professional-looking photos that align perfectly with my brand's aesthetic. This tool is a must-have for anyone serious about their online image.",
    authorName: 'Ava',
    authorImage: 'https://photo-dev.snaptap.ai/113657790681055233/144384891075690497/157649169027170305-2.webp',
  },
  {
    id: 2,
    title: '"An absolute timesaver for creating high-quality content!"',
    description:
      'As a small business owner, I always struggled with finding the time and resources for photoshoots. Snaptap has completely changed the game for me, allowing me to produce stunning visuals in minutes.',
    authorName: 'Liam',
    authorImage: 'https://photo.snaptap.ai/145101225770090497/148553925375557633/154498504473968641-2.webp',
  },
  {
    id: 3,
    title: '"My go-to solution for unique and eye-catching social media posts."',
    description:
      "I've been able to consistently wow my followers and stand out from the competition. The ease of use and quality of results are unmatched. Highly recommend it to anyone looking to elevate their social media game.",
    authorName: 'Sophia',
    authorImage: 'https://api.picogen.io/files/202401/19/ab53e6eaa6a0f0b7e5e143aa13cb5f9f.png',
  },
  {
    id: 4,
    title: '"Revolutionized the way I create content for my personal brand."',
    description:
      "I can't emphasize enough how much time and money I've saved while achieving better results. This tool has allowed me to experiment with different styles and concepts without any of the usual hassle or expense.",
    authorName: 'Ethan',
    authorImage: 'https://photo.snaptap.ai/145101225770090497/145153814138191873/145265396860059649-2.webp',
  },
]
