import { NavLink } from 'react-router-dom'
import { FormattedText } from 'src/core/ds/FormattedText'
import styles from './index.module.scss'

export function SideNavigation() {
  return (
    <nav className={styles.wrapper}>
      <ul className={styles.nav}>
        <li>
          <NavLink className={styles.navLink} to="/dashboard">
            <FormattedText id="navigation.top.dashboard" />
          </NavLink>
        </li>

        <li>
          <NavLink className={styles.navLink} to="/favorites">
            <FormattedText id="navigation.top.favorites" />
          </NavLink>
        </li>

        <li>
          <NavLink className={styles.navLink} to="/explore">
            <FormattedText id="navigation.top.explore" />
          </NavLink>
        </li>

        <li>
          <NavLink className={styles.navLink} to="/prices">
            <FormattedText id="navigation.top.prices" />
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}
