import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Loader } from 'src/core/ds/loader'
import { useAuth } from 'src/entities/account/useAuth'
import styles from './index.module.scss'

export function MagicLinkScreen() {
  const query = new URLSearchParams(useLocation().search)

  const email = query.get('email')
  const code = query.get('code')

  const { verifyCode } = useAuth()

  // const onLoginSuccess = () => {
  //   pushNotification({
  //     type: 'success',
  //     title: 'Success Login',
  //     subtitle: 'You will be redirected.',
  //   })
  //   navigate(`/zen`)
  // }

  // const onRedirect = () => {
  //   onLoginSuccess()
  // }

  useEffect(() => {
    verifyCode.mutate({ email, code } as any)
  }, [])

  if (verifyCode.isLoading) {
    return (
      <div className={styles.centered}>
        <Loader isBig />
      </div>
    )
  }

  if (verifyCode.isError) {
    return (
      <div className={styles.centered}>
        <div>The link is not valid anymore.</div>
        <a href="/">Go home</a>
      </div>
    )
  }

  return (
    <div className={styles.centered}>
      <Loader isBig />
    </div>
  )
}
