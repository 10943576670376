import { AIModel } from 'src/entities/models/types'

export const shouldShowLoader = (model: AIModel) => {
  if (model.status === 'pending') {
    return true
  }

  if (model.status === 'processing') {
    return true
  }

  return false
}
