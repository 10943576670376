import { forwardRef } from 'react'
import { Icon, IconNames } from 'src/core/ds/icon'
import { useMedia } from 'src/hooks/useMedia'
import styles from './index.module.scss'

export const ScrollToTopButton = forwardRef((props, ref) => {
  // Function to scroll to the top
  // eslint-disable-next-line
  // @ts-ignore
  const width = useMedia()
  const scrollToTop = () => {
    // eslint-disable-next-line
    // @ts-ignore
    if (ref?.current) {
      if (width > 768) {
        // eslint-disable-next-line
        // @ts-ignore
        ref?.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button type="button" onClick={scrollToTop} className={styles.button}>
        <Icon name={IconNames.arrowUp} />
      </button>
    </div>
  )
})
