import { FormattedMessage } from 'react-intl'
import { getErrorMessage } from 'src/hooks/serverNotification.hook'
import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'

export function ErrorField({ error }) {
  return (
    <div className={styles.errorField}>
      <Icon size={IconSize.xs} name={IconNames.alertFill} color="var(--color-red)" />
      <span>
        <FormattedMessage id={getErrorMessage(error)} />
      </span>
    </div>
  )
}
