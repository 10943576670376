import { useMutation } from 'react-query'
import { apiClient } from 'src/api/api-client'
import { createQueryKey, useCollectionApi, UseCollectionApiProps } from 'src/api/core'
import { ServerErrorEnum } from 'src/api/types'
import { useServerNotification } from 'src/hooks/serverNotification.hook'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { queryClient } from 'src/queries/reactQueryClient'
import { QUERY_KEY_CURRENT_ACCOUNT } from '../account/queryKeys'
import { QUERY_KEY_USER_MODELS } from './queryKeys'
import { AIModel } from './types'

type UseUserPreferencesApiProps = UseCollectionApiProps<typeof apiClient.models, AIModel> & { refetchEnabled?: boolean }

export const useModels = (props?: UseUserPreferencesApiProps) => {
  const client = apiClient.models()
  const { fireEvent } = useAnalytics()
  const { notify } = useServerNotification()

  const query = useCollectionApi<typeof client.getModels>({
    ...props,
    id: 'models',
    queryKey: QUERY_KEY_USER_MODELS,
    refetchInterval: props?.refetchEnabled ? 15000 : 9999999999,
    // eslint-disable-next-line
    // @ts-ignore
    queryFn: () => client.getModels(),
  })

  const createModelMutation = useMutation(client.createModel, {
    onSuccess: () => {
      fireEvent(ANALYTICS_EVENTS.CreateModel)
      window?.fbq('track', 'CreateModel')
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_MODELS, role: 'collection', scope: 'default' }),
      )
    },
  })
  const trainModelMutation = useMutation(client.trainModel, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_MODELS, role: 'collection', scope: 'default' }),
      )
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_CURRENT_ACCOUNT })
      window?.fbq('track', 'TrainModel')
      fireEvent(ANALYTICS_EVENTS.TrainModel)
    },
    onError: (err: ServerErrorEnum) => notify(err),
  })
  const deleteModelMutation = useMutation(client.deleteModel, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        createQueryKey({ queryKey: QUERY_KEY_USER_MODELS, role: 'collection', scope: 'default' }),
      )
      window?.fbq('track', 'DeleteModel')
      fireEvent(ANALYTICS_EVENTS.DeleteModel)
    },
  })

  return {
    ...query,
    createModel: {
      ...createModelMutation,
    },
    trainModel: {
      ...trainModelMutation,
    },
    deleteModel: {
      ...deleteModelMutation,
    },
  }
}
