import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { FormattedText } from 'src/core/ds/FormattedText'
import { IconNames } from 'src/core/ds/icon'
import Video from '../../../assets/images/snaptap-screencast.mp4'
import styles from '../index.module.scss'

export function CustomFeatures({ data }) {
  return (
    <section id="prompts" className={styles.advantages}>
      <h2>
        <FormattedText id="landing.howitworks.title" />
      </h2>
      <p>
        <FormattedText id="landing.howitworks.description" />
      </p>

      <div className={styles.video}>
        {/* eslint-disable-next-line */}
        <video controls loop>
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className={styles.newFeaturesList}>
        <div className={styles.newFeatureItem}>
          <div className={styles.newFeatureItemContent}>
            <h3>{data.features.title}</h3>
            <p>{data.features.description}</p>
            <div className={styles.newFeatureItemContentAction}>
              <Link to="/login">
                <Button
                  iconColor="#000"
                  icon={IconNames.arrowRight}
                  label="landing.features.featureOne.action"
                  type="button"
                />
              </Link>
            </div>
          </div>
          <div className={styles.newFeatureItemImage}>
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/6cbfff99-b0ee-4fab-f718-faaea7caa700/medium"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
