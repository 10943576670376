import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { config } from 'src/config/config'
import { Container } from 'src/core/ds/container'
import { Input } from 'src/core/ds/input'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import styles from './index.module.scss'

const pricePer1K = 0.5
const MIN_PAYOUT = 10
const MAX_PAYOUT = 2500

export function InfluencersScreen() {
  const [value, setValue] = useState('0')

  const getEstimatedPayout = () => {
    const payout = (parseInt(value, 10) / 1000) * pricePer1K

    if (value === '') {
      return 0
    }

    if (payout < MIN_PAYOUT) {
      return 0
    }

    if (payout > MAX_PAYOUT) {
      return MAX_PAYOUT
    }

    return payout.toFixed(2)
  }

  return (
    <AppLayout>
      <Helmet>
        <title>Influencer Program | Turn influence 💸 into money with Snaptap.ai</title>
        <meta
          name="description"
          content="Discover the Snaptap Influencer Program and monetize your content. Earn up to $2,500 per post."
        />
        <meta property="og:title" content="Influencer Program | Turn influence 💸 into money with Snaptap.ai" />
        <meta
          property="og:description"
          content="Discover the Snaptap Influencer Program and monetize your content. Earn up to $2,500 per post."
        />
      </Helmet>
      <Container>
        <div className={styles.content}>
          <div className={styles.hero}>
            <h1>Turn influence 💸 into money</h1>
            <p>
              {/* eslint-disable-next-line */}
              Discover the Snaptap Influencer Program and monetize your content. Earn up to <span>$2,500</span> per
              post.
            </p>

            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/1054e694-ce9f-486d-923c-c65049b87800/large"
              alt="earn with snaptap.ai"
              loading="lazy"
              width="600"
              height="391"
            />
          </div>

          <div className={styles.steps}>
            <h2>Begin with 3 simple steps</h2>
            <p>To start making affiliate income with Snaptap, just follow these easy steps:</p>

            <div className={styles.stepList}>
              <div className={styles.step}>
                <div className={styles.stepImage}>
                  <img
                    src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/c701a9e5-36e5-4cfa-b94f-8ca4f45f0200/medium"
                    alt=""
                    loading="lazy"
                    width="320"
                    height="320"
                  />
                </div>
                <div className={styles.stepContent}>
                  <div className={styles.stepNum}>First step</div>
                  <div className={styles.stepTitle}>Create</div>
                  <div className={styles.stepDescription}>
                    <p>Do what you do best, pull out your phone and start creating!</p>
                    <p>Tell friends about Snaptap.ai!</p>
                  </div>
                </div>
              </div>

              <div className={styles.step}>
                <div className={styles.stepContent}>
                  <div className={styles.stepNum}>Second step</div>
                  <div className={styles.stepTitle}>Share</div>
                  <div className={styles.stepDescription}>
                    <p>
                      {/* eslint-disable-next-line */}
                      Create content for your audience. Be sure to tag us and use <span>#snaptapai</span>.
                    </p>
                    <p>We accept content from TikTok, Youtube, Instagram & Twitter.</p>
                    <p>
                      Check example on Twitter:&nbsp;
                      <a
                        target="_blank"
                        href="https://twitter.com/allarick3_/status/1668999073393700865"
                        rel="noreferrer"
                      >
                        example.
                      </a>
                    </p>
                  </div>
                </div>
                <div className={styles.stepImage}>
                  <img
                    src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/229b1ce9-6fd8-4524-9cd0-56b2109da500/medium"
                    alt=""
                    loading="lazy"
                    width="320"
                    height="320"
                  />
                </div>
              </div>

              <div className={styles.step}>
                <div className={styles.stepImage}>
                  <img
                    src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/095bdbf6-d6c1-4b2e-f358-afd378ae4800/medium"
                    alt=""
                  />
                </div>
                <div className={styles.stepContent}>
                  <div className={styles.stepNum}>Third step</div>
                  <div className={styles.stepTitle}>Get paid</div>
                  <div className={styles.stepDescription}>
                    <p>
                      We&apos;ll send you $0.5 for every 1,000 views your post receives. Minimum payout is $10 (20k
                      views).
                    </p>
                    <p>Just submit a link to your content via Google form. You can find the link below.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.calculator}>
            <h2>Earnings calculator</h2>
            <p>Discover your potential income with our user-friendly Earnings Calculator.</p>

            <div className={styles.calc}>
              <Input
                value={value}
                inputMode="numeric"
                pattern="[0-9]*"
                onChange={(e) => setValue(e.target.value)}
                label="Enter the number of views or impressions for your content."
              />
              <div className={styles.estimated}>
                <p>
                  Your estimated
                  <br />
                  payout is:
                </p>
                {/* eslint-disable-next-line */}
                <span>${getEstimatedPayout()}</span>
              </div>

              <p className={styles.note}>
                *We pay $0.5 for every 1K impressions/views. We reserve the right to refuse payment if it is discovered
                that you have manipulated views and were dishonest.
              </p>
            </div>
          </div>

          <div className={styles.faq}>
            <h2>FAQ</h2>
            <p>Answers to common questions.</p>

            <div className={styles.faqList}>
              <div className={styles.faqItem}>
                <span>Is there a max payout cap?</span>
                <p>
                  We cap the maximium payout at $2,500 USD. This is for content that reach 5 million views / impressions
                  and above.
                </p>
              </div>
              <div className={styles.faqItem}>
                <span>Which currency do you pay in?</span>
                <p>We will pay you in USD $.</p>
              </div>
              <div className={styles.faqItem}>
                <span>How will you pay me?</span>
                <p>
                  We can pay you in Wise, PayPal, Crypto, Bank Transfer. Simply let us know what works best for you.
                </p>
              </div>
              <div className={styles.faqItem}>
                <span>When do I get paid?</span>
                <p>
                  We measure the content performance within 14 days of upload. You will receive a payout that is
                  reflective of the content views within the 14 day window.
                  <br />
                  Payments will be processed within 30 days of the 14 day window ending.
                </p>
              </div>
              <div className={styles.faqItem}>
                <span>Can you decline the payout?</span>
                <p>
                  Yes, we reserve the right to refuse payment if it is discovered that you have manipulated views and
                  were dishonest.
                </p>
              </div>
              <div className={styles.faqItem}>
                <span>How often I can post?</span>
                <p>
                  We allow 1 post per 1 social network (TikTok, YouTube, Instagram, Twitter) per week. So, You can make
                  4 posts per week as a max: On TikTok, on Twitter, on YouTube and on Instagram.
                </p>
              </div>
              <div className={styles.faqItem}>
                <span>Can I contact you to discuss the details?</span>
                <p>
                  Absolutely! Contact us using email:&nbsp;
                  <a target="_blank" href="mailto:hello@snaptap.ai" rel="noreferrer">
                    hello@snaptap.ai&nbsp;
                  </a>
                  or on&nbsp;
                  <a target="_blank" href="https://twitter.com/snaptapai" rel="noreferrer">
                    Twitter.
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className={styles.submit}>
            <h2>Submit your content here!</h2>
            <p>Just fill in the Google form - it&apos;s that easy!</p>

            <a href={config.influencersFormLink} target="_blank" rel="noreferrer">
              Submit your content
            </a>
          </div>
        </div>
      </Container>
      <Footer />
    </AppLayout>
  )
}
