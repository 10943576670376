/* eslint-disable camelcase */
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { User } from 'src/entities/account'

export const useAnalytics = () => {
  const posthog = usePostHog()
  const [searchParams] = useSearchParams()
  const ref_id = searchParams.get('ref_id')
  const utm_medium = searchParams.get('utm_medium')
  const utm_source = searchParams.get('utm_source')
  const utm_campaign = searchParams.get('utm_campaign')
  const utm_term = searchParams.get('utm_term')

  useEffect(() => {
    if (!ref_id) return

    localStorage.setItem('refId', ref_id as string)
  }, [ref_id])

  useEffect(() => {
    try {
      const adsSource = localStorage.getItem('adsSource') || null

      if (adsSource) return

      localStorage.setItem(
        'adsSource',
        JSON.stringify({
          utm_medium,
          utm_source,
          utm_campaign,
          utm_term,
          // eslint-disable-next-line
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  const fireEvent = (eventName: ANALYTICS_EVENTS, params: Record<string, string> = {}) => {
    posthog.capture(eventName, params)
  }

  const identify = (user: User) => {
    posthog?.identify(user.id.toString(), {
      email: user.email,
    })
  }

  return {
    fireEvent,
    identify,
  }
}

export enum ANALYTICS_EVENTS {
  Login = 'Login',
  SignUp = 'SignUp',
  ClickUpsell = 'ClickUpsell',
  Logout = 'Logout',
  CreateSnap = 'CreateSnap',
  CreateModel = 'CreateModel',
  DeleteModel = 'DeleteModel',
  TrainModel = 'TrainModel',
  ClickCreateModel = 'ClickCreateModel',
  ClickSubscribe = 'ClickSubscribe',
  ClickManagePlan = 'ClickManagePlan',
  ClickBuyCredits = 'ClickBuyCredits',
  ClickBuySubscription = 'ClickBuySubscription',
  ClickFavorite = 'ClickFavorite',
  ClickPreset = 'ClickPreset',
  ClickCopyPrompt = 'ClickCopyPrompt',
  ClickAdvancedSettings = 'ClickAdvancedSettings',

  ModelFlowCreateView = 'ModelFlowCreateView',
  ModelFlowInfoView = 'ModelFlowCreateView',
  ModelFlowUploadPhotoView = 'ModelFlowUploadPhotoView',
  ModelFlowReviewView = 'ModelFlowReviewView',
  ModelFlowPaywallView = 'ModelFlowPaywallView',
  ModelFlowPhotosUploaded = 'ModelFlowPhotosUploaded',

  ClickHowToPrompt = 'ClickHowToPrompt',
}
