import { Button } from '../button'
import { ButtonSizes, ButtonVariants } from '../button/types'
import { Icon, IconNames } from '../icon'
import styles from './index.module.scss'

export type Props = {
  type: 'success' | 'error' | 'default'
  title: any
  subtitle?: string
  onInteract?: any
  interactLabel?: string
  onClose?: any
}

const iconMap = {
  success: <Icon name={IconNames.checkCircle} color="var(--color-primary)" />,
  error: <Icon name={IconNames.alertCircle} color="var(--color-red)" />,
  default: <Icon name={IconNames.alertCircle} color="var(--color-secondary)" />,
}

export function Notification({ type, title, onClose, subtitle, onInteract, interactLabel }: Props) {
  return (
    <div className={styles.notification}>
      <div className={styles.icon}>{iconMap[type]}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        {interactLabel && (
          <div className={styles.action}>
            <Button onClick={onInteract} size={ButtonSizes.s} label={interactLabel} type="button" />
          </div>
        )}
      </div>
      <div className={styles.close}>
        <Button
          type="button"
          iconColor="var(--color-secondary)"
          variant={ButtonVariants.unstyled}
          icon={IconNames.close}
          onClick={onClose}
        />
      </div>
    </div>
  )
}
