import { useEffect, useState } from 'react'
import { useCurrentAccount } from '../account/useCurrentAccount'
import { useSnaps } from './useGetSnaps'

export const useActiveSnaps = () => {
  const [refetchEnabled, setRefetchEnabled] = useState(false)
  const { data: snapPages } = useSnaps({ refetchEnabled })
  const { data: currentUser } = useCurrentAccount()

  const snaps: any = snapPages?.pages?.flatMap((page) => page?.items ?? page)

  const inAQueue = snaps?.filter((snap) => snap.status === 'pending' || snap.status === 'created') || null
  const inProgress = snaps?.find((snap) => snap.status === 'processing') || null
  const isConcurrencyFilled = inAQueue?.length + inProgress?.length >= (currentUser?.concurrency.snap || 1)

  useEffect(() => {
    if (snapPages && snaps) {
      const upscalingSnaps = snaps.find((snap) =>
        snap?.info?.some((infoItem) => infoItem.upscale === 'processing' || infoItem.upscale === 'pending'),
      )
      const isRefetchEnabled = snaps.find((snap) => snap.status !== 'ready') || upscalingSnaps?.length > 0
      setRefetchEnabled(isRefetchEnabled) // Be careful to avoid infinite loops here
    }
  }, [snaps])

  return {
    inAQueue,
    inAQueueLength: inAQueue?.length,
    inProgress,
    inProgressLength: inProgress?.length,
    isConcurrencyFilled,
  }
}
