import { useMutation } from 'react-query'
import { saveEmail } from './api'

export const useEmailService = () => {
  const requestSubscribeWithEmailMutation = useMutation(saveEmail, {
    onMutate: (variables) => {
      if (!variables.token) {
        throw Error('No token provided')
      }
    },
  })

  return {
    requestSubscribeWithEmail: { ...requestSubscribeWithEmailMutation },
  }
}
