import { config } from 'src/config/config'

export const NsfwCheck = (info, photoIndex) => {
  if (!info) return false

  if (info[photoIndex] && info[photoIndex]?.nsfw_probability) {
    if (info[photoIndex]?.nsfw_probability > config.settings.nsfwCutoff) {
      return true
    }
  }

  return false
}
