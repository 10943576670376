import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Dropdown } from 'src/core/ds/dropdown'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { SimpleLoader } from 'src/core/ds/spinner'
import { useAuth } from 'src/entities/account/useAuth'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './index.module.scss'

export function UserArea({ currentUser }) {
  const [isOpenLogoutModal, setOpenLogoutModal] = useState(false)
  const { logout } = useAuth()
  const navigate = useNavigate()

  const openLogoutModal = () => {
    setOpenLogoutModal(true)
  }

  const logoutUser = async () => {
    try {
      await logout.mutateAsync()
      pushNotification({
        type: 'success',
        title: 'notification.auth.logout.success',
      })
      navigate('/')
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'notification.auth.logout.fail',
      })
    }
  }

  if (!currentUser) return <SimpleLoader />

  return (
    <>
      <Modal
        isOpen={isOpenLogoutModal}
        title="Are you sure?"
        type="default"
        onClose={() => setOpenLogoutModal(false)}
        onDeny={() => setOpenLogoutModal(false)}
        onConfirm={logoutUser}
      />

      <Dropdown
        // isAbsoluteBottom
        visibleContent={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            {/* eslint-disable-next-line react/jsx-wrap-multilines */}

            <div className={styles.box}>
              <Avatar size={AVATAR_SIZES.sm} url={currentUser.avatar_url} />
              <p>{currentUser.email}</p>
              <Icon name={IconNames.caretDownMini} />
            </div>
          </>
        }
        hiddenContent={
          // eslint-disable-next-line
          <div className={styles.userMenu}>
            <div className={styles.actionsFooter}>
              <div className={styles.userMenuActions}>
                <Button
                  variant={ButtonVariants.unstyled}
                  onClick={openLogoutModal}
                  type="button"
                  disabled={logout.isLoading}
                  isLoading={logout.isLoading}
                  label="Logout"
                />
              </div>
            </div>
          </div>
        }
      />
    </>
  )
}
