import { useEffect, useState } from 'react'

const defaultWidth = window.innerWidth

export const useMedia = () => {
  const [width, setWidth] = useState(defaultWidth)

  const setWindowWidth = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    setWindowWidth()
    window.addEventListener('resize', setWindowWidth)

    return () => {
      window.removeEventListener('resize', setWindowWidth)
    }
  }, [])

  return width
}
