import { ImageSkeleton } from 'src/components/skeletons/ImageSkeleton'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { useAllowedFeatures } from 'src/entities/account/useAllowedFeatures'
import { useFavoritesActions } from 'src/entities/favorites'
import { GenProgressStatus } from 'src/screens/dashboard/useGenProgressStatus'
import { pushNotification } from 'src/services/notifications/notificationService'
import { DateFormats, formatDate } from 'src/utils/dates'
import { copyToClipboard } from 'src/utils/strings'
import { PhotoItem } from './components/PhotoItem'
import styles from './index.module.scss'

export function PhotoList({ snap }) {
  const { prompt, character } = snap
  const { likeness, seed, text, negative, strictness, face_swap: faceSwap, face_fix: faceFix, hq } = prompt

  const { addFavorite, removeFavorite } = useFavoritesActions()
  const allowedFeatures = useAllowedFeatures()

  const onTextCopy = (text) => {
    copyToClipboard(
      text,
      pushNotification({
        type: 'success',
        title: 'notification.common.textCopy',
      }),
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.snapDetails}>
        <div>{formatDate(Number(snap?.created_at * 1000) as unknown as Date, DateFormats.datePickerFullDate)}</div>
        {character && (
          <div className={styles.snapDetailItem}>
            <span>
              <img src={character.image_url} alt={character.name} />
              {character.name}
            </span>
          </div>
        )}
        {likeness && (
          <div className={styles.snapDetailItem}>
            <Icon name={IconNames.eyeShow} />
            <span>
              Likeness:&nbsp;
              {likeness}
            </span>
          </div>
        )}
        {seed && (
          <div className={styles.snapDetailItem}>
            <Icon name={IconNames.camera} />
            <span>
              Seed:&nbsp;
              {seed}
            </span>
          </div>
        )}
        {/* eslint-disable-next-line camelcase */}
        {faceFix && (
          <div className={styles.snapDetailItem}>
            <Icon name={IconNames.stopwatch} />
            <span>Enhancement</span>
          </div>
        )}
        {/* eslint-disable-next-line camelcase */}
        {faceSwap && (
          <div className={styles.snapDetailItem}>
            <Icon name={IconNames.refresh} />
            <span>Face swap</span>
          </div>
        )}
        {strictness && (
          <div className={styles.snapDetailItem}>
            <Icon name={IconNames.shield} />
            <span>
              Strictness:&nbsp;
              {strictness}
            </span>
          </div>
        )}
        {hq && (
          <div className={styles.snapDetailItem}>
            <Icon name={IconNames.contacts} />
            <span>HQ</span>
          </div>
        )}
      </div>
      <div className={styles.promptText}>
        <span>Prompt:&nbsp;</span>
        {text}
        <span>
          <Button
            label=""
            icon={IconNames.copy}
            type="button"
            tooltip="photo.actions.copy"
            iconColor="var(--color-secondary)"
            variant={ButtonVariants.unstyled}
            onClick={() => onTextCopy(text)}
          />
        </span>
      </div>
      {negative && (
        <div className={styles.promptText}>
          <span>Negative:&nbsp;</span>
          {negative}
          <span>
            <Button
              label=""
              icon={IconNames.copy}
              type="button"
              tooltip="common.copyNegative"
              iconColor="var(--color-secondary)"
              variant={ButtonVariants.unstyled}
              onClick={() => onTextCopy(negative)}
            />
          </span>
        </div>
      )}

      <div className={styles.list}>
        {snap.thumbs.map((photoItem, index) =>
          snap.status === 'ready' ? (
            <PhotoItem
              snapId={snap.id.toString()}
              onAddFav={addFavorite}
              onRemoveFav={removeFavorite}
              downloadableUrl={snap.photos[index]}
              key={`${snap.id.toString()}${index}`}
              photoIndex={index}
              prompt={snap.prompt.text}
              info={snap.info}
              photo={photoItem}
              allowedFeatures={allowedFeatures}
              isFav={(snap.favorites || []).map((fav) => fav.photo).includes(index)}
            />
          ) : (
            <ImageSkeleton>
              <GenProgressStatus snap={snap} />
              {/* <div style={{ height: `${snap.progress}%` }} className={styles.loadedBlock} /> */}
              <img alt="processing" className={styles.pending} src={snap?.character?.image_url} />
            </ImageSkeleton>
          ),
        )}
      </div>
    </div>
  )
}
