import { Helmet } from 'react-helmet'
import { Container } from 'src/core/ds/container'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import { Blog } from '../landing/parts/Blog'
import { Faq } from '../landing/parts/Faq'
import { Upsell } from '../landing/parts/Upsell'
import { ImageConverter } from './ImageConverter'
import styles from './index.module.scss'

export function ConvertToPNGScreen() {
  return (
    <AppLayout>
      <Helmet>
        <title>Team</title>
        <meta
          name="description"
          content="Discover the Zentask Influencer Program and monetize your content. Earn up to $2,500 per post."
        />
        <meta property="og:title" content="Influencer Program | Turn influence 💸 into money with Zentask.ai" />
        <meta
          property="og:description"
          content="Discover the Zentask Influencer Program and monetize your content. Earn up to $2,500 per post."
        />
      </Helmet>
      <Container>
        <div className={styles.content}>
          <div className={styles.hero}>
            <h1>Convert photo to PNG</h1>
          </div>

          <div className={styles.convertBox}>
            <ImageConverter />
          </div>

          <Blog />

          <Faq />

          <Upsell />
        </div>
      </Container>
      <Footer />
    </AppLayout>
  )
}
