import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ESCAPE } from 'src/constants/keyCodes'
// eslint-disable-next-line import/no-cycle
import { Button } from '../button'
import { ButtonVariants } from '../button/types'
import { Icon, IconNames, IconSize } from '../icon'
import styles from './index.module.scss'

export type Props = {
  title: string
  subtitle?: string
  onConfirm?: any
  onDeny?: any
  isOpen: boolean
  onClose?: any
  type?: 'success' | 'error' | 'default'
}

const iconMap = {
  success: <Icon size={IconSize['2xl']} name={IconNames.checkCircle} color="var(--color-primary)" />,
  error: <Icon size={IconSize['2xl']} name={IconNames.alertCircle} color="var(--color-red)" />,
  default: <Icon size={IconSize['2xl']} name={IconNames.alertCircle} color="var(--color-secondary)" />,
}

export function Modal({ title, onClose, isOpen = false, type, onConfirm = false, onDeny = false, subtitle }: Props) {
  const [isOpened, setIsOpen] = useState(isOpen)

  const onPopupClose = () => {
    setIsOpen(false)
    onClose?.()
  }

  const onPopupCloseInner = (e: any) => {
    e.stopPropagation()
  }
  // eslint-disable-next-line
  const onPopupCloseOuter = (e) => {
    setIsOpen(false)
    onClose?.()
  }

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsOpen(isOpen)
    }
  }, [isOpen])

  useEffect(() => {
    window.document.addEventListener('keydown', onKeyPress)

    return () => {
      window.document.removeEventListener('keydown', onKeyPress)
    }
    // eslint-disable-next-line
  }, [title])

  const onKeyPress = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      // close
      case ESCAPE:
        onPopupClose()
        break

      default:
        break
    }
  }

  if (!isOpened) return null

  return (
    <>
      {ReactDOM.createPortal(
        isOpened && (
          // eslint-disable-next-line
          <div onClick={(e: any) => onPopupCloseOuter(e)} className={styles.surface}>
            <div className={styles.container}>
              {/* eslint-disable-next-line */}
              <div onClick={(e: any) => onPopupCloseInner(e)} className={styles.modal}>
                {type && <div className={styles.icon}>{iconMap[type]}</div>}
                <div className={styles.title}>{title}</div>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {(onDeny || onConfirm) && (
                  <div className={styles.action}>
                    {onDeny && (
                      <Button label="Deny" onClick={onDeny} variant={ButtonVariants.secondary} type="button" />
                    )}
                    {onConfirm && <Button label="Confirm" onClick={onConfirm} type="button" />}
                  </div>
                )}
              </div>
            </div>
          </div>
        ),
        document.body,
      )}
    </>
  )
}
