import { FormattedMessage } from 'react-intl'
import { defaultFormatters } from 'src/utils/translation'

export function FormattedText({ id, values = {} }) {
  if (!id) {
    return null
  }

  return <FormattedMessage id={id} values={{ ...values, ...defaultFormatters }} />
}
