import { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Loader } from 'src/core/ds/loader'
import { SimpleLoader } from 'src/core/ds/spinner'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { useModels } from 'src/entities/models/useGetModels'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { FlowLayout } from 'src/layouts/flow/Flow.layout'
import { Grid } from 'src/screens/landing/parts/Grid'
import { PricesNew } from 'src/screens/landing/parts/PricesNew'
import { Testimonials } from 'src/screens/landing/parts/Testimonials'
import { pushNotification } from 'src/services/notifications/notificationService'
import { EmptyList } from 'src/widgets/EmptyList'
import { imageUpload } from '../ImageUploadUtil'
import styles from '../index.module.scss'
import { FilePreview } from './FilePreview'

const fileTypes = ['PNG', 'JPG', 'JPEG', 'HEIC', 'HEIF', 'RAW', 'DNG']

export function ModelUploadFlow(props) {
  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false)
  const [isNoBalanceAccount, setIsNoBalanceAccount] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [badPhotosList, setBadPhotosList] = useState<string[]>([])
  const { createdModelId } = props
  const { data: currentUser } = useCurrentAccount()

  const { fireEvent } = useAnalytics()
  useEffect(() => {
    if (isNoBalanceAccount) {
      fireEvent(ANALYTICS_EVENTS.ModelFlowPaywallView)
      window?.fbq('track', 'ModelFlowPaywallView')
    }
  }, [isNoBalanceAccount, fireEvent])

  useEffect(() => {
    fireEvent(ANALYTICS_EVENTS.ModelFlowUploadPhotoView)
  }, [fireEvent])

  const { trainModel } = useModels()

  const updateBadPhotosCount = (name: string) => {
    setBadPhotosList((prevState) => [...prevState, name])
  }

  const handleChange = (file) => {
    // resetBadPhotosCount()
    setFile((prevState) => {
      if (prevState) {
        return [...prevState, ...file]
      }

      return [...file]
    })
  }

  const deleteFile = (fileToDelete) => {
    setFile(file.filter((file) => file !== fileToDelete))
  }

  const { onCancelFlow, goNext, goBack } = props

  const filteredPhotos = (file || []).filter((file) => !badPhotosList.includes(file.name))

  const onUploadImages = () => {
    setIsUploadingPhotos(true)
    const requests = filteredPhotos?.map((requestFile) => imageUpload(requestFile, createdModelId))
    Promise.all(requests)
      .then(() => {
        fireEvent(ANALYTICS_EVENTS.ModelFlowPhotosUploaded)
        window?.fbq('track', 'ModelFlowPhotosUploaded')

        if (currentUser?.limits.trains === 0) {
          setIsNoBalanceAccount(true)
          setIsUploadingPhotos(false)
        } else {
          // eslint-disable-next-line
          // @ts-ignore
          trainModel
            // eslint-disable-next-line
            // @ts-ignore
            .mutateAsync(window.characterId)
            .then(() => {
              setIsUploadingPhotos(false)
              goNext()
            })
            .catch(() => setIsUploadingPhotos(false))
        }
      })
      .catch(() => {
        setIsUploadingPhotos(false)
        pushNotification({
          type: 'error',
          title: 'Some error occured.',
        })
      })
  }

  const hasEnoughtPhotosToProceed =
    filteredPhotos?.length >= config.settings.minPhotoRequired &&
    filteredPhotos?.length <= config.settings.maxPhotoRequired

  if (isNoBalanceAccount) {
    return (
      <FlowLayout>
        <FlowLayout.Header goBack={goBack} onCancelFlow={onCancelFlow} />
        <FlowLayout.Body>
          <FlowLayout.Title>
            <h1>One last thing.</h1>
          </FlowLayout.Title>
          <FlowLayout.Description>
            Training the model requires GPU power. To start training, please purchase a subscription or top up your
            balance.
          </FlowLayout.Description>
          <PricesNew isInnerBlock />
          <Grid withLink={false} />
          <Testimonials />
          <div className={styles.skip}>
            <Button variant={ButtonVariants.unstyled} onClick={onCancelFlow} type="button" label="Skip for now" />
          </div>
        </FlowLayout.Body>
      </FlowLayout>
    )
  }

  return (
    <FlowLayout>
      {isUploadingPhotos && (
        <div className={styles.loaderBlock}>
          <Loader isBig />
        </div>
      )}
      <FlowLayout.Header goBack={goBack} onCancelFlow={onCancelFlow} />
      <FlowLayout.Body>
        <FlowLayout.Title>
          <h1>
            <FormattedText id="addModel.upload.title" />
          </h1>
        </FlowLayout.Title>
        <FlowLayout.Description>
          <FormattedText id="addModel.upload.description" />
        </FlowLayout.Description>
        <div style={{ margin: '1rem auto', maxWidth: '800px', marginBottom: '1rem' }}>
          {isUploadingPhotos && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SimpleLoader />
            </div>
          )}
          <div className={styles.uploadBox}>
            {!isUploadingPhotos && (
              <div className={styles.finalResult}>
                <p className={styles.instructions}>
                  <Icon size={IconSize.xs} name={IconNames.infoCircle} />
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line  */}
                  Please, upload at least {config.settings.minPhotoRequired} photos (max{' '}
                  {config.settings.maxPhotoRequired}
                  ).
                  <br />
                  Min width: 832px, min height: 1216px. Only PNG allowed.
                </p>
                <FileUploader
                  className={styles.uploader}
                  multiple
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
              </div>
            )}

            {file?.length > 0 ? (
              <div className={styles.photoPreview}>
                {file?.map((file) => (
                  <FilePreview
                    updateBadPhotosCount={updateBadPhotosCount}
                    key={file.name}
                    file={file}
                    onDelete={deleteFile}
                  />
                ))}
              </div>
            ) : (
              <EmptyList title="No photos uploaded" description="Your photos will appear here" illustration />
            )}
          </div>
        </div>
        <FlowLayout.Footer>
          {/* <Button onClick={goBack} variant={ButtonVariants.secondary} type="button" label="Go back" /> */}
          <Button
            disabled={!hasEnoughtPhotosToProceed || isUploadingPhotos || (file && file?.length < 1)}
            onClick={onUploadImages}
            isLoading={isUploadingPhotos}
            type="button"
            label="addModel.upload.button.upload"
          />
          <p className={styles.photosCount}>
            Valid photos:&nbsp;
            {filteredPhotos?.length}
          </p>
        </FlowLayout.Footer>

        <div className={styles.protection}>🔒 Your photos are protected and will be removed after model trained.</div>
      </FlowLayout.Body>
    </FlowLayout>
  )
}
