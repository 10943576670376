import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { FormattedText } from 'src/core/ds/FormattedText'
import { Icon, IconNames } from 'src/core/ds/icon'
import styles from '../index.module.scss'

export function Hero({ currentUser }) {
  return (
    <div className={styles.hero}>
      <Container>
        <div className={styles.heroTop}>
          <div className={styles.heroTopText}>
            <a href="https://muvon.io" target="_blank" className={styles.muvon} rel="noreferrer">
              <span>
                built by
                <svg width="126" height="40" viewBox="0 0 126 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="16.7757" cy="6.04188" r="6.04188" fill="#76B744" />
                  <circle cx="37.5282" cy="31.2601" r="6.04188" fill="#76B744" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.4889 14.7002C40.6847 18.0313 38.1431 20.8904 34.812 21.0862C33.2728 21.1767 31.6218 21.4473 30.5785 22.5826L25.4075 28.2087C24.2377 29.4814 24.1311 31.3703 24.1311 33.099C24.1311 36.4359 21.4261 39.1409 18.0892 39.1409C14.7524 39.1409 12.0473 36.4359 12.0473 33.099C12.0473 33.0689 12.0475 33.0387 12.048 33.0087C12.0696 31.5357 11.9005 29.9507 10.8815 28.8867C9.74425 27.6992 7.93379 27.5495 6.29084 27.615C6.01445 27.6261 5.73401 27.6182 5.45092 27.5904C2.13011 27.2638 -0.297169 24.3069 0.0294542 20.9861C0.356077 17.6653 3.31291 15.238 6.63373 15.5646C9.95454 15.8913 12.3818 18.8481 12.0552 22.1689C11.9519 23.2191 12.1129 24.3374 12.8427 25.0995L13.3778 25.6582C14.5465 26.8785 16.3995 27.0571 18.0892 27.0571C19.5836 27.0571 21.1971 26.8255 22.2084 25.7252L27.3209 20.1627C28.456 18.9277 28.5244 17.0838 28.426 15.4092C28.2302 12.0781 30.7718 9.21903 34.1029 9.02323C37.434 8.82742 40.2931 11.3691 40.4889 14.7002Z"
                    fill="#76B744"
                  />
                  <path
                    d="M65.6534 17.4566C65.3946 16.9132 64.8877 16.6414 64.1329 16.6414C63.2559 16.6414 62.6664 16.9132 62.3644 17.4566C62.1991 17.7661 62.1164 18.227 62.1164 18.8392V25.6079H59V14.3818H61.987V16.0224C62.368 15.4377 62.7275 15.0215 63.0654 14.7739C63.662 14.3336 64.4348 14.1135 65.3838 14.1135C66.2824 14.1135 67.0085 14.3027 67.562 14.681C68.0078 15.0318 68.3456 15.4824 68.5757 16.0327C68.9783 15.3723 69.4779 14.8874 70.0746 14.5778C70.7072 14.2683 71.4117 14.1135 72.1881 14.1135C72.7057 14.1135 73.2161 14.2098 73.7194 14.4024C74.2226 14.595 74.6791 14.9321 75.0889 15.4136C75.4196 15.8057 75.6424 16.2872 75.7574 16.8581C75.8293 17.2365 75.8653 17.7902 75.8653 18.5193L75.8437 25.6079H72.6949V18.4471C72.6949 18.0206 72.6231 17.6698 72.4793 17.3947C72.2061 16.8719 71.7029 16.6105 70.9696 16.6105C70.1213 16.6105 69.5354 16.9475 69.2119 17.6217C69.0466 17.9794 68.9639 18.4093 68.9639 18.9114V25.6079H65.869V18.9114C65.869 18.2442 65.7972 17.7592 65.6534 17.4566Z"
                    fill="var(--color-text)"
                  />
                  <path
                    d="M85.5764 24.0189C85.5476 24.0533 85.4757 24.1565 85.3607 24.3285C85.2457 24.5004 85.1091 24.6518 84.951 24.7825C84.4693 25.1952 84.002 25.4772 83.5491 25.6285C83.1034 25.7799 82.5786 25.8555 81.9747 25.8555C80.235 25.8555 79.0632 25.2571 78.4593 24.0602C78.1215 23.3998 77.9525 22.4265 77.9525 21.1402V14.3611H81.1013V21.1402C81.1013 21.7799 81.1804 22.2614 81.3385 22.5847C81.6189 23.1556 82.1688 23.4411 82.9884 23.4411C84.038 23.4411 84.7569 23.0353 85.1451 22.2236C85.3463 21.7833 85.447 21.2021 85.447 20.4798V14.3611H88.5634V25.6079H85.5764V24.0189Z"
                    fill="var(--color-text)"
                  />
                  <path
                    d="M89.7233 14.3611H93.174L95.6218 22.6569L98.1236 14.3611H101.423L97.1854 25.6079H93.9396L89.7233 14.3611Z"
                    fill="var(--color-text)"
                  />
                  <path
                    d="M112.31 15.785C113.302 16.9751 113.798 18.3818 113.798 20.0052C113.798 21.6561 113.302 23.0696 112.31 24.2459C111.318 25.4153 109.812 26 107.792 26C105.771 26 104.265 25.4153 103.273 24.2459C102.281 23.0696 101.785 21.6561 101.785 20.0052C101.785 18.3818 102.281 16.9751 103.273 15.785C104.265 14.595 105.771 14 107.792 14C109.812 14 111.318 14.595 112.31 15.785ZM107.781 16.4867C106.882 16.4867 106.188 16.7928 105.7 17.405C105.218 18.0103 104.977 18.877 104.977 20.0052C104.977 21.1333 105.218 22.0034 105.7 22.6156C106.188 23.2279 106.882 23.534 107.781 23.534C108.679 23.534 109.37 23.2279 109.851 22.6156C110.333 22.0034 110.574 21.1333 110.574 20.0052C110.574 18.877 110.333 18.0103 109.851 17.405C109.37 16.7928 108.679 16.4867 107.781 16.4867Z"
                    fill="var(--color-text)"
                  />
                  <path
                    d="M120.878 16.5383C119.835 16.5383 119.12 16.9613 118.732 17.8074C118.531 18.2545 118.43 18.8255 118.43 19.5202V25.6079H115.368V14.3818H118.333V16.0224C118.728 15.4445 119.102 15.0284 119.454 14.7739C120.087 14.3199 120.889 14.0929 121.859 14.0929C123.074 14.0929 124.066 14.399 124.835 15.0112C125.612 15.6165 126 16.6242 126 18.0344V25.6079H122.851V18.767C122.851 18.1754 122.769 17.7214 122.603 17.405C122.301 16.8272 121.726 16.5383 120.878 16.5383Z"
                    fill="var(--color-text)"
                  />
                </svg>
              </span>
            </a>
            <h1>
              <FormattedText id="landing.hero.title" />
            </h1>
            <p>
              <FormattedText id="landing.hero.subtitle" />
            </p>
          </div>

          <div className={styles.heroTopImages}>
            {/* <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/69b517a6-2f93-4de6-e778-4d9574fa5a00/4k"
              alt=""
            /> */}
            <ImageSlider />
          </div>
        </div>

        {currentUser && (
          <div className={styles.form}>
            <Link to="/dashboard">
              <Button type="button" size={ButtonSizes.xl} label="landing.hero.dashboard" />
            </Link>
          </div>
        )}

        {!currentUser && (
          <div className={styles.form}>
            <Link to="/login">
              <Button
                type="button"
                size={ButtonSizes.xl}
                icon={IconNames.arrowRight}
                iconColor="var(--color-black)"
                label="landing.hero.action"
              />
            </Link>
            <div className={styles.nocard}>
              <Icon name={IconNames.check} />
              <FormattedText id="landing.hero.noCredit" />
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

const images = [
  // Replace these with the paths or imports of your images
  'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/41c2221c-c498-4b80-a54f-44a544957000/medium',
  'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/965fcd90-0c39-46bd-b9d8-aaaae074a100/medium',
  'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/54e25cfc-3a40-4690-46c2-15266e31ab00/medium',
  'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/acb12781-a123-4efe-8dc5-0a3f97406800/medium',
]

function ImageSlider() {
  const [currentIndex, setCurrentIndex] = useState(() => Math.floor(Math.random() * 4))
  const [opacity, setOpacity] = useState(1)

  // Prefetch images
  useEffect(() => {
    images.forEach((image) => {
      const img = new Image()
      img.src = image
    })
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Fade out current image
      setOpacity(0)
      setTimeout(() => {
        // Update to the next image and fade in
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
        setOpacity(1)
      }, 800) // Match this timeout with the CSS transition time
    }, 4000) // Change image every 2 seconds

    return () => clearInterval(intervalId) // Clear interval on component unmount
  }, [])

  return (
    <img
      src={images[currentIndex]}
      // loading="lazy"
      style={{
        opacity,
        transition: 'opacity .8s ease-in-out',
        width: '100%', // Adjust based on your needs
        height: 'auto', // Adjust based on your needs
      }}
      alt="Sliding Images"
    />
  )
}
