import { format, formatDistanceToNow } from 'date-fns'

export enum DateFormats {
  day = 'EEE',
  dayLong = 'EEEE',
  dayOfMonth = 'd',
  dayOfMonthWithOrdinal = 'do',
  dayAndYear = 'd, yyyy',
  isoDate = 'yyyy-MM-dd',
  isoDateWithHoursAndMinutes = 'yyyy-MM-dd hh:mm a',
  datePickerFullDate = 'MMMM d, yyyy h:mm aa',
  dayMonthYearDate = 'dd-MM-yyyy',
  dayMonthYearDateSlash = 'MM/dd/yyyy',
  dayLongMonthShortYear = 'EEEE, MMM d, yyyy',
  monthShortWithDateAndYear = 'MMM d, yyyy',
  monthShortWithLongDateAndYear = 'MMM dd, yyyy',
  monthShortWithYear = 'MMM yyyy',
  monthNumberWithShortYear = 'MM-yy',
  monthNumberWithSlashShortYear = 'MM/yy',
  monthNumberWithYear = 'MM/yyyy',
  hoursAndMinutesOnly = 'HH:mm',
  monthWithDay = 'MMM d',
}

export const formatDate = (date: Date, dateFormat: DateFormats) => format(new Date(date), dateFormat)
export const getTimeAgo = (date: Date | number) => formatDistanceToNow(date, { includeSeconds: true })
