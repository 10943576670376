import { Helmet } from 'react-helmet'
import { MetaTitle } from 'src/components/MetaTitle'
import { Button } from 'src/core/ds/button'
import { useAllowedFeatures } from 'src/entities/account/useAllowedFeatures'
import { useExplorerSnaps } from 'src/entities/snap/useExplorerSnaps'
import { DashboardLayout } from 'src/layouts/DashboardLayout'
import { EmptyList } from 'src/widgets/EmptyList'
import { PhotoItem } from 'src/widgets/PhotoList/components/PhotoItem'
// import { PhotoListSimple } from 'src/widgets/PhotoListSimple/PhotoListSimple.widget'
import styles from './index.module.scss'

export function ExploreScreen() {
  const { data: snapPages, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useExplorerSnaps()

  const snaps: any = snapPages?.pages?.flatMap((page) => page?.items ?? page)

  const isHasAnyPhotos = snaps?.length > 0
  const allowedFeatures = useAllowedFeatures()

  return (
    <DashboardLayout>
      <MetaTitle title="Explore photos" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2>Explore snaps</h2>

          {!isHasAnyPhotos && !isLoading && (
            <EmptyList description="emptyStates.noPhotos.description" title="emptyStates.noPhotos.title" illustration />
          )}

          {isHasAnyPhotos && (
            <div className={styles.list}>
              {snaps?.flatMap(
                (snap) =>
                  snap.account.id.toString() !== '147153057904328705' &&
                  snap.thumbs?.map((photo, index) => (
                    <PhotoItem
                      key={index}
                      allowedFeatures={allowedFeatures}
                      downloadableUrl={snap.photos[index]}
                      showPrompt
                      photoIndex={index}
                      info={snap.info}
                      prompt={snap.prompt.text}
                      photo={photo}
                    />
                  )),
              )}
            </div>
          )}

          {hasNextPage && (
            <div className={styles.action}>
              <Button isLoading={isFetchingNextPage} type="button" onClick={fetchNextPage} label="Load more" />
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
