export const presets = [
  {
    id: 17,
    title: `Symmetry`,
    prompt: `background features symmetry and repeating patterns that visually appealing and grab attention. Architectural elements and arranged objects, radiating calm, (half-body shot)`,
    negative: '',
    isNew: true,
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/147823022202421249/151691270119489537-3.webp',
  },
  {
    id: 16,
    title: `In luxury car`,
    prompt: `close-up shot, driving at night in a high-end luxury vehicle. Wearing a sleek, black outfit that mirrors the car's polished exterior. enjoying the quiet power beneath the hood, surrounded by the car's refined interior.`,
    negative: '',
    isNew: true,
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/147823022202421249/151689279846744065-3.webp',
  },

  {
    id: 14,
    title: `City street with neon signs`,
    prompt: `in trendy streetwear, City street with neon signs and pedestrians, Cinematic, Close up shot, Mirrorless, 35mm lens, f/1.8 aperture, ISO 400, slight color grading`,
    negative: '',
    isNew: true,
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/147823022202421249/151674651221688321-3.webp',
  },
  {
    id: 13,
    title: `Old factory`,
    prompt: `in modern attire with metallic accessories, in an old factory loft setting fashion show, Metallic sheen, Full-frame mirrorless, 35mm lens, f/2.8 aperture, ISO 500, off-camera flash`,
    negative: '',
    isNew: true,
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/147823022202421249/151674591805177857-2.webp',
  },
  {
    id: 12,
    title: `Street style`,
    prompt: `in layered street style, standing against a vibrant graffiti wall, Vivid colors, Mirrorless, 28mm lens, f/2.5 aperture, ISO 400, natural daylight, (half-body shot)`,
    negative: '',
    isNew: true,
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/147823022202421249/151674530979381249-3.webp',
  },
  {
    id: 6,
    title: `Magazine cover`,
    prompt: `In front of a vibrant, abstract background, radiating confidence and style, hair styled impeccably. Dressed in the latest high-fashion attire, striking a pose that commands attention.  eyes sparkling with ambition. the dynamic composition, (close-up shot)`,
    negative: '',
    isNew: true,
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/147823022202421249/149472239102722049-0.webp',
  },
  {
    id: 10,
    title: `Fashion show backstage`,
    prompt: `Backstage at a high-end fashion show, face highlighted under the bright lights, draped in an avant-garde outfit that marries classic elegance with modern flair. Confident gaze piercing through the lens`,
    negative: '',
    isNew: true,
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/147823022202421249/149426166359392257-0.webp',
  },
  {
    id: 1,
    title: 'Walking in New York',
    prompt:
      'walking new york street, shot on Fujifilm Superia 400, wearing fashion apparel, natural lighting, close-up shot, 32k, cinematic composition, professional color grading, film grain, atmospheric, wondrous ',
    negative: '',
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/138469286078316545/139183670899507201-2.webp',
  },
  {
    id: 2,
    title: 'Halloween night',
    prompt:
      'halloween night, shot on Fujifilm Superia 400, (wearing halloween costume and makeup:2), natural lighting, close-up shot, 32k, cinematic composition, professional color grading, film grain, atmospheric, wondrous ',
    negative: '',
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/138469286078316545/139119661198344193-3.webp',
  },
  {
    id: 3,
    title: 'In Miami',
    prompt:
      'in Miami, shot on Fujifilm Superia 400, wearing fashion apparel, natural lighting, close-up shot, 32k, cinematic composition, professional color grading, film grain, atmospheric, wondrous ',
    negative: '',
    preview: 'https://photo-dev.snaptap.ai/113657790681055233/144382048713310209/144888809715138561-0.jpg',
  },
  {
    id: 7,
    title: 'Neon rain',
    prompt:
      'cinematic composition, close-up portrait shot, professional color grading, film grain, pausing to gaze at their reflection in a rain-soaked puddle, surrounded by the lively hustle and bustle of a neon-lit city street at night, capturing a moment of introspection amidst urban chaos',
    negative:
      'incomplete, missing, deformed, extra eyes, wide eyes, missing fingers, extra fingers, extra mouth, missing hair, fedora hats, 3d, cartoon, twisted fingers,',
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/144776733306912769/144917682624397313-1.jpg',
  },
  {
    id: 4,
    title: 'At Stadium',
    prompt:
      'at the stadium, shot on Fujifilm Superia 400, wearing fashion apparel, natural lighting, close-up shot, 32k, cinematic composition, professional color grading, film grain, atmospheric, wondrous ',
    negative: '',
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/138469286078316545/139183993441484801-3.webp',
  },
  {
    id: 5,
    title: 'In the mountains',
    prompt: 'cinematic composition, close-up shot, professional color grading, film grain, in the mountains behind',
    negative: '',
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/144776733306912769/144819885816414209-2.jpg',
  },
  {
    id: 9,
    title: 'In the jungle',
    prompt:
      'close-up shot portrait, natural lighting, cinematic composition, professional color grading, film grain, as Adventurous explorer, clad in khaki, machete in hand, navigating through dense jungle undergrowth, ancient ruins peeking through the foliage, wild animals lurking, the sound of a distant waterfall guiding their path',
    negative: '',
    preview: 'https://photo-dev.snaptap.ai/106527100520890369/144776733306912769/144912364481806337-3.jpg',
  },
]
