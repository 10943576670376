/* eslint-disable react/no-unescaped-entities */
import { FormattedText } from 'src/core/ds/FormattedText'
import styles from '../index.module.scss'

export function Faq() {
  return (
    <section id="faq" className={styles.faq}>
      <h2>
        <FormattedText id="landing.faq.title" />
      </h2>
      <p>
        <FormattedText id="landing.faq.description" />
      </p>

      <div className={styles.faqList}>
        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q1" />
          </span>
          <p>
            <FormattedText id="faq.section.a1" />
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q2" />
          </span>
          <p>
            <FormattedText id="faq.section.a2" />
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q3" />
          </span>
          <p>
            <FormattedText id="faq.section.a3" />
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q4" />
          </span>
          <p>
            <FormattedText id="faq.section.a4" />
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q5" />
          </span>
          <p>
            <FormattedText id="faq.section.a5" />
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q6" />
          </span>
          <p>
            <FormattedText id="faq.section.a6" />
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q7" />
          </span>
          <p>
            <FormattedText id="faq.section.a7" />
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>
            <FormattedText id="faq.section.q8" />
          </span>
          <p>
            <FormattedText id="faq.section.a8" />
          </p>
        </div>
      </div>
    </section>
  )
}
